import { Component, OnInit } from '@angular/core';
import {Evento} from '../models/evento.model';
import {PlatformService} from '../providers/platform.service';
import {ActionSheetController, AlertController, IonSlides, LoadingController, ModalController} from '@ionic/angular';
import {Location} from '@angular/common';
import {StorageService} from '../providers/storage.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EventoApiService} from '../providers/evento-api.service';
import {EventoSeccionService} from '../providers/evento-seccion.service';
import {DomSanitizer} from '@angular/platform-browser';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Calendar, NameOrOptions} from '@ionic-native/calendar/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {MultimediaComponent} from '../shared/multimedia/multimedia.component';
import {Helper} from '../models/helper';
import { ConnexionApiService } from '../providers/connexion-api.service';
import { RecintoApiService } from '../providers/recinto-api.service';
import { Recinto } from '../models/recinto.model';
import { LoadingService } from '../providers/loading-service';
declare const Sharer: any;


@Component({
  selector: 'app-evento',
  templateUrl: './evento.page.html',
  styleUrls: ['./evento.page.scss'],
})
export class EventoPage implements OnInit {

  webVersion = false;

  evento: Evento;

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  itemPorSlide = 2;

  secciones: any;

  eventos: Evento[] = [];

  trustedVideoURL: any;

  calendarName = 'Eventos Butaca';

  sharerInit = false;

  constructor(public platform: PlatformService, private location: Location, private storage: StorageService,
              private activatedRoute: ActivatedRoute, private eventoService: EventoApiService,
              private seccionService: EventoSeccionService, private sanitizer: DomSanitizer,
              private iab: InAppBrowser, private calendar: Calendar, private alertController: AlertController,
              private socialSharing: SocialSharing, private modalController: ModalController,
              private loadingService: LoadingService,
              private loadingController: LoadingController, private recintoService: RecintoApiService) { }

  async back () {

    await this.location.back();
  }

  async ionViewWillEnter() {
    const loading = await this.loadingController.create({
      message: 'Espere por favor',
      spinner: 'circles',
      cssClass: 'custom-loading',
    });
    await loading.present();
    this.activatedRoute.paramMap.subscribe(async (p: ParamMap) => {

      const id = p.get('id');
      if (!id) {

        return;
      }
      let idUser = await this.storage.getUsuarioId();
      const favoritos:any = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
      this.eventoService.obtenerEventoInfo(id, favoritos).subscribe(async (evento) => {

          this.evento = evento;
          try {
            this.evento.horario = JSON.parse(this.evento.horario);
          } catch (error) { }
          // this.evento.liked = this.storage._usuario.likeEventos.indexOf(evento.id) >= 0;
          let respuesta = await this.buscarEventoId(favoritos.intereses, evento.id);
          this.evento.liked = respuesta;
          // let idUser = await this.storage.getUsuarioId();
          // const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
          // this.recintoService.obtenerRecintoInfo(this.evento.recinto, favoritos).subscribe((recinto: Recinto) => {
          //   this.evento.recinto = recinto[0];
          //   console.log(this.evento.recinto);
          // });
          this.sanitizeAndEmbedURL();

          this.evento.horario.forEach(horario => {
            horario.diasString = [];
            horario.dias.forEach(dia => {
              const dias = [
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
                'Domingo',
              ];
              horario.diasString.push(dias[dia]);
            });            
          });
      });

    });

    if (this.storage._eventos) {

      this.eventos = this.storage._eventos;
      this.secciones = this.seccionService.seccionarEventos(this.storage._eventos);
    }
    await loading.dismiss();
  }

  buscarEventoId(datos, eventoId) {
    // Iterar sobre cada objeto en el arreglo
    for (let i = 0; i < datos.length; i++) {
      // Verificar si el evento_id coincide con el buscado
      if (datos[i].evento_id === eventoId) {
        return true; // Encontrado
      }
    }
    return false; // No encontrado
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

  slide() {

    if (!this.evento || !this.evento.imagenes || this.evento.imagenes.length < 2) {

      return [];
    }

    const n = Math.ceil(this.evento.imagenes.length / this.itemPorSlide);
    const lista = [];

    for (let i = 1; i < n; i++) {

      lista.push(this.evento.imagenes.slice(i * this.itemPorSlide, (i+1) * this.itemPorSlide));
    }

    return lista;
  }

  async prev(slides: IonSlides) {

    await slides.slidePrev();
  }

  async next(slides: IonSlides) {

    await slides.slideNext();
  }

  openFacebook(url: string) {
    this.openSocialNetwork(url);
  }

  openInstagram(url: string) {
    this.openSocialNetwork(url);
  }

  openTwitter(url: string) {
    this.openSocialNetwork(url);
  }

  openYoutube(url: string) {
    this.openSocialNetwork(url);
  }

  openSnapchat(url: string) {
    this.openSocialNetwork(url);
  }

  openSocialNetwork(url) {

    const iabInstance = this.iab.create(url, '_system');
    iabInstance.close();
  }

  private sanitizeAndEmbedURL() {

      if (!this.evento) {

        return;
      }

      let videourl = Helper.YoutbeURLVideo(this.evento.video);

      if (videourl) {

        this.trustedVideoURL =  this.sanitizer.bypassSecurityTrustResourceUrl(videourl);
      }
  }

  public async toggleLike() {
    let usuario = await this.storage.getUsuario();
    if(usuario.username == 'invitado'){
      return;
    }
    if (this.evento) {
      let idUser = await this.storage.getUsuarioId();
      if(this.evento.liked) {
        await this.eventoService.toggleDislike(this.evento.id, idUser).toPromise();
        this.evento.liked = false;
      } else {
        await this.eventoService.toggleLike(this.evento.id, idUser).toPromise();
        this.evento.liked = true;
      }
      

      // this.eventoService.obtenerEventoFavoritos(idUser).subscribe((likes:any) => {
      //   let dt = likes.intereses.filter( fl => fl.evento_id == this.evento.id );
      //   this.evento.liked = (dt.length > 0) ? true : false;
      // });

      // this.evento.liked = likeList.indexOf(this.evento.id) >= 0;
    }
  }

  public comprar() {

    const iabInstance = this.iab.create(this.evento.urlCompra, '_system');
    iabInstance.close();
  }

  public async agregarAlCalendario() {

    // let data = this.generarFechasConHorario(this.evento);
    // console.log(data);

    await this.loadingService.show();
    const lista: any[] = await this.calendar.listCalendars().catch(async () => {
      // await this.loadingService.hide();
      console.log('Error al acceder al calendario');
      this.mostrarAlert('No se pudo acceder al calendario');
      return;
    }).finally( async () => {
      await this.loadingService.hide();
    });
    if(lista.length > 0) {
      this.validarEventos(lista);
    }
  }

  async validarEventos(lista) {
    let data = this.generarFechasConHorario(this.evento);
    if(data.length > 1) {
      let opciones = [];
      let fechaInicioCalendario = '';
  
      data.forEach(fecha => {
        opciones.push( {
          name: fecha,
          type: 'radio',
          label: fecha.replace('.000','').replace('T', ' '),
          value: fecha
        })
      });
  
      const alert = await this.alertController.create({
        header: 'Guardar en calendario',
        inputs: opciones,
        cssClass: 'crls',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            handler: (data) => {
              fechaInicioCalendario = data;
              // console.log(data);
              this.agregarCalendario(lista, fechaInicioCalendario);
            }
          }
        ]
      });
      await alert.present();
    } else {
      this.agregarCalendario(lista, null);
    }
  }

  async agregarCalendario(lista, fechaInicioCalendario = null) {
    const index = lista.findIndex((c: any) => c.name === this.calendarName );
    if ( index < 0) {
      const createCalOptions: NameOrOptions = this.calendar.getCreateCalendarOptions();
      createCalOptions.calendarName = this.calendarName;
      createCalOptions.calendarColor = '#614795';
      await this.calendar.createCalendar(createCalOptions).catch(async () => {
        console.log('Error al crear el calendario');
        await this.loadingService.hide();
      });
    }
    const calOptions = this.calendar.getCalendarOptions();
    calOptions.calendarName = this.calendarName;
    if (this.evento.fechaInicio) {
      if (fechaInicioCalendario == null ) {
        let data = this.generarFechasConHorario(this.evento);
        // console.log(data);
        let fechaInicio = data[0];
        this.agregarCalendarioDispositivo(fechaInicio, calOptions);
      } else {
        this.agregarCalendarioDispositivo(fechaInicioCalendario, calOptions);
      }
    } else {
      await this.loadingService.hide();
      await this.mostrarAlert(`El evento ${this.evento.nombre} no fue agregado. Fecha no especificada.`);
    }
  }

  async agregarCalendarioDispositivo(fechaInicio, calOptions) {
    // let userTimezoneOffset = (new Date()).getTimezoneOffset() * 60000 + 8 * 3600000;
    // Revisar la hora de evento para calendario
    // const fechaInicio = new Date(new Date(this.evento.fechaInicio).getTime() + userTimezoneOffset);
    // const fechaFin = this.evento.fechaFin ? new Date(new Date(this.evento.fechaFin).getTime() + userTimezoneOffset) : fechaInicio;
    let fechaIn = new Date(fechaInicio);
    let fechaFn =  new Date(fechaInicio);
    fechaFn.setHours(fechaIn.getHours() + 1,0,0,0);
    await this.calendar.createEventWithOptions(this.evento.nombre, this.evento.recinto[0].nombre, '',
    fechaIn, fechaFn, calOptions).catch(async (err) => {
        console.log(err);
        this.mostrarAlert('El evento no pudo ser agregado al calendario.');
        await this.loadingService.hide();
      });
    await this.loadingService.hide();
    await this.mostrarAlert(`El evento ${this.evento.nombre} fue agregado a su calendario.`);
  }

  async mostrarAlert(msg) {

    const alert = await this.alertController.create({
      header: 'Notificación',
      message: msg,
      buttons: ['Ok']
    });

    await alert.present();
  }

  async share(btn: HTMLElement | null) {

    // if (this.webVersion) {

    //   if (!this.sharerInit) {
    //     Sharer.init();
    //     this.sharerInit = true;
    //   }

    //   btn.setAttribute('data-title', this.evento.descripcion);
    //   btn.setAttribute('data-url', this.evento.redes.web ? this.evento.redes.web: this.evento.urlCompra);
    //   btn.setAttribute('data-subject', this.evento.nombre);
    //   btn.setAttribute('data-subject', this.evento.descripcion);
    //   btn.setAttribute('data-hashtag', 'butaca-udg');
    //   btn.setAttribute('data-hashtags', 'butaca-udg');

    //   const actionSheet = await this.actionSheetController.create({
    //     header: 'Compartir',
    //     buttons: [
    //       {
    //         text: 'Email',
    //         icon: 'mail',
    //         handler: () => {
    //           btn.setAttribute('data-sharer', 'email');
    //           btn.click();
    //         }
    //       }, {
    //         text: 'Facebook',
    //         icon: 'logo-facebook',
    //         handler: () => {
    //           btn.setAttribute('data-sharer', 'facebook');
    //           btn.click();
    //         }
    //       }, {
    //         text: 'Twitter',
    //         icon: 'logo-twitter',
    //         handler: () => {
    //           btn.setAttribute('data-sharer', 'twitter');
    //           btn.click();
    //         }
    //       }, {
    //         text: 'Cancelar',
    //         icon: 'close',
    //         role: 'cancel',
    //         handler: () => {
    //           console.log('Cancel clicked');
    //         }
    //       }]
    //   });

    //   await actionSheet.present();
    // } else {
    if(this.webVersion){
      const shareData = {
        title: this.evento.nombre,
        text: this.evento.descripcion,
        url: this.evento.urlCompra,
      };
      // await navigator.share(shareData);
    } else {
      await this.socialSharing.share(this.evento.nombre, this.evento.nombre, null,
        this.evento.redes.web ? this.evento.redes.web: this.evento.urlCompra);
    }
      
    // }

  }

  async showMultimedia(evento) {
    let imagenes = [];
    imagenes.push(this.imageUrl(evento.foto));
    evento.galeria.forEach(imagen => {
      imagenes.push(this.imageUrl(imagen.foto));
    });
    // console.log(evento);
    const modal = await this.modalController.create({
      component: MultimediaComponent,
      cssClass: 'modal-fullscreen',
      componentProps: {
        initialImage: imagenes[0]
      }
    });

    return await modal.present();
  }

  imageUrl(foto: string): string {

    return `${ConnexionApiService.host.replace('api','app.')}/uploads/evento/${foto}`;
  }

  generarFechasConHorario(datos) {
    const { fecha_inicio, fecha_fin, horario } = datos;
    let fechaActual = new Date(fecha_inicio + 'T06:01:00Z');
    let fechaFin = new Date(fecha_fin + 'T18:01:00Z');
    fechaFin.setHours(23,59,59,0);
    let fechasConHorario = [];
  
    function aDosDigitos(numero) {
      return numero < 10 ? '0' + numero : numero;
    }
  
    while (fechaActual <= fechaFin) {
      // Ajustar el índice del día de la semana para que coincida con tu entrada (donde "0" es Lunes)
      const diaDeLaSemana = (fechaActual.getDay() + 6) % 7; // Convertir "0" (Domingo) a "6", "1" (Lunes) a "0", etc.
  
      horario.forEach(horarioDelDia => {
        if (horarioDelDia.dias.includes(diaDeLaSemana.toString())) {
          // Separar los horarios para el día y generar una fecha para cada uno
          horarioDelDia.horas.split(', ').forEach(horaCompleta => {
            const [hora, minutosParte] = horaCompleta.split(':');
            const minutos = minutosParte.substring(0, 2);
            const meridiano = minutosParte.substring(3);
            let horasEn24 = parseInt(hora, 10);
            // Convertir a formato 24 horas
            if (meridiano === 'PM' && horasEn24 < 12) {
              horasEn24 += 12;
            } else if (meridiano === 'AM' && horasEn24 === 12) {
              horasEn24 = 0;
            }
            // Construir la cadena de fecha en formato ISO local
            const fechaConHorario = `${fechaActual.getFullYear()}-${aDosDigitos(fechaActual.getMonth() + 1)}-${aDosDigitos(fechaActual.getDate())}T${aDosDigitos(horasEn24)}:${minutos}:00.000`;
            fechasConHorario.push(fechaConHorario);
          });
        }
      });
  
      fechaActual.setUTCDate(fechaActual.getUTCDate() + 1);
    }
  
    return fechasConHorario;
  }
  
  

}
