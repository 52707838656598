export class Helper {


    static FormatFecha(fecha: Date) {

        const mesPrefix = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

        return {
            fechaCorta: `${fecha.getDate()} de ${mesPrefix[fecha.getMonth()]}`,
            hora: null
        };
    }

    static CheckUrl(url: string) {

        if (!url || url.length < 4) {

            return false;
        }

        return url.slice(0,4) === 'http';
    }

    static CheckAndFixUrl(url: string) {

        if (Helper.CheckUrl(url)) {
            return url;
        }

        return `http://${url}`;
    }

    static YoutbeURLVideo(videoUrl) {

        if (!videoUrl)
            return;

        let videoid = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)[1];


        if (!videoid) {

            return;
        }

        return `https://www.youtube.com/embed/${videoid}`;
    }
}
