import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RecintoPageRoutingModule } from './recinto-routing.module';

import { RecintoPage } from './recinto.page';
import {SharedModule} from '../shared/shared.module';
import {NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RecintoPageRoutingModule,
        SharedModule,
        NgbTabsetModule
    ],
  declarations: [RecintoPage]
})
export class RecintoPageModule {}
