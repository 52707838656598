import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {

  @Input()
  opciones: any[] = [];

  @Input()
  selected: any[] = [];

  @Input()
  emptyOptions: string = '';

  @Input()
  onSelect: EventEmitter<any>;

  constructor() { }

  ngOnInit() {}

  changeOpcion(event) {

    if (this.onSelect) {

      this.onSelect.emit(event);
    }
  }

  isChecked(opcion) {

    return this.selected.findIndex((e: any) => e.id == opcion.id) >= 0;
  }

}
