import { Injectable } from '@angular/core';
import {HttpRequestApiService} from './http-request-api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Notify} from '../models/notify.model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionApiService {

  constructor(private http: HttpRequestApiService) { }

  obtenerTodas(idUser, dominio): Observable<Notify[]> {

    return this.http.get('api/v1/usuario/notificacion?idUser='+idUser+'&dominio='+dominio,).pipe((map( (obj: any[]) => {

      return obj.map((b) => new Notify(b));
    })));
  }

  borrar(id): Observable<any> {

    return this.http.delete('api/v1/usuario/notificacion/'+id);
  }

}
