import { Component, OnInit } from '@angular/core';
import {StorageService} from '../providers/storage.service';
import {Location} from '@angular/common';
import {Notify} from '../models/notify.model';
import {NotificacionApiService} from '../providers/notificacion-api.service';
import {ModalController, ToastController} from '@ionic/angular';
import {MultimediaComponent} from '../shared/multimedia/multimedia.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.page.html',
  styleUrls: ['./notificacion.page.scss'],
})
export class NotificacionPage implements OnInit {

  notifications: Notify[] = [];

  loaded = false;

  constructor(private storage: StorageService, private location: Location, private modalController: ModalController,  private iab: InAppBrowser,
              private modalService: NgbModal, private notificacionService: NotificacionApiService, private toast: ToastController) { }

  ngOnInit() {
  }

  async back () {

    await this.location.back();
  }

  ionViewWillEnter() {

    this.actualizarNotificaciones();
  }

  async actualizarNotificaciones() {
    let usuarioId = await localStorage.getItem('usuarioId');
    let segmento = await localStorage.getItem('Segmento');
    console.log(segmento);
    let dominio = 0;
    if(segmento){
      if(segmento == 'General') {
        dominio = 2;
      }
      else {
        dominio = 1;
      }
    }
    this.notificacionService.obtenerTodas(usuarioId, dominio).subscribe((notificaciones: Notify[]) => {

      this.loaded = true;
      this.notifications = this.ordenar(notificaciones);
      this.storage.newNotification(notificaciones.filter((n: Notify) => n.nuevo).length > 0);
    });
  }

  eliminar(notify: Notify) {

    this.notificacionService.borrar(notify.id).subscribe(
        async () => {
          await this.actualizarNotificaciones();
          await this.mostrarToast('La notificación fue borrada', 'info')
        },
        () => this.mostrarToast('La notificación no pudo ser borrada'));
  }

  openAdvertenciaModal(content, notify) {

    this.modalService.open(content, { centered: true, windowClass: 'dark-modal' });
  }

  async mostrarToast(mensaje: string, color = 'danger') {

    const toast = await this.toast.create({
      message: mensaje,
      duration: 5000,
      color: color,
      position: 'top'
    });

    await toast.present();
  }

  async enlargeImage(notify: Notify) {

    const modal = await this.modalController.create({
      component: MultimediaComponent,
      cssClass: 'modal-fullscreen',
      componentProps: {
        initialImage: notify.imagenUrl,
        title: notify.titulo,
        description: notify.mensaje
      }
    });

    return await modal.present();
  }

  ordenar(notificaciones: Notify[]) {
    const regexURL = /(https?:\/\/[^\s]+)/g;
    notificaciones.forEach(cupon => {
      const urlEncontrada = cupon.mensaje.match(regexURL);
      if (urlEncontrada && urlEncontrada.length > 0) {
        let url = urlEncontrada[0];
        cupon.url = url;
        cupon.mensaje = cupon.mensaje.replace(url, '');
      } else {
        cupon.url = '';
        cupon.mensaje = cupon.mensaje;
      }
    });
    return notificaciones;
  }

  showDetails(notificacion) {
    if(notificacion.url != ''){
      this.iab.create(notificacion.url, '_system');
    }
  }

}
