import {Deserializable} from './deserializable.model';
import {Genero} from './genero.model';

export class Categoria implements Deserializable {

  id = 0;
  nombre = '';
  background = '';
  color = '#ffffff';
  thumbnail = '';
  generos: Genero[] = [];

  constructor(obj = null) {

      if (obj) {

        this.deserialize(obj)
      }
  }

  deserialize(obj: any): this {

    Object.assign(this, obj);

    if (obj.generos) {

      this.generos = obj.generos.map( (g: any) => {

        return new Genero(g);
      });
    }

    return this;
  }
}
