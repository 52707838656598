import {Deserializable} from './deserializable.model';

export class Usuario implements Deserializable {

  id = 0;
  nombre = '';
  username = '';
  correo = '';
  telefono = '';
  foto = '';
  fotoUrl = '';
  providerUId = '';
  provider= '';
  token: string;
  password: string;
  mostrarNotificaciones = true;
  estaVerificada = false;
  likeEventos: any[] = [];
  intereses = [];
  nuevasNotificaciones = 0;
  noPush = false;
  fechaNacimiento: string = "";
  codigoUdeG: string;
  codigo_ude_g:string;
  cuenta_verificada = 0;
  segmento:string;

  constructor(obj = null) {

      if (obj) {

        this.deserialize(obj)
      }
  }

  deserialize(obj: any): this {

    Object.assign(this, obj);

    if(obj.codigo_ude_g){
      this.codigo_ude_g = obj.codigo_ude_g;
    }

    if (obj.displayName) {

      this.nombre = obj.displayName;
    }

    if (obj.email) {

      this.correo = obj.email;
    }

    if (obj.phoneNumber) {

      this.telefono = obj.phoneNumber;
    }

    if (obj.provider_uid) {

      this.providerUId = obj.provider_uid;
    }
    if (obj.provider_id) {

      this.provider = obj.provider_id;
    }

    if (obj.photoURL) {

      this.foto = obj.photoURL;
    }

    if (obj.segmento) {
      this.segmento = obj.segmento;
    }

    if (this.likeEventos) {
      this.likeEventos = this.likeEventos.map( (obj: any) => {
        return obj.id ? obj.id : obj;
      })
    }

    if (obj.intereses && obj.intereses.length) {
      try {
        this.intereses = obj.intereses.split(',').map(Number);
      } catch (error) {}
    }

    return this;
  }
}
