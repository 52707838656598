import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {ConfigDataEnum} from '../models/config.data.enum';

@Injectable({
    providedIn: 'root'
})
export class PlatformService extends Platform {

    /**
     * Diferencia si la app fue compilada para la versión web o para device.
     * Necesario para identificar si en la app se visita la versión web o
     * es el mobil app.
     */
    public isWebBuild() {

        return ConfigDataEnum.IS_WEB_BUILD === 1;
    }

    /**
     * Determina si el dispositivo es desktop (no device)
     */
    public isDesktop() {

        if (this.isWebBuild()) {

            return !this.is('ipad') && !this.is('iphone') && !this.is('ios') &&
                !this.is('android');
        }

        return this.is('desktop');
    }
}
