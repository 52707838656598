import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP } from '@ionic-native/http/ngx';
import {ConnexionApiService} from './providers/connexion-api.service';
import {HttpRequestApiService} from './providers/http-request-api.service';
import {LoadingService} from './providers/loading-service';
import {StorageService} from './providers/storage.service';
import {UsuarioApiService} from './providers/usuario-api.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ResponseInterceptor} from './providers/response.interceptor';
import {environment} from '../environments/environment';
import {AuthFirebaseService} from './providers/auth-firebase.service';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {RequestInterceptor} from './providers/request.interceptor';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {DatepickerI18nService} from './providers/DatepickerI18n.service';
import {IonicStorageModule} from '@ionic/storage';
import {AuthGuardService} from './providers/auth-guard.service';
import {GeolocationService} from './providers/geolocation.service';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Calendar} from '@ionic-native/calendar/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {AndroidFullScreen} from '@ionic-native/android-full-screen/ngx';
import {SharedModule} from './shared/shared.module';
import {UdegUserService} from './providers/udeg.user.service';
import {UdegGuardService} from './providers/udeg-guard.service';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      BrowserModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      HttpClientModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      AngularFirestoreModule,
      IonicStorageModule.forRoot({
          name: '__butaca_dev'
      }),
      SharedModule
  ],
  providers: [
      StatusBar,
      SplashScreen,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      HTTP,
      LoadingService,
      StorageService,
      ConnexionApiService,
      HttpRequestApiService,
      UsuarioApiService,
      Geolocation,
      AndroidPermissions,
      GeolocationService,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
      },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: ResponseInterceptor,
          multi: true
      },
      { provide: NgbDatepickerI18n, useClass: DatepickerI18nService },
      AuthFirebaseService,
      GooglePlus,
      AuthGuardService,
      UdegGuardService,
      Facebook,
      Camera,
      File,
      InAppBrowser,
      Calendar,
      SocialSharing,
      AndroidFullScreen,
      UdegUserService,
      SignInWithApple,
      FirebaseAuthentication,
      FirebaseMessaging
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
