import {Deserializable} from './deserializable.model';
import {Evento} from './evento.model';
import {ConnexionApiService} from '../providers/connexion-api.service';

export class Recinto implements Deserializable {

  id = 0;
  nombre = 'Conjunto Santander de artes escénicas';
  contacto: string;
  web: string;
  telefono: string;
  horario: string ;
  capacidad: number;
  direccion: string;
  redes = { facebook: '', instagram: '', web: '', twitter: '', youtube: '' };  // urls
  amemnidades: string[];
  descripcion: string;
  video: string;
  promocion = false;
  clasificacion: string;
  tipoClasificacion: string;
  location: { lat: string, lng: string };
  imagenes: string[] = [];
  eventos: Evento[];
  foto: string;

  constructor(obj = null, intereses) {

      if (obj) {

        this.deserialize({obj:obj, intereses: intereses})
      }
  }


  featuredImg() {

    // if (!this.imagenes || this.imagenes.length == 0 ) {

    //   return null;
    // }
    return `${ConnexionApiService.host.replace('api','app.')}/uploads/recinto/${this.foto}`
    // return this.imagenes[0];
  }

  deserialize(obj: any): this {

    Object.assign(this, obj.obj);

    if (this.imagenes && this.imagenes.length) {

      this.imagenes = this.imagenes.map( (img: string) => {

        return ConnexionApiService.host.replace('api','app.') + '/uploads/recinto/' + img;
      });
    }

    if (this.eventos && this.eventos.length) {

      this.eventos.map( (e: any) => new Evento(e));
    }

    return this;
  }
}
