import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CategoriaApiService} from '../providers/categoria-api.service';
import {Categoria} from '../models/categoria.model';
import {Genero} from '../models/genero.model';
import {StorageService} from '../providers/storage.service';

@Component({
  selector: 'app-intereses',
  templateUrl: './intereses.page.html',
  styleUrls: ['./intereses.page.scss'],
})
export class InteresesPage implements OnInit {

  listaOriginal: any[] = [];
  listas: any[] = [];
  numberOfColumns = 0;
  intereses = [];
  interesesReales = [];

  constructor(private router: Router, private categoriaService: CategoriaApiService, private storage: StorageService) {}

  ngOnInit() {
  }

  async ionViewWillEnter() {
    let idUsuario = this.storage._idUser;
    // this.intereses = this.storage._usuario && this.storage._usuario.intereses ?
        // this.storage._usuario.intereses.map( (g) => g.id ) : [];
    await this.categoriaService.obtenerInteresesTodas(idUsuario).subscribe((dataIntereses) => {
      this.interesesReales = dataIntereses.intereses.split(',').map(Number);
    });
    this.categoriaService.obtenerTodas().subscribe((data: Categoria[]) => {
      const lista: any[] = [];
      data.forEach((c: Categoria) => {
        let index = lista.length;
        lista.push({ id: c.id, nombre: c.nombre, esCategoria: true, categoriaId: c.id, seleccionado: false });
        if (this.interesesReales.indexOf(c.id) >=0) {
          lista[index].seleccionado = true;
        }
      });

      this.listaOriginal = lista;
      this.checkBreakPoint();
    });

  }

  async saltar() {

    await this.router.navigate([ '/home' ]);
  }

  async goHome() {

    let intereses = [];
    this.listaOriginal.forEach((item: any) => {
      if(item.seleccionado && item.esCategoria) {
        intereses.push(item.id)
      }
    });
    let idUser = await this.storage.getUsuarioId();
    this.categoriaService.asignarIntereses(intereses, idUser).subscribe(() => {

      this.router.navigate([ '/home' ]);
    });
  }

  checkBreakPoint () {

    let n = 1;
    if (window.innerWidth > 992) {

      n = 3;
    } else if (window.innerWidth > 768) {

      n = 2;
    }

    if (!this.numberOfColumns || n !== this.numberOfColumns) {

      this.reArreglo(n);
    }

    this.numberOfColumns = n;
  }

  onResize() {

    this.checkBreakPoint();
  }

  reArreglo (n) {

    const itemsPorBloque = Math.ceil(this.listaOriginal.length/n);

    let listas = [];

    for (let i = 0; i < n; i++ ) {

      listas.push(this.listaOriginal.slice( i * itemsPorBloque, (i + 1) * itemsPorBloque));
    }

    this.listas = listas;
  }

  selectItem(item: {id: number, nombre: string, esCategoria: number, categoriaId: number, seleccionado: boolean}) {

    const data = this.getCategoriaGeneros(item.categoriaId);

    if (item.esCategoria) {

      data.generos.forEach((i: any) => i.seleccionado = item.seleccionado);
    } else {

      const seleccionados = data.generos.filter(((g: any) => g.seleccionado ));

      if (seleccionados.length === data.generos.length - 1 || seleccionados.length === data.generos.length) {

        data.categoria.seleccionado = item.seleccionado;
      }
    }
  }

  getCategoriaGeneros(categoriaId) {

    let generos: any[] = [];
    let categoria: any = null;

    this.listas.forEach((lista: any[]) => {
      lista.forEach((i: any) => {

        if (i.categoriaId !== categoriaId) {

          return;
        }

        if (i.esCategoria) {

          categoria = i;
        } else {
          generos.push(i);
        }

      });

      console.log(this.listas);
    });

    return {
      categoria,
      generos
    }
  }

}
