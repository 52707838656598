  import { Component, OnInit, NgZone } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { AlertController } from '@ionic/angular';
  import { HttpClient } from '@angular/common/http';
  import { UsuarioApiService } from '../providers/usuario-api.service';
  import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
  import { Router } from '@angular/router';
  import { Location } from '@angular/common';

  @Component({
    selector: 'app-login-recuperar-contra',
    templateUrl: './login-recuperar-contra.page.html',
    styleUrls: ['./login-recuperar-contra.page.scss'],
  })
  export class LoginRecuperarContraPage implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    passwordRecovery = false;
    webVersion = false;
    showAlert = false;

    constructor(
      private fb: FormBuilder, 
      private alertController: AlertController,
      private http: HttpClient,
      private usuarioService: UsuarioApiService,
      private modalService: NgbModal,
      private router:Router,
      private location:Location

      
    

    ) {}

    ngOnInit() {
      this.loginForm = this.fb.group({
        username: ['', [Validators.required]],
      });
      this.webVersion = this.detectWebVersion();
    }
  
    detectWebVersion(): boolean {
      const userAgent = window.navigator.userAgent;
      return /Chrome|Firefox|Safari/.test(userAgent) && !/Mobile/.test(userAgent);
    }
  

    async recuperarPassword(modalContent) {

      if (!this.loginForm.controls.username.valid) {

        this.passwordRecovery = true;
        return;
      }

      this.passwordRecovery = false;
      const alert = await this.alertController.create({
        message: `Realmente quiere reiniciar la contraseña de "${this.loginForm.get('username').value}"?`,
        buttons: [
            {
                text: 'OK',
                handler: () => {

                  this.usuarioService.solicitarRecuperarPassword(this.loginForm.get('username').value).subscribe(async () => {
                    const alert = await this.alertController.create({
                      mode: 'ios',
                      header: 'Acabamos de enviar un mail a tu correo electrónico con el que te diste de alta.',
                      message: 'Favor de revisar y seguir las indicaciones para re-establecer la contraseña.',
                      buttons: [
                        {
                          text: 'Ok',
                          role: 'confirm',
                          handler: async () => {
                            this.router.navigate(['/login']);
                          },
                        },
                      ],
                    });
                  
                    await alert.present();
                }, (error) => {
                  this.showErrorMessage(error)
                });
            }
        },
        'Cancel'
    ]
  });

      await alert.present();
    }

    async showErrorMessage(error) {

      const alert = await this.alertController.create({
        message: `No se puede recuperar la contraseña. ${error ? error.message: ''}`,
        buttons: [ 'OK' ]
      });

      console.log(error)
      
      await alert.present();
    }
      back(){
        this.location.back();
      }
  }