import { Component, OnInit } from '@angular/core';
import {Recinto} from '../models/recinto.model';
import {PlatformService} from '../providers/platform.service';
import {IonSlides} from '@ionic/angular';
import {Evento} from '../models/evento.model';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {RecintoApiService} from '../providers/recinto-api.service';
import {EventoSeccionService} from '../providers/evento-seccion.service';
import {DomSanitizer} from '@angular/platform-browser';
import {EventoApiService} from '../providers/evento-api.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Helper} from '../models/helper';
import { StorageService } from '../providers/storage.service';
import {ConnexionApiService} from '../providers/connexion-api.service';
import { Filtro } from '../models/filtro.model';
import { Categoria } from '../models/categoria.model';
import { Genero } from '../models/genero.model';

@Component({
  selector: 'app-recinto',
  templateUrl: './recinto.page.html',
  styleUrls: ['./recinto.page.scss'],
})
export class RecintoPage implements OnInit {

  webVersion = false;

  recinto: Recinto;

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  itemPorSlide = 2;

  secciones: any[];

  trustedVideoURL: any;

  eventos: Evento[] = [];
  mostrarOrdenar = false;
  enVista = false;
  loaded = false;
  orderKey = null;
  filtros: Filtro = new Filtro();
  categorias: Categoria[];
  recintos: Recinto[];
  generos: Genero[];
  ordenamiento = [
    { nombre: 'Mayor a menor promoción', valor: { orderBy: 'promocion', orderType: 'desc' } },
    { nombre: 'Fecha lejana', valor: { orderBy: 'fecha', orderType: 'desc' } },
    { nombre: 'Fecha cercana', valor: { orderBy: 'fecha', orderType: 'asc' } },
    { nombre: 'De menor a mayor precio', valor: { orderBy: 'precio', orderType: 'desc' } },
    { nombre: 'De mayor a menor precio', valor: { orderBy: 'precio', orderType: 'asc' } }
  ];

  constructor(public platform: PlatformService, private location: Location, private activatedRoute: ActivatedRoute,
              private recintoService: RecintoApiService, private sanitizer: DomSanitizer, private iab: InAppBrowser,
              private eventoService: EventoApiService, private seccionService: EventoSeccionService,
              private storage: StorageService,) { }

  async back () {
    this.enVista = false;
    await this.location.back();
  }

  ngOnInit() {
    this.mostrarOrdenar = false;
    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[2]; // ordenamiento más cercano
  }

  ionViewWillEnter() {
    this.enVista = true;
    this.activatedRoute.paramMap.subscribe(async (p: ParamMap) => {

      const id = p.get('id');
      if (!id) {

        return;
      }
      let idUser = await this.storage.getUsuarioId();
      const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
      this.recintoService.obtenerRecintoInfo(id, favoritos).subscribe((recinto: Recinto) => {

        this.recinto = recinto[0];
        this.recinto.foto = `${ConnexionApiService.host.replace('api','app.')}/uploads/recinto/${this.recinto.foto}`
        console.log(this.recinto);
        this.sanitizeAndEmbedURL();

        this.obtenerEventos({});
      });
      this.filtros.recinto = [id];
      this.actualizarBusqueda();
    });

  }

  slide() {

    if (!this.recinto || !this.recinto.imagenes || this.recinto.imagenes.length < 2) {

      return [];
    }

    const n = Math.ceil(this.recinto.imagenes.length / this.itemPorSlide);
    const lista = [];

    for (let i=1; i < n; i++) {

      lista.push(this.recinto.imagenes.slice(i * this.itemPorSlide, (i+1) * this.itemPorSlide));
    }

    return lista;
  }

  async prev(slides: IonSlides) {

    await slides.slidePrev();
  }

  async next(slides: IonSlides) {

    await slides.slideNext();
  }


  openFacebook(url: string) {
    this.openSocialNetwork(url);
  }

  openInstagram(url: string) {
    this.openSocialNetwork(url);
  }

  openTwitter(url: string) {
    this.openSocialNetwork(url);
  }

  openYoutube(url: string) {
    this.openSocialNetwork(url);
  }

  openSocialNetwork(url) {

    const iabInstance = this.iab.create(url, '_system');
    iabInstance.close();
  }

  private sanitizeAndEmbedURL() {

    if (!this.recinto) {

      return;
    }

    let videourl = Helper.YoutbeURLVideo(this.recinto.video);

    if (videourl) {

      this.trustedVideoURL =  this.sanitizer.bypassSecurityTrustResourceUrl(videourl);
    }
  }

  async obtenerEventos(data) {
    if (!this.recinto || !this.recinto.id) {
      return;
    }
    if (!data.filtros) {
      data.filtros = {}
    }
    data.filtros.recinto = [ this.recinto.id ];
    let idUser = await this.storage.getUsuarioId();
    const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarTodoEventos(data, favoritos).subscribe((eventos: Evento[]) => {

      this.secciones = this.seccionService.seccionarEventos(eventos);
    });
  }

  ordenarEventos() {
    if (!this.webVersion) {
      this.mostrarOrdenar = false;
    }
    if(this.enVista) {
      this.actualizarBusqueda();
    }
  }

  async actualizarBusqueda() {
    
    this.loaded = false;
    if (!this.filtros) {
      this.filtros = new Filtro();
    }
    this.storage._metadata = this.filtros;
    this.obtenerEntidades();
    const data: any = {
      filtros: this.filtros
    };
    if (this.orderKey && this.orderKey.valor) {
      data.orderBy = this.orderKey.valor.orderBy;
      data.orderType = this.orderKey.valor.orderType;
    }
    console.log(data);
    let idUser = await this.storage.getUsuarioId();
    const favoritos:any = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarTodoEventos(data, favoritos).subscribe((eventos: Evento[]) => {
      let evnt = eventos;
      evnt.forEach(element => {
        element.liked = this.buscarEventoPorId(favoritos.intereses, element.id);
      });
      let respFiltrado =  this.filtrarYOrdenar(data,evnt);
      let existePromociones = false;
      if(data.orderBy == 'promocion'){
        respFiltrado.forEach(element => {
          if(element.textoPromocion != null) {
            existePromociones = true;
          }
        });
      }
      this.mostrarOrdenar = false;
      this.eventos = (data.orderBy == 'promocion') ?  (existePromociones ? respFiltrado : []) : respFiltrado;
    }, () => {}, () => this.loaded = true);
  }

  buscarEventoPorId(listaEventos, id) {
    return listaEventos.some(evento => evento.evento_id === id);
  }

  obtenerEntidades() {
    this.categorias = this.storage._categorias.filter((c: Categoria)  => this.filtros.categoria.indexOf(c.id) >= 0);
    this.recintos = this.storage._recintos.filter((r: Recinto)  => this.filtros.recinto.indexOf(r.id) >= 0);
    let generos = [];
    this.categorias.forEach((c: Categoria) => generos = [ ...generos, ... c.generos]);
    this.generos = generos.filter((g: Genero)  => this.filtros.genero.indexOf(g.id) >= 0);
  }

  filtrarYOrdenar(filtro, data) {
    const { orderBy, orderType } = filtro;
    if (!orderBy || !orderType) {
      return data;
    }
    return data.sort((a, b) => {
      let valorA, valorB;
      if (orderBy === 'promocion') {
        valorA = a.texto_promocional === null ? (orderType === 'asc' ? Infinity : -Infinity) : parseInt(a.texto_promocional, 10);
        valorB = b.texto_promocional === null ? (orderType === 'asc' ? Infinity : -Infinity) : parseInt(b.texto_promocional, 10);
      } else if (orderBy === 'fecha') {
        valorA = new Date(a.fecha_inicio);
        valorB = new Date(b.fecha_inicio);
      } else if (orderBy === 'precio') {
        if(orderType == 'desc') {
          valorA = parseFloat(b.precio_bajo);
          valorB = parseFloat(a.precio_bajo);
        } else {
          valorA = parseFloat(b.precio_alto);
          valorB = parseFloat(a.precio_alto);
        }
      }
      if (orderType === 'asc') {
        return valorA - valorB;
      } else {
        return valorB - valorA;
      }
    });
  }
}
