import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PerfilPageRoutingModule } from './perfil-routing.module';

import { PerfilPage } from './perfil.page';
import {SharedModule} from '../shared/shared.module';
import {NgbModalModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PerfilPageRoutingModule,
        SharedModule,
        NgbTabsetModule,
        NgbModalModule,
        ReactiveFormsModule
    ],
  declarations: [PerfilPage]
})
export class PerfilPageModule {}
