import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequestApiService} from './http-request-api.service';
import {map, tap} from 'rxjs/operators';
import {Categoria} from '../models/categoria.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriaApiService {

    constructor(private http: HttpRequestApiService) { }

    obtenerTodas(): Observable<any> {
      return this.http.get('api/v1/categoria').pipe((map( (obj: any) => {

          return obj.message.map((c) => new Categoria(c));
      })));
    }

    asignarIntereses(intereses: string[], idUser): Observable<any> {
        return this.http.post('api/v1/usuario/intereses2', { intereses, user_id: idUser });
    }

    obtenerInteresesTodas(usuario): Observable<any> {
      return this.http.get('api/v1/usuario/intereses2/'+usuario);
    }

    asignarSegmento(segmento: string, idUser): Observable<any> {
      return this.http.post('api/v1/usuario/segmento', { segmento, user_id: idUser });
  }

}

