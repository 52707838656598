import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Cupon} from '../models/cupon.model';
import {MultimediaComponent} from '../shared/multimedia/multimedia.component';
import {ModalController} from '@ionic/angular';
import {CuponApiService} from '../providers/cupon-api.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Notify } from '../models/notify.model';

@Component({
  selector: 'app-cupones',
  templateUrl: './cupones.page.html',
  styleUrls: ['./cupones.page.scss'],
})
export class CuponesPage implements OnInit {

  cupones: Notify[]  = [];

  loaded = false;

  constructor(private location: Location, private modalController: ModalController,
              private cuponService: CuponApiService, private iab: InAppBrowser) { }

  ngOnInit() {}

  async ionViewWillEnter() {
    let segmento = await localStorage.getItem('Segmento');
    // console.log(segmento);
    let dominio = 0;
    if(segmento){
      dominio = 1;
    } else {
      dominio = 2;
    }
    this.cuponService.obtenerTodos(dominio, segmento).subscribe((cupones: Notify[] ) => {
      this.cupones = this.orderCupones(cupones);
      console.log(this.cupones);
    }, err => console.log(err), () => this.loaded = true );

  }

  async back () {

    await this.location.back();
  }

  async showMultimedia(cupon: Notify) {

    const modal = await this.modalController.create({
      component: MultimediaComponent,
      cssClass: 'modal-fullscreen',
      componentProps: {
        initialImage: cupon.imagenUrl,
        description: cupon.mensaje,
        link: cupon.url,
        title: cupon.titulo
      }
    });

    return await modal.present();
  }

  orderCupones(notificaciones: Notify[]) {
    const regexURL = /(https?:\/\/[^\s]+)/g;
    notificaciones.forEach(cupon => {
      const urlEncontrada = cupon.mensaje.match(regexURL);
      if (urlEncontrada && urlEncontrada.length > 0) {
        let url = urlEncontrada[0];
        cupon.url = url;
        cupon.mensaje = cupon.mensaje.replace(url, '');
      } else {
        cupon.url = '';
        cupon.mensaje = cupon.mensaje;
      }
    });
    return notificaciones;
  }

  showDetails(cupone) {
    if(cupone.url != ''){
      this.iab.create(cupone.url, '_system');
    }
  }

  handleError(event: any) {
    event.target.src = 'assets/images/udg.svg';
  }

}
