import {Component, Input, OnInit} from '@angular/core';
import {PlatformService} from '../../providers/platform.service';
import {Router} from '@angular/router';
import {StorageService} from '../../providers/storage.service';
import {ModalController, NavController} from '@ionic/angular';
import {UdegUserService} from '../../providers/udeg.user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

    @Input()
    onlyWeb: boolean = false;

    webVersion = false;

    searchText: string;

    constructor(public platform: PlatformService, private router: Router, public storage: StorageService,
                private modalController: ModalController, private udegUserService: UdegUserService) { }

    ngOnInit() {

        this.webVersion = this.platform.isDesktop();
    }

    async textChanged() {

        if (!this.searchText || !this.searchText.length) {

            return;
        }

        await this.router.navigate([ '/busqueda-resultado' ], { queryParams: { texto: this.searchText } });
    }

    goToHome() {

        if (this.storage._isAuth) {

            this.router.navigate([ '/home' ]);
        } else {

            if (this.platform.isWebBuild()) {
                this.router.navigate([ '/promo' ]);
            }   else {
                this.router.navigate([ '/registro' ]);
            }
        }

    }

    async udegUser() {

        await this.udegUserService.mostrarModal({
            target: '/registro',
            declineTarget: '/registro',
            successLabelBtn: 'Continuar con el registro'
        });
    }

    irLogin(){
        this.storage.irLogin();
    }

}
