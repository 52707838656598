import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {StorageService} from './storage.service';

@Injectable()
export class UdegGuardService implements CanActivate {

    constructor(public router: Router, private storage: StorageService) {}

    canActivate(): boolean {


        const test = this.storage._usuario && this.storage._usuario.codigoUdeG;

        if (!test) {

            this.router.navigate([ '/home' ]);
        }


        return !(!test);
    }
}
