import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (this.storage._token) {

          req = req.clone({
              setHeaders: {
                  Authorization: `Bearer ${this.storage._token}`
              }
          });
      }

      return next.handle(req);
  }

}
