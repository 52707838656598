import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {InfoApiService} from '../providers/info-api.service';
import {ActivatedRoute, ParamMap} from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit {

  titulo = '';

  texto = '';

  constructor( private location: Location, private infoService: InfoApiService, private activatedRoute: ActivatedRoute) { }

  async back () {

    await this.location.back();
  }

  ngOnInit() {
  }

  ionViewWillEnter() {

    this.activatedRoute.paramMap.subscribe((p: ParamMap) => {

      this.infoService.infoBySlug(p.get('slug')).subscribe((obj: any) => {

        this.texto = obj[0].texto;
        this.titulo = obj[0].info;
      })
    });

  }

}
