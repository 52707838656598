import { Component, OnInit } from '@angular/core';
import {Evento} from '../models/evento.model';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {EventoApiService} from '../providers/evento-api.service';
import {StorageService} from '../providers/storage.service';
import {Genero} from '../models/genero.model';
import { EventoSeccionService } from '../providers/evento-seccion.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.page.html',
  styleUrls: ['./promociones.page.scss'],
})
export class PromocionesPage implements OnInit {
//Filtros
ordenamiento = [
  { nombre: 'Mayor a menor promoción', valor: { orderBy: 'promocion', orderType: 'desc' } },
  { nombre: 'Fecha lejana', valor: { orderBy: 'fecha', orderType: 'desc' } },
  { nombre: 'Fecha cercana', valor: { orderBy: 'fecha', orderType: 'asc' } },
  { nombre: 'De menor a mayor precio', valor: { orderBy: 'precio', orderType: 'desc' } },
  { nombre: 'De mayor a menor precio', valor: { orderBy: 'precio', orderType: 'asc' } }
];
mostrarOrdenar = false;
webVersion = false;
enVista = false;
orderKey = null;

  eventos: Evento[] = [];
  eventosAux: Evento[] = [];
  recomendados: Evento[] = [];
  lastFind;

  constructor(public platform: PlatformService, private location: Location, private storage: StorageService,
              private eventoService: EventoApiService, private seccionService: EventoSeccionService,) { }

  ngOnInit() {
    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[1];
  }

  async back () {

    await this.location.back();
  }

  ionViewWillEnter() {
    this.lastFind = {
          categoria: [],
          fecha: {from: (new Date()).toISOString().substr(0,10), to: null} ,
          genero: [],
          precio: {min: null, max: null},
          recinto: [],
          texto: "",
          promocion: true
      };
    this.obtenerEventos(this.lastFind);
  }

  onFilter(data) {

    this.obtenerEventos(data);
  }

  async obtenerEventos(data) {

    if (!data) {

      data = {}
    }

    let idUser = await this.storage.getUsuarioId();
    const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarPromociones(data, favoritos).subscribe((eventos: Evento[]) => {

        this.eventos = eventos;
        this.eventosAux = eventos;
        this.recomendados = this.eventos.filter((e: Evento) => {

          return e.recomendado;
        })
    });
  }

  // Seccion filtros
  ordenarEventos() {
    if (!this.webVersion) {
      this.mostrarOrdenar = false;
    }
      this.actualizarBusqueda();
  }
  async actualizarBusqueda() {
    if(this.eventos.length == 0) {
      this.eventos = this.eventosAux;
    }
    let eventosOrdenados:any = this.seccionService.ordenarPor(this.eventos, this.orderKey.valor.orderBy, this.orderKey.valor.orderType);
    // console.log(eventosOrdenados);
    this.eventos = [];
    setTimeout(() => {
      this.eventos = eventosOrdenados;
    }, 100);
  }

  async onBuscarEventos(data) {
    if(data.fecha.from == null) {
      data.fecha.from = this.lastFind.fecha.from;
      data.promocion = true;
    }
    // console.group('BusquedaPage@onBuscarEventos');
    console.log(data);
    // console.groupEnd();
    // await this.storage.setMetadata(data);
    this.obtenerEventos(data);
    //await this.router.navigate([ 'busqueda-resultado' ]);
  }

}
