import {Geolocation, GeolocationOptions, Geoposition} from '@ionic-native/geolocation/ngx';
import {Injectable} from '@angular/core';
import {PlatformService} from './platform.service';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService extends Geolocation {

    // TODO: Cambiar al compilar
    develop = false;

    currentLocationIndex = 0;
    testLocations = [
        { coords: { latitude: 20.6737647, longitude: -103.3672196 } }, // 0
    ];

    constructor(private platform: PlatformService) {
        super();
    }

    getCurrentPosition(options?: GeolocationOptions): Promise<Geoposition> {

        let promise;
        if (!this.develop) {

            if (this.platform.isWebBuild()) {

                promise = new Promise<Geoposition>( (resolve, reject) => {

                    return navigator.geolocation.getCurrentPosition(resolve, reject);
                });
            } else {

                promise = super.getCurrentPosition(options);
            }
        } else {
            promise = new Promise<Geoposition>( (resolve, reject) => {

                resolve(this.getDevPosition());
            });
        }

        return promise;
    }

    getDevPosition(): Geoposition {

        return this.testLocations[this.currentLocationIndex] as Geoposition;
    }

    // Calcula En kilómetros, la distancia entre dos locaciones
    static DistanciaDesdeLatLng(latlngLocal, latlngRecinto) {

        if (!latlngLocal || !latlngLocal.lat || !latlngLocal.lng ||
            !latlngRecinto || !latlngRecinto.lat || !latlngRecinto.lng)  {

            return null;
        }

        const radius = 6371;

        const lat_i = latlngLocal.lat  * (Math.PI/180);
        const lat_f = latlngRecinto.lat * (Math.PI/180);
        const lng_i = latlngLocal.lng  * (Math.PI/180);
        const lng_f = latlngRecinto.lng * (Math.PI/180);

        let lng_delta = lng_f - lng_i;

        return (radius * Math.acos( Math.cos(lat_i) * Math.cos(lat_f) * Math.cos(lng_delta) + Math.sin(lat_i) * Math.sin(lat_f)));
    }

}
