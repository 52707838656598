import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BusquedaResultadoPageRoutingModule } from './busqueda-resultado-routing.module';

import { BusquedaResultadoPage } from './busqueda-resultado.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        BusquedaResultadoPageRoutingModule,
        SharedModule
    ],
  declarations: [BusquedaResultadoPage]
})
export class BusquedaResultadoPageModule {}
