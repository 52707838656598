export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDe_i-Jva_aStEHo87JA0-3PV7NfNITKaY",
    authDomain: "butaca-udg-262421.firebaseapp.com",
    databaseURL: "https://butaca-udg-262421.firebaseio.com",
    projectId: "butaca-udg-262421",
    storageBucket: "butaca-udg-262421.appspot.com",
    messagingSenderId: "433871374756",
    appId: "1:433871374756:web:823459a1ef609045d79931",
    measurementId: "G-0K9DBP4CED"
  },
  firebaseConfig: {
    apiKey: "AIzaSyBzlRrpTHsHcdGanTnTE1HTiarmv2vzwjU",
    authDomain: "butaca-udg-be14d.firebaseapp.com",
    projectId: "butaca-udg-be14d",
    storageBucket: "butaca-udg-be14d.appspot.com",
    messagingSenderId: "903014995348",
    appId: "1:903014995348:web:5cd9432c693bbd9820eb81",
    measurementId: "G-2JWWEYXZMG"
  },
  apiKeyContryStateCity: 'RlhlSU5tN2RXdnpzWmdPWWNMa01MNndWaWtqSjkzeVVObFk2OGlIOQ==',
  validacionTwilio: false,
  buildWeb: true,
  GTM: 'GTM-PSGX558C',
};
