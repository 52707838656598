import {Injectable} from '@angular/core';
import { ConfigApiEnum } from '../models/config.api.enum';
import {ToastController} from '@ionic/angular';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConnexionApiService {

  // TODO: Change to production compilation
  public static ENVIRONMENT = 'prod';

  constructor(private toast: ToastController, private storage: StorageService) { }

  static get host(): string {

    if (ConnexionApiService.ENVIRONMENT === 'prod') {

      return ConfigApiEnum.HOST_REMOTE;
    } else if (ConnexionApiService.ENVIRONMENT === 'dev') {

      return ConfigApiEnum.HOST_DEV;
    }

    return ConfigApiEnum.HOST_LOCAL;
  }

  protected static checkMissingParamOnUrl(url: string): boolean {

    if (!url) {
      return true;
    }

    const indexOpen = url.indexOf('{');
    if (indexOpen < 0) {
      return true;
    }

    const indexClose = url.indexOf('}');
    let param: string;
    if (indexClose < 0) {
      param = 'undefined';
    } else {
      param = url.slice(url.indexOf('{') + 1, url.indexOf('}'));
    }

    throw new Error(`Missing ${param} on route.Check ${url}`);
  }

  public getUrl(route: string, params?: any, query?: string): string {

    let url = route;

    if (params) {
        Object.keys(params).forEach( (k) => {
            url = url.replace(`{${k}}`, params[k]);
        });
    }

    ConnexionApiService.checkMissingParamOnUrl(url);
    return `${ConnexionApiService.host}/${url}${query ? `?${query}` : ''}`;
  }

  async sesionFinalizada() {

    this.storage.setUsuario(null);
    this.storage.setRefreshToken(null);
    this.storage.setToken(null);

    const toast = await this.toast.create({
      message: 'Sesión finalizada',
      duration: 2400,
      color: 'warning',
      position: 'top'
    });

    await toast.present();
  }

  async errorEncontrado(msg) {

    const toast = await this.toast.create({
      message: msg,
      duration: 5000,
      color: 'danger',
      position: 'top'
    });

    await toast.present();
  }
}
