import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor( private platform: Platform ) { }

  inicializar() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.injectGTM();
      } else {
        this.loadBrowserGTM();
      }
    });
  }

  injectGTM() {
    const script = document.createElement('script');
    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.GTM}'); `;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }

  loadBrowserGTM() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${environment.GTM}`;
    document.head.appendChild(script);

    script.onload = () => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['gtag'] = function () { window['dataLayer'].push(arguments); };
      window['gtag']('js', new Date());
    };

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  }
}