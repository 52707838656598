import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-udeg-inicio',
  templateUrl: './udeg-inicio.component.html',
  styleUrls: ['./udeg-inicio.component.scss'],
})
export class UdegInicioComponent implements OnInit {

  @Input()
  asModal = true;

  @Output()
  onAccept = new EventEmitter();

  @Output()
  onDecline = new EventEmitter();

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async mostrarFormulario() {

    if (this.asModal) {

      await this.modalController.dismiss({
        role: 'form'
      });
    } else {

      this.onAccept.emit();
    }

  }

  async decline() {

    if (this.asModal) {

      await this.modalController.dismiss({
        role: 'decline'
      });
    } else {

      this.onDecline.emit();
    }

  }

}
