import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CategoriaListaPageRoutingModule } from './categoria-lista-routing.module';

import { CategoriaListaPage } from './categoria-lista.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CategoriaListaPageRoutingModule,
        SharedModule
    ],
  declarations: [CategoriaListaPage]
})
export class CategoriaListaPageModule {}
