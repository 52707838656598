import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.scss'],
})
export class MultimediaComponent implements OnInit {

  @Input()
  images: string[] = [];

  @Input()
  initialImage: string = null;

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  link: string;

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  
  constructor(private modalController: ModalController, private iab: InAppBrowser) { }

  ngOnInit() {
  }

  async dismiss() {
    await this.modalController.dismiss({
      'dismissed': true
    });
  }

  handleError(event: any) {
    event.target.src = 'assets/images/udg.svg';
  }

  showDetails(notificacion) {
    // if(notificacion.url != ''){
      this.iab.create(notificacion, '_system');
    // }
  }

}
