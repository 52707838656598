import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequestApiService} from './http-request-api.service';
import {map, tap} from 'rxjs/operators';
import {Cupon} from '../models/cupon.model';
import { Notify } from '../models/notify.model';

@Injectable({
  providedIn: 'root'
})
export class CuponApiService {

    constructor(private http: HttpRequestApiService) { }

    obtenerTodos(dominio, segmento): Observable<any> {
      return this.http.get('api/v1/cupon?dominio='+dominio+'&segmento='+segmento).pipe((map( (obj: any[]) => {
          return obj.map((c) => new Notify(c));
      })));
    }

}

