import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private loader = null;
    private isActive = false;

    constructor(private loadingController: LoadingController) { }

    async show(message?: string) {

        if (!this.isActive) {

            this.isActive = true;

            this.loader = await this.loadingController.create({
                spinner: 'circles',
                message: message ? message : 'Espere...',
                cssClass: 'custom-loading',
                translucent: false,
                // duration: 3000
            });

            return await this.loader.present().then((a: any) => {
                if (!this.isActive && a) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        }
    }

    async hide() {

        if (this.loader) {
            await this.loader.dismiss();
            this.isActive = false;
        }
    }

}
