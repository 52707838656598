import {Deserializable} from './deserializable.model';

export class Sitio implements Deserializable {

  nombre = 'Conjunto Santander de artes escénicas';
  url = 'http://www.google.com';
  clasificacion: string;

  constructor(obj = null) {

      if (obj) {

        this.deserialize(obj)
      }
  }

  deserialize(obj: any): this {

    Object.assign(this, obj);

    return this;
  }
}
