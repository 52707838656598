import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CrearCuentaPageRoutingModule } from './crear-cuenta-routing.module';

import { CrearCuentaPage } from './crear-cuenta.page';
import {RECAPTCHA_LANGUAGE, RecaptchaModule} from 'ng-recaptcha';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        CrearCuentaPageRoutingModule,
        RecaptchaModule,
        SharedModule
    ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es', // use French language
    }
  ],
  declarations: [CrearCuentaPage]
})
export class CrearCuentaPageModule {}
