import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class DatepickerI18nService implements NgbDatepickerI18n{

    monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    monthShortNames = [
        'Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.',
        'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'
    ];

    dayWeekNames = [
        'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'
    ];

    dayWeekShortNames = [
        'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'
    ];

    getMonthFullName(month: number, year?: number): string {

        return this.monthNames[month - 1];
    }

    getMonthShortName(month: number, year?: number): string {
        return this.monthShortNames[month - 1];
    }

    getWeekNumerals(weekNumber: number): string {
        return this.dayWeekShortNames[weekNumber - 1];
    }

    getWeekdayShortName(weekday: number): string {
        return this.dayWeekShortNames[weekday - 1];
    }

    getYearNumerals(year: number): string {
        return year.toString();
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day} - ${date.month} - ${date.year}`;
    }

    getDayNumerals(date: NgbDateStruct): string {
        return `${date.day} - ${date.month} - ${date.year}`;
    }

}