import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AuthFirebaseService} from '../providers/auth-firebase.service';
import {Platform} from '@ionic/angular';
import {PlatformService} from '../providers/platform.service';
import {UsuarioApiService} from '../providers/usuario-api.service';
import {StorageService} from '../providers/storage.service';
import { HttpRequestApiService } from '../providers/http-request-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verificar-cuenta',
  templateUrl: './verificar-cuenta.page.html',
  styleUrls: ['./verificar-cuenta.page.scss'],
})
export class VerificarCuentaPage implements OnInit{
  validacionTwilio = environment.validacionTwilio;
  telefono = '';

  maxTime = 5;

  currentTime: number; // seconds

  resending = false;

  error = false;

  codigoRenviado = false;

  errorRenvio = false;

  telefonoEnUso = false;

  code = '';

  webVersion = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private platform: PlatformService,
              private authFirebase: AuthFirebaseService, private ngZone: NgZone,
              private http: HttpRequestApiService,
              private usuarioService: UsuarioApiService, private storage: StorageService) { }

  ionViewWillEnter() {
    this.activatedRoute.paramMap.subscribe((p: ParamMap) => {

      this.telefono = atob(p.get('numero'));
    });
    this.currentTime = this.maxTime;
    this.updateTime();
  }

  async onSubmit() {
    const idUsuario = await this.storage.getUsuarioId();
    this.storage.setUsuarioId(idUsuario);
    // this.authFirebase.verifySMSCode(this.code).then(async (resp) => {
    // console.log(resp);
    //   this.error = false;
    //   const getNormal = this.storage.getNormalLogin();
    //   let usuario;
    //   if(getNormal === null){
    //     usuario = await this.authFirebase.loginOnApi();
    //   } else{
    //     this.storage.removeNormalLogin();
    //   }
    //   usuario = await this.usuarioService.actualizarPerfil({
    //     telefono: this.telefono,
    //     esVerificada: true
    //   }).toPromise();
    //   this.storage._isAuth = true;
    //   this.storage.setUsuario(usuario);
    //   // let usuario = this.authFirebase.getFirebaseUser(true);
    //   await this.usuarioService.checkVerificacion(usuario);
    //   await localStorage.setItem('updateTelefonoUsuario', JSON.stringify({ telefono: this.telefono, esVerificada: true }));
    // }).catch((err) => {
    //     if (err.code === 'auth/credential-already-in-use') {
    //       this.telefonoEnUso = true;
    //     }
    //     this.error = true;
    // });

    
    // this.storage.getDataRegisterUser().then(dataRegisterUser=> {
      if(this.validacionTwilio) {
        this.http.put('api/validate/code/'+idUsuario, {sms_code: this.code}).subscribe( async (sendCode:any) => {
        if(sendCode.Validate){
          this.error = false;
          const getNormal = this.storage.getNormalLogin();
          let usuario;
          if(getNormal === null){
            usuario = await this.authFirebase.loginOnApi();
          } else{
            this.storage.removeNormalLogin();
          }
          let idUser = await this.storage.getUsuarioId();
          usuario = await this.usuarioService.actualizarPerfil(idUser, {
            telefono: this.telefono,
            esVerificada: true
          }).toPromise();
          this.storage._isAuth = true;
          this.storage.setUsuario(usuario);
          // let usuario = this.authFirebase.getFirebaseUser(true);
          await localStorage.setItem('updateTelefonoUsuario', JSON.stringify({ telefono: this.telefono, esVerificada: true }));
          await this.usuarioService.checkVerificacion(usuario);
        } else {
          // if (err.code === 'auth/credential-already-in-use') {
          //  this.telefonoEnUso = true;
          // }
          this.error = true;
        }
      }, error => {
        if (error.code === 'auth/credential-already-in-use') {
          this.telefonoEnUso = true;
         }
         this.error = true;
      });
    } else {
      this.http.put('api/validate/email/code/'+idUsuario, {sms_code: this.code}).subscribe( async (sendCode:any) => {
        if(sendCode.Validate == "true"){
          this.error = false;
          const getNormal = this.storage.getNormalLogin();
          let usuario;
          if(getNormal === null){
            usuario = await this.authFirebase.loginOnApi();
          } else{
            this.storage.removeNormalLogin();
          }
          let idUser = await this.storage.getUsuarioId();
          usuario = await this.usuarioService.actualizarPerfil(idUser, {
            correo: this.telefono,
            esVerificada: true
          }).toPromise();
          this.storage._isAuth = true;
          this.storage.setUsuario(usuario);
          // let usuario = this.authFirebase.getFirebaseUser(true);
          await localStorage.setItem('updateCorreoUsuario', JSON.stringify({ correo: this.telefono, esVerificada: true }));
          await this.usuarioService.checkVerificacion(usuario);
        } else {
          // if (err.code === 'auth/credential-already-in-use') {
          //  this.telefonoEnUso = true;
          // }
          this.error = true;
        }
      }, error => {
        if (error.code === 'auth/credential-already-in-use') {
          this.telefonoEnUso = true;
         }
         this.error = true;
      });
    }
      
    // });
   
  }

  updateTime() {
    setTimeout( () => {
      const time = this.currentTime - 0.3;

      if (time > 0) {

        this.currentTime = time;
        this.updateTime();
      } else {

        this.currentTime = 0;
      }
    }, 300);
  }

  timeFormatted() {
    const minutes = Math.floor(this.currentTime / 60);
    const seconds = Math.round(this.currentTime - minutes * 60);
    return `${minutes}:${seconds < 10 ? '0'+seconds : seconds }`;
  }

  codeChange(event) {
    if ( [ "0","1","2","3","4","5","6","7","8","9", "Delete", "Backspace"].indexOf(event.key) < 0 ||
        (this.code.length === 6 && ["Delete", "Backspace"].indexOf(event.key) < 0)) {

      event.preventDefault();
      return;
    }
  }

  async reenviarSMS() {
    this.codigoRenviado = false;
    this.error = false;
    this.errorRenvio = false;
    this.resending = true;
    this.code = '';
    // this.authFirebase.requestSMSCode(this.telefono, true).then( () => {
    //   this.ngZone.run(() => this.codigoRenviado = true);
    // }).catch((err) => {
    //   this.ngZone.run(() => this.errorRenvio = true);
    // }).finally(() => {
    //   this.ngZone.run(() => {
    //     this.resending = false;
    //     this.currentTime = this.maxTime;
    //     this.updateTime();
    //   });
    // });
    // this.storage.getDataRegisterUser().then(dataRegisterUser => {
      const idUsuario = await this.storage.getUsuarioId();
      this.storage.setUsuarioId(idUsuario);
      if(this.validacionTwilio) {
        this.http.put('api/resend/code/'+idUsuario, { telefono: this.storage._usuario.telefono }).subscribe( (sendCode:any) => {
          if(sendCode.message){
            // if(!sendCode.message.message){
            this.ngZone.run(() => this.codigoRenviado = true);
          } else {
            this.ngZone.run(() => this.errorRenvio = true);
          }
        }, error => {
          this.ngZone.run(() => this.errorRenvio = true);
        }, () => {
          this.ngZone.run(() => {
            this.resending = false;
            this.currentTime = this.maxTime;
            this.updateTime();
            });
        });
      } else {
        this.http.put('api/resend/email/code/'+idUsuario, { correo: this.storage._usuario.correo }).subscribe( (sendCode:any) => {
          if(sendCode.message){
            // if(!sendCode.message.message){
            this.ngZone.run(() => this.codigoRenviado = true);
          } else {
            this.ngZone.run(() => this.errorRenvio = true);
          }
        }, error => {
          this.ngZone.run(() => this.errorRenvio = true);
        }, () => {
          this.ngZone.run(() => {
            this.resending = false;
            this.currentTime = this.maxTime;
            this.updateTime();
            });
        });
      }
      
    // });
  }

  ngOnInit(): void {
    this.webVersion = this.platform.isDesktop();
  }

}
