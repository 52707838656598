import {EventEmitter, Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UdegInicioComponent} from '../shared/udeg-inicio/udeg-inicio.component';
import {Router} from '@angular/router';
import {UdegFormularioComponent} from '../shared/udeg-formulario/udeg-formulario.component';
import {UdegAlertComponent} from '../shared/udeg-alert/udeg-alert.component';
import {UsuarioApiService} from './usuario-api.service';
import {Usuario} from '../models/usuario.model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UdegUserService {

    props: any;

    udegData: { codigo: string, nip: string } = { codigo: null, nip: null };

    onUdegDataChange = new EventEmitter<any>();

    constructor(private modalController: ModalController, private router: Router,
                private usuarioApiService: UsuarioApiService, private storage: StorageService) {
    }

    async mostrarModal(props: any) {

        this.resetData();
        this.props = props;

        const modal = await this.modalController.create({
            component: UdegInicioComponent,
            cssClass: 'modal-fullscreen',
            backdropDismiss: false
        });

        modal.onWillDismiss().then(async (obj) => {

            if (obj.data.role === 'decline') {

                if (this.props.declineTarget ) {

                    await this.router.navigate([ this.props.declineTarget ] );
                }

            } else if (obj.data.role === 'form') {

                await this.mostrarFormModal();
            }
        });

        await modal.present();
    }

    resetData() {
        this.usuarioApiService.desuscripcionTotal();
        this.udegData = { codigo: null, nip: null };
        this.onUdegDataChange.emit(this.udegData);
        this.storage.removeAllData();
        this.storage.irLogin();
    }

    async mostrarFormModal() {

        const modal = await this.modalController.create({
          component: UdegFormularioComponent,
          cssClass: 'modal-fullscreen',
          componentProps: {
            target: this.props.target,
            successLabelBtn: this.props.successLabelBtn
          },
          backdropDismiss: false,
        });

        modal.onWillDismiss().then(async (obj) => {

            if (obj.data.role === 'auth') {

                await this.authenticarUdeg(obj.data.codigo, obj.data.nip);
            } else if (this.props && this.props.declineTarget) {

                await this.router.navigate([ this.props.declineTarget ] );
            }
        });

        await modal.present();
    }

    async authenticarUdeg(codigo, nip) {

        this.usuarioApiService.statusUdegUser(codigo).subscribe((data) => {

            this.udegData = { codigo, nip };
            localStorage.setItem('userUdeGDatos', JSON.stringify({ codigo, nip }));
            this.onUdegDataChange.emit(this.udegData);

            if (this.props.auth) {

                this.storage.setUsuario(new Usuario(data.usuario));
            } else {

                this.usuarioApiService.udegData = this.udegData;
            }

            this.mostrarAlert('success', 'Datos verificados', this.props.successLabelBtn);
        }, (err) => {

            console.log(err);
            this.mostrarAlert('error', err.message || 'Tus datos no coinciden', 'Intenta de Nuevo');
        });

    }

    async mostrarAlert(type, message, labelBtn = '') {

        const modal = await  this.modalController.create({
            component: UdegAlertComponent,
            componentProps: {
                type,
                labelBtn: labelBtn,
                message
            },
            cssClass: 'modal-fullscreen',
            backdropDismiss: false
        });

        modal.onWillDismiss().then(async () => {

            if (type === 'success') {

                if (this.props.target) {

                    await this.router.navigate([ this.props.target ] );
                }
            } else {

                await this.mostrarFormModal();
            }
        });

        await modal.present();
    }

}
