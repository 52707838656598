import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Evento} from '../../models/evento.model';
import {PlatformService} from '../../providers/platform.service';
import {PopoverController} from '@ionic/angular';
import {Recinto} from '../../models/recinto.model';
import {StorageService} from '../../providers/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-evento-lista',
  templateUrl: './evento-lista.component.html',
  styleUrls: ['./evento-lista.component.scss'],
})
export class EventoListaComponent implements OnInit, OnChanges {

  @Input()
  eventos: Evento[] = [];

  @Input()
  recintos: Recinto[] = [];

  @Input()
  titulo: string = '';

  @Input()
  categoriaFiltro: string = 'eventos';

  filtro = { recinto: [], fecha: null, precio: null };
  orderKey = null;

  collapseStatus = { recinto: false, fecha: false, precio: false };

  @Output()
  filter = new EventEmitter();
  botonCarga = false;
  webVersion = true;

  precios = [
    {min: null, max:    0 }, {min:    0, max:  100 }, {min:  100, max:  250 }, {min:  250, max:  500 },
    {min:  500, max: 1000 }, {min: 1000, max: 1500 }, {min: 1500, max: 2000 }, {min: 2000, max: null }
  ];

  ordenamiento = [
    { nombre: 'Mayor a menor promoción', valor: { orderBy: 'promocion', orderType: 'desc' } },
    { nombre: 'Fecha lejana', valor: { orderBy: 'fecha', orderType: 'desc' } },
    { nombre: 'Fecha cercana', valor: { orderBy: 'fecha', orderType: 'asc' } },
    { nombre: 'De menor a mayor precio', valor: { orderBy: 'precio', orderType: 'desc' } },
    { nombre: 'De mayor a menor precio', valor: { orderBy: 'precio', orderType: 'asc' } }
  ];

  mostrarFiltrarPor = false;
  mostrarOrdenarPor = false;

  eventosAux:any;
  firschange:any;

  constructor(public platform: PlatformService, private popoverController: PopoverController,
              private storage: StorageService) {
    // this.webVersion = this.platform.isDesktop();
    // this.webVersion = this.platform.is('ipad' || 'tablet');
    // console.log(this.webVersion);
    this.webVersion = environment.buildWeb ? this.platform.isDesktop() : false; 
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('entraron datos');
    this.firschange = changes;
    if (changes.eventos) {
      // El valor del input ha cambiado
      this.eventosAux = changes.eventos.currentValue.slice(0,this.incremento);
      this.incremento = 20;
      this.botonCarga = false;
    }
  }

  ngOnInit() {

    this.recintos = this.storage._recintos;
  }

  toggleFiltrar() {

    if (!this.mostrarFiltrarPor && this.mostrarOrdenarPor) {
      this.mostrarOrdenarPor = false;
    }
    this.mostrarFiltrarPor = !this.mostrarFiltrarPor
  }

  toggleOrdenar() {

    if (this.mostrarFiltrarPor && !this.mostrarOrdenarPor) {
      this.mostrarFiltrarPor = false;

    }

    this.mostrarOrdenarPor = !this.mostrarOrdenarPor
  }

  toggleFiltroRecinto(id) {

    const  index = this.filtro.recinto.indexOf(id);
    if (index < 0) {
      this.filtro.recinto.push(id);
      return;
    }

    this.filtro.recinto.splice(index, 1);
  }

  removerFiltro() {

    this.filtro = { recinto: [], fecha: null, precio: null };
    this.filtrarEventos();
  }

  onDateRangeSelected(range) {

    this.filtro.fecha = range;
  }

  filtrarEventos() {

      this.filter.emit(this.obtenerFiltroData());
  }

  obtenerFiltroData() {

    const filtro: any = {};

    if (this.filtro.recinto) {
      filtro.recinto = this.filtro.recinto;
    }

    if (this.filtro.fecha) {

      filtro.fecha = {};
      filtro.fecha.from = this.formatFecha(this.filtro.fecha.from);
      filtro.fecha.to = this.formatFecha(this.filtro.fecha.to);
    }

    if (this.filtro.precio) {
      filtro.precio = this.filtro.precio;
    }

    let data: any = {};
    if (this.orderKey) {
      data = Object.assign({}, this.orderKey);
    }

    if (Object.keys(filtro).length !== 0) {
      data.filtros = filtro;
    }

    return data;
  }

  limpiarFiltro() {
    this.filtro.precio = null;
  }

  formatFecha(fecha: Date) {

    if (!fecha) {

      return fecha;
    }
    fecha = new Date(fecha)

    return `${fecha.getFullYear()}-${fecha.getUTCMonth() < 9 ? '0' : ''}${fecha.getUTCMonth() + 1}-${fecha.getUTCDate() < 9 ? '0' : ''}${fecha.getUTCDate()}`
  }

  incremento = 20;

  ionInfinite(event:any) {
   
    // setTimeout(() => {
    //   this.incremento += 20; 
    //   // const newData = this.eventos.slice(0,this.incremento);
    //   // this.eventosAux = {...this.eventosAux, ...newData};
    //   this.eventosAux = this.eventos.slice(0,this.incremento);
    //   // // if(this.eventosAux.length >= this.eventos.length) {
    //     ev.target.complete();
    //   // // }
    // }, 500);

    // setTimeout(() => {
      this.incremento += 20; 
      
      if(this.eventosAux.length >= this.eventos.length) {
        this.botonCarga = true;
      } else {
        this.eventosAux = this.eventos.slice(0,this.incremento);
        if(this.eventosAux.length >= this.eventos.length) {
          this.botonCarga = true;
        } else{
          this.botonCarga = false;
        }
      }
      // event.target.complete();
      // }, 1000)
  }

}
