import {Injectable} from '@angular/core';
import {Evento} from '../models/evento.model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventoSeccionService {

    constructor(private storage: StorageService) { }

    seccionarEventos(eventos) {
        // const nombre = ['hoy', 'semana', 'mes'];
        // const secciones: any = {};
        // secciones.promociones = eventos.filter((e: Evento) => !(!e.textoPromocion));
        // nombre.forEach(s => {
        //     secciones[s] = eventos.filter((e: Evento) => e.seccion === s);
        // });
        // // secciones.cerca = eventos.filter((e: Evento) => 
        // //     this.storage._recintos_cerca.indexOf(e.recinto) >= 0 
        // // );
        // secciones.cerca = eventos.filter(e => this.storage._recintos_cerca.includes(e.recinto));
        // secciones.hoy = this.obtenerEventosPorPeriodo(eventos, 'hoy');
        // secciones.semana = this.obtenerEventosPorPeriodo(eventos, 'semana');
        // secciones.mes = this.obtenerEventosPorPeriodo(eventos, 'mes');
        // return secciones;

        // const nombre = ['misEventos', 'gratuitos', 'cerca'];
        let GPSNoActivo = localStorage.getItem('GPSNoActivo');
        let PositionGPS = localStorage.getItem('PositionGPS');
        const secciones: any = {};
        // secciones.promociones = eventos.filter((e: Evento) => !(!e.textoPromocion));
        secciones.misEventos = eventos.paraTi;
        let listaEventos = [];
        if(eventos.eventos == undefined) {
            if(eventos == undefined) {
                listaEventos = [];
            } else {
                listaEventos = eventos;
            }
        } else {
            listaEventos = eventos.eventos;
        }
        secciones.gratuitos = listaEventos.filter((e: Evento) => (e.esGratuito  || (e.precio_alto == '0' && e.precio_bajo == '0')));
        if(GPSNoActivo) {
            secciones.cerca = [];
        } else {
            let posicion = JSON.parse(PositionGPS);
            secciones.cerca = listaEventos.filter((e: Evento) => {
                let distancia = parseFloat(this.getKilometros(parseFloat(e.latitud), parseFloat(e.longitud), posicion.latitude, posicion.longitude));
                return distancia <= 5; // Esto ahora retorna un booleano
            });
        }
        return secciones;
    }

    obtenerEventosPorPeriodo(eventos, periodo) {
      const normalizarFechaDesdeCadena = (fechaCadena) => {
          const [año, mes, día] = fechaCadena.split('-').map(num => parseInt(num, 10));
          // Crear fecha como UTC para evitar problemas de zona horaria
          return new Date(Date.UTC(año, mes - 1, día));
      };
  
      const normalizarFecha = (fecha) => {
          fecha.setUTCHours(0, 0, 0, 0);
          return fecha;
      };
  
      const fechaActual = normalizarFecha(new Date());
      const diaSemana = fechaActual.getUTCDay();
      const primerDiaSemana = new Date(Date.UTC(fechaActual.getUTCFullYear(), fechaActual.getUTCMonth(), fechaActual.getUTCDate() - diaSemana));
      const unaSemanaDespues = new Date(primerDiaSemana);
      unaSemanaDespues.setUTCDate(primerDiaSemana.getUTCDate() + 7);
      const unMesDespues = new Date(Date.UTC(fechaActual.getUTCFullYear(), fechaActual.getUTCMonth() + 1, fechaActual.getUTCDate()));
  
      return eventos.filter(evento => {
          const fechaInicio = normalizarFechaDesdeCadena(evento.fechaInicio);
          const fechaFin = normalizarFechaDesdeCadena(evento.fechaFin);
  
          if (periodo === "hoy") {
              return fechaInicio <= fechaActual && fechaFin >= fechaActual;
          } else if (periodo === "semana") {
              return fechaInicio <= unaSemanaDespues && fechaFin >= primerDiaSemana;
          } else if (periodo === "mes") {
              return fechaInicio <= unMesDespues && fechaFin >= fechaActual;
          }
  
          // Considera agregar un manejo para casos donde el periodo no es reconocido
      });
  }

  getKilometros(lat1,lon1,lat2,lon2) {
    let rad = function(x) {return x*Math.PI/180;}
    var R = 6378.137; //Radio de la tierra en km
    var dLat = rad( lat2 - lat1 );
    var dLong = rad( lon2 - lon1 );
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d.toFixed(3); //Retorna tres decimales
  }

  ordenarPor(array, parametro, modo) {
    if(parametro == 'fecha') {
      const campo = modo === 'asc' ? 'fecha_fin' : 'fecha_inicio';
      return array.sort((a, b) => {
        const fechaA = new Date(a[campo]);
        const fechaB = new Date(b[campo]);
        if (modo === 'asc') {
          return fechaA.getTime() - fechaB.getTime();
        } else {
          return fechaB.getTime() - fechaA.getTime();
        }
      });
    }
    if(parametro == 'precio') {
      const campo = modo === 'asc' ? 'precio_alto' : 'precio_bajo';
      return array.sort((a, b) => {
        const precioA = a[campo];
        const precioB = b[campo];
        if (modo === 'asc') {
          return precioB - precioA;
        } else {
          return precioA - precioB;
        }
      });
    }
    if(parametro == 'promocion'){
      return array
      .filter(item => item.textoPromocion && !isNaN(parseInt(item.textoPromocion.replace('%', ''))))
      .sort((a, b) => {
        const porcentajeA = parseInt(a.textoPromocion.replace('%', ''));
        const porcentajeB = parseInt(b.textoPromocion.replace('%', ''));
        return porcentajeB - porcentajeA;
      });
    
    }
    if(parametro == 'alfabeto') {
      return array.sort((a, b) => {
        const nombreA = a.nombre.toUpperCase();
        const nombreB = b.nombre.toUpperCase();
    
        if (nombreA < nombreB) {
          return modo === 'asc' ? -1 : 1;
        } else if (nombreA > nombreB) {
          return modo === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  }
  
}

