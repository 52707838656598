import {Component, Input, OnInit} from '@angular/core';
import {Banner} from '../../models/banner.model';
import {StorageService} from '../../providers/storage.service';
import {BannerApiService} from '../../providers/banner-api.service';
import {ConnexionApiService} from '../../providers/connexion-api.service';
import {PlatformService} from '../../providers/platform.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {

  // ubicacion_imagen full=0, izquierda=1, derecha=2
  // ubicacion arriba=0, abajo=1


  @Input()
  ubicacion = 'BOTTOM';
  ubicacion_imagen = 'FULL_WIDTH';

  banner: Banner;

  webVersion = false;

  constructor(private storage: StorageService, private platform: PlatformService, private bannerService: BannerApiService, private iab: InAppBrowser) { }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

  ngAfterViewInit() {

    if (!this.storage._banners || !this.storage._banners.length) {

      this.bannerService.obtenerTodos(0,0).subscribe((banners: Banner[]) => {

        this.storage._banners = banners;
        this.getBanner(banners);
      })

    } else {

      this.getBanner();
    }
  }

  getBanner(bannersLst = null) {
    if(bannersLst == null) {
      if (!this.storage._banners || !this.storage._banners.length) {
        return;
      }
      const banners = this.storage._banners.filter((b: Banner) => b.ubicacion == (this.ubicacion == 'BOTTOM' ? 1 : 0).toString() );
      if (!banners.length) {
        return;
      }
      this.banner = banners[Math.floor(banners.length * Math.random())];
      this.ubicacion_imagen = this.banner.ubicacion_imagen == 0 ? 'FULL_WIDTH' : (this.banner.ubicacion_imagen == 1 ? 'LEFT' : 'RIGHT');
      console.log('carga');
      console.log(this.banner);
    } else {
      const banners = bannersLst.filter((b: Banner) => b.ubicacion == (this.ubicacion == 'BOTTOM' ? 1 : 0).toString() );
      if (!banners.length) {
        return;
      }
      this.banner = banners[Math.floor(banners.length * Math.random())];
      // console.log('lista');
      // console.log(this.banner);
      this.ubicacion_imagen = this.banner.ubicacion_imagen == 0 ? 'FULL_WIDTH' : (this.banner.ubicacion_imagen == 1 ? 'LEFT' : 'RIGHT');
    }
  }

  imageUrl(relativePath: string): string {

    return `${ConnexionApiService.host.replace('api','app.')}/uploads/banner/${relativePath}`;
  }

  showDetails(banner) {
    if (banner.url && banner.url.trim() !== ''){
      this.iab.create(banner.url, '_system');
    }
  }

}
