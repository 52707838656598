import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-udeg-formulario',
  templateUrl: './udeg-formulario.component.html',
  styleUrls: ['./udeg-formulario.component.scss'],
})
export class UdegFormularioComponent implements OnInit, AfterViewInit {

  @Input()
  asModal = true;

  @Output()
  onSendForm = new EventEmitter();

  authForm: FormGroup;

  submitted = false;

  constructor(private formBuilder: FormBuilder, private modalController: ModalController) {

    this.authForm = this.formBuilder.group({
      codigo: [ '', [ Validators.required ] ],
      nip: [ '', [ ] ]
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.submitted = false;
  }

  async authUdeG() {

    this.submitted = true;

    if (this.authForm.invalid) {
      return;
    }

    if (this.asModal) {

      await this.modalController.dismiss({
        role: 'auth',
        codigo: this.authForm.value.codigo,
        nip: this.authForm.value.nip
      });
    } else {

      this.onSendForm.emit({
        codigo: this.authForm.value.codigo,
        nip: this.authForm.value.nip
      })
    }
  }

}
