import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UsuarioApiService} from '../providers/usuario-api.service';
import {timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {RecaptchaComponent} from 'ng-recaptcha';
import {AlertController} from '@ionic/angular';
import {AuthFirebaseService} from '../providers/auth-firebase.service';
import {PlatformService} from '../providers/platform.service';
import { StorageService } from '../providers/storage.service';
import { HttpRequestApiService } from '../providers/http-request-api.service';
import { LoadingService } from '../providers/loading-service';
import { DataService } from '../providers/data.service';

@Component({
  selector: 'app-crear-cuenta',
  templateUrl: './crear-cuenta.page.html',
  styleUrls: ['./crear-cuenta.page.scss'],
})
export class CrearCuentaPage implements OnInit {

  registerForm: FormGroup;

  tyc: false;

  submitted = false;

  submitting = false;

  confirmEmail: string;

  confirmPassword: string;

  captcha = { token: null, error: false, checked: false, message: null };

  @ViewChild('captchaElement', { static: true })
  captchaElement: RecaptchaComponent;

  webVersion = false;

  constructor(private formBuilder: FormBuilder, private usuarioService: UsuarioApiService,
              private router: Router, private alertController: AlertController, private ngZone: NgZone,
              private authFirebase: AuthFirebaseService, private platform: PlatformService,
              private storage: StorageService, private http: HttpRequestApiService,
              private loadingService: LoadingService, private dataCiudades: DataService
              ) {

    this.registerForm = this.formBuilder.group({
      username: [ '', [ Validators.required, Validators.minLength(3),
        Validators.pattern(/^[.@a-zA-Z0-9_-]{3,}$/) ], [ this.usernameIsTaken(this.usuarioService) ] ],
        email: new FormControl(
          '', {
            validators: [ Validators.required, Validators.email,
              this.hasSameValue('confirmEmail').bind(this) ],
            asyncValidators: [ this.emailIsTaken(this.usuarioService) ]
          }
      ),
      password: ['', [ Validators.required, Validators.minLength(5),
        this.hasSameValue('confirmPassword').bind(this) ]],
      // dia:  [ '', [ Validators.required, this.dateIsNotValid.bind(this)  ] ],
      // mes:  [ '', [ Validators.required ] ],
      // anno: [ '', [ Validators.required ] ],
      fecha: [ '', [ Validators.required ] ],
      genero: [ '', [ Validators.required ] ],
      // nombre: [ '', [ Validators.required ] ],
      estado: [ '', [ Validators.required ] ],
      ciudad: [ '', [ Validators.required ] ]
    });
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }
  estadosLista = [];
  ciudadesLista = [];
  activaCiudad =false;
  ionViewWillEnter() {
    this.activaCiudad = false;
    this.estadosLista = this.dataCiudades.getEstados();
    console.log(this.estadosLista);
  }
  cambioCiudad(event) {
    this.activaCiudad = true;
    this.ciudadesLista = this.dataCiudades.getCiudades((event.detail.value));
    this.registerForm.get('ciudad').reset();
  }

  async onSubmit() {

    this.submitted = true;

    if (this.registerForm.invalid || !this.tyc || this.captcha.error) {
      return;
    }
    let datos:any = await localStorage.getItem('userUdeGDatos');
    if(datos == null){
      datos = {codigo: null, nip: null};
    } else {
      datos = JSON.parse(datos);
    }
    let data = this.registerForm.value;
    data.fechaNacimiento = this.registerForm.value['fecha']; //this.getDateArray().join('/');
    data.correo = data.email;
    data.nombre = data.email;
    data.codigo = datos.codigo;
    data.nip = datos.nip;
    data.provider_id = 'email';

    this.usuarioService.createUser(data).subscribe(async (dataRegisterUser) => {
      this.storage.setDataRegisterUser(dataRegisterUser);
      await this.authFirebase.createUserFromCredentials(this.registerForm.value['email'],
          this.registerForm.value['password']);

      await this.usuarioService.login({
        username: this.registerForm.get('username').value,
        password: this.registerForm.get('password').value
      }, false);
    }, async (err) => {

      await this.createSimpleAlert(err.message ? err.message : 'Error al crear usuario. Inténtelo más tarde.');
    });

    return;
  }

  captCharesolved(captchaResponse: string) {

    this.captcha.token = captchaResponse;

    this.usuarioService.verificarCaptha(captchaResponse).subscribe((response: any) => {

      this.captcha.checked = true;
      if (response && response.success) {

        this.captcha.error = false;
      } else {

        this.captcha.error = true;
        this.captcha.message = '¿Seguro que eres humano?';
        this.captchaElement.reset();
      }
    }, () => {

      this.captcha.checked = true;
      this.captcha.error = true;
      this.captcha.message = 'Ocurrió un error al verificar el Captcha. Inténtelo de nuevo.';
      this.captchaElement.reset();
    });
  }

  hasSameValue(confirmField: string) {

    return (c: FormControl) => {

      return this[confirmField] && this[confirmField] === c.value ? null : {
        hasSameValue: { valid: true }
      };
    }
  }

  emailIsTaken(userService: UsuarioApiService) {

    return (c: FormControl) => {

      return timer(500).pipe(switchMap(() => {

        return userService.checkEmail(c.value)
      }), map( (data: any) => {

        return !data.user ? null : { 'emailIsTaken': true }
      }));
    }
  }

  usernameIsTaken(userService: UsuarioApiService) {

    return (c: FormControl) => {

      return timer(500).pipe(switchMap(() => {

        return userService.checkUsername(c.value)
      }), map( (data: any) => {

        return !data.user ? null : { 'usernameIsTaken': true }
      }));
    }
  }

  // dateIsNotValid(c: FormControl) {

  //     if (!this.registerForm || !this.registerForm.value)
  //       return null;

  //     let date = this.getDateArray();

  //     let maxDays = 31;
  //     if ( date[1] == 1 ) {
  //       maxDays = (date[2] % 4 == 0 && date[2] % 100) || date[2] % 400 == 0 ? 29 : 28;
  //     } else if ( [ 8, 3, 5, 10].indexOf(date[1]) >= 0 ) {
  //       maxDays = 30;
  //     }

  //     return date[1] >= 0 && date[1] < 12 && date[0] > 0 && date[0] <= maxDays ? null : { dateIsNotValid: true };
  // }

  // getDateArray() {

  //   let d = new Date(this.registerForm.value['dia']).getDate();
  //   let m = new Date(this.registerForm.value['mes']).getMonth();
  //   let y = new Date(this.registerForm.value['anno']).getFullYear();

  //   return [d, m , y]
  // }

  async createSimpleAlert(message: string) {

    const alert = await this.alertController.create({
      header: 'Notificación',
      message: message,
      buttons: ['Ok']
    });

    return alert.present();
  }

  irLogin(){
    this.storage.irLogin();
  }

  inputFechaFoco = false;
  onFocus() {
    this.inputFechaFoco = true;
  }

  onBlur() {
    this.inputFechaFoco = false;
  }

}
