import { Component, OnInit } from '@angular/core';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {Sitio} from '../models/sitio.model';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {InfoApiService} from '../providers/info-api.service';

@Component({
  selector: 'app-sitios-interes',
  templateUrl: './sitios-interes.page.html',
  styleUrls: ['./sitios-interes.page.scss'],
})
export class SitiosInteresPage implements OnInit {

  webVersion = false;

  agrupacion: Sitio[]= [];

  grupo = false;

  totalGrupo = 0;

  constructor(private platform: PlatformService, private location: Location, private infoApiService: InfoApiService,
              private iab: InAppBrowser) { }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

  ionViewWillEnter() {

    this.infoApiService.sitiosInteres().subscribe((sitios: Sitio[]) => {
      this.agrupar(sitios);
    });
  }

  async back () {

    await this.location.back();
  }

  agrupar(sitios: Sitio[]) {

    const grupos: string[] = [];

    sitios.forEach((r: Sitio) => {

      if (r.clasificacion && grupos.indexOf(r.clasificacion) < 0) {

        grupos.push(r.clasificacion);
      }
    });

    this.totalGrupo = grupos.length;
    this.agrupacion = sitios.sort((a: Sitio, b: Sitio) => {

      if (!a.clasificacion && b.clasificacion) {

        return -1;
      }

      if (!b.clasificacion && a.clasificacion) {

        return 1;
      }

      if ( (!a.clasificacion && !b.clasificacion) || a.clasificacion === b.clasificacion) {

        return 0;
      }

      return grupos.indexOf(a.clasificacion) > grupos.indexOf(b.clasificacion) ? 1: -1;
    });
  }

  mostrarGrupo(sitio, iniciar = false) {

    if (iniciar) {
      this.grupo = sitio.clasificacion;
      return true;
    }

    const test = this.grupo !== sitio.clasificacion;

    if (test) {
      this.grupo = sitio.clasificacion;
    }

    return test;
  }

  goTo(sitio: Sitio) {

    const iabInstance = this.iab.create(sitio.url, '_system');
    iabInstance.close();
  }

}
