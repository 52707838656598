import { Injectable } from '@angular/core';
import {HttpRequestApiService} from './http-request-api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Banner} from '../models/banner.model';

@Injectable({
  providedIn: 'root'
})
export class BannerApiService {

  constructor(private http: HttpRequestApiService) { }

  obtenerTodos(spinner = 1, hideload = 1) {
    return this.http.get('api/v1/banner'+"?spinner="+spinner+"&hideload="+hideload, {});
    // return this.http.get('api/v1/banner'+"?spinner="+spinner+"&hideload="+hideload, {}).pipe((map( (obj: any[]) => {

    //   return obj.map((b) => new Banner(b));
    // })));
  }

}
