import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpRequestApiService} from './http-request-api.service';
import {map, tap} from 'rxjs/operators';
import {Categoria} from '../models/categoria.model';
import {Evento} from '../models/evento.model';
import {Recinto} from '../models/recinto.model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventoApiService {

    constructor(private http: HttpRequestApiService, private storage: StorageService) { }

    private eventoSource = new Subject<any>();
    eventoObservable$ = this.eventoSource.asObservable();
    emitirEvento(data: any) {
      this.eventoSource.next(data);
    }

    obtenerEventosParaTi(data: {latitude: any, longitude: any }, idUser, favoritos, spinner = 1, hideload = 1): Observable<any> {

      const query = data ? `lat=${data.latitude}&lng=${data.longitude}`: '';
      return this.http.get('api/v1/evento/para_ti/'+idUser+"?spinner="+spinner+"&hideload="+hideload+'&lat='+data.latitude+'&lng='+data.longitude, {}).pipe((map( (obj: any) => {
        const fecha =  new Date();
        const fecha2 = fecha.getFullYear() +'-'+( fecha.getMonth() + 1)+'-'+fecha.getDate();
        const obj_aux = this.filtrarPorFechaBase(fecha2, obj.eventos);
        obj.eventos = obj_aux;
            return {
              paraTi: obj.para_ti.map((e: any) => new Evento(e, favoritos.intereses)),
              eventos: obj.eventos.map((e: any) => new Evento(e)),
              recintos: obj.recintos.map((r: any) => new Recinto(r, favoritos.intereses)),
              categorias: obj.categorias.map((c: any) => new Categoria(c)),
              recintos_cerca: obj.recintos_cercas.map((r: any) => r.id)
          };
      })));
    }

    // filtrarPorFechaInicio(fechaInicio, datos) {
    //     fechaInicio =  new Date(fechaInicio); 
    //     return datos.filter(function(evento) {
    //       var fechaInicioEvento = new Date(evento.fecha_inicio);
    //       var fechaFinEvento = new Date(evento.fecha_fin);
    //       return fechaInicio >= fechaInicioEvento && fechaInicio <= fechaFinEvento;
    //     });
    // }
    filtrarPorFechaBase(fechaBase, datos) {
        const parseFechaUTC = (fecha: string): Date => {
            const [año, mes, día] = fecha.split('-').map(num => parseInt(num, 10));
            return new Date(Date.UTC(año, mes - 1, día));
        };
    
        const fechaBaseObj = parseFechaUTC(fechaBase);
    
        return datos.filter(dato => parseFechaUTC(dato.fecha_inicio) >= fechaBaseObj);
    }
    

    filtrarTodoEventos(data, intereses): Observable<Evento[]> {
        let filtros: {
            categoria: [],
            fecha: {from: null, to: null},
            genero: [],
            precio: {min: null, max: null},
            recinto: [],
            texto: ""
        }
        filtros = data.filtros;
        if(!data.filtros.hasOwnProperty('categoria'))filtros.categoria = [];
        if(!data.filtros.hasOwnProperty('genero')) filtros.genero = [];
        if(!data.filtros.hasOwnProperty('recinto')) filtros.recinto = [];
        if(!data.filtros.hasOwnProperty('texto')) filtros.texto = "";
        if(!data.filtros.hasOwnProperty('fecha')) filtros.fecha = {from: null, to: null};
        if(!data.filtros.hasOwnProperty('precio')) filtros.precio = {min: null, max: null};
        return this.http.post('api/evento/filtrar', {filtros: filtros}).pipe((map( (obj: any) => {

            return obj.map((e: any) => new Evento(e, intereses));
        })))
    }

    filtrarFuturosEventos(filtro): Observable<Evento[]> {
        return this.http.post('api/evento/futuros', {filtro: filtro}).pipe((map( (obj: any) => {
            return obj.map((e: any) => new Evento(e));
        })))
    }

    filtrarPromociones(data, intereses): Observable<Evento[]> {
        // let filtros: {
        //     categoria: [],
        //     fecha: {from: null, to: null},
        //     genero: [],
        //     precio: {min: null, max: null},
        //     recinto: [],
        //     texto: ""
        // }
        // filtros = data.filtros;
        // if(!data.filtros.hasOwnProperty('categoria'))filtros.categoria = [];
        // if(!data.filtros.hasOwnProperty('genero')) filtros.genero = [];
        // if(!data.filtros.hasOwnProperty('recinto')) filtros.recinto = [];
        // if(!data.filtros.hasOwnProperty('texto')) filtros.texto = "";
        // if(!data.filtros.hasOwnProperty('fecha')) filtros.fecha = {from: null, to: null};
        // if(!data.filtros.hasOwnProperty('precio')) filtros.precio = {min: null, max: null};
        return this.http.post('api/evento/promociones', {filtros: data}).pipe((map( (obj: any) => {

            return obj.map((e: any) => new Evento(e, intereses));
        })))
    }

    obtenerEventoFavoritos(idUser, spinner = 1, hideload = 1): Observable<Evento[]> {

        return this.http.get('api/v1/evento/liked/'+idUser+"?spinner="+spinner+"&hideload="+hideload).pipe((map( (obj: any) => {

            return obj;//.map((e: any) => new Evento(e));
        })));
    }

    obtenerEventoInfo(id: string |  number, intereses): Observable<Evento> {

        return this.http.get('api/v1/evento/' + id).pipe((map( (obj: any) => {

            return new Evento(obj[0], intereses);
        })));
    }

    toggleLike(id: string | number, idUser) : Observable<any[]> {

        return this.http.post(`api/v1/evento/${id}/like/toggle`, {"user_id" : idUser}).pipe( map((obj: any[]) => {

            // const likeit = obj.map((l: any) => l.id);

            // this.storage._usuario.likeEventos = likeit;

            // // Actualiza el usuario
            // this.storage.setUsuario(this.storage._usuario);

            return obj;
        }))

    }
    toggleDislike(id: string | number, idUser) : Observable<any[]> {

        return this.http.post(`api/v1/evento/delete/${id}`, {"user_id" : idUser}).pipe( map((obj: any[]) => {

            // const likeit = obj.map((l: any) => l.id);

            // this.storage._usuario.likeEventos = likeit;

            // // Actualiza el usuario
            // this.storage.setUsuario(this.storage._usuario);

            return obj;
        }))

    }

    obtenerGeneros(spinner = 1, hideload = 1): Observable<any[]> {

        return this.http.get('api/v1/generos'+"?spinner="+spinner+"&hideload="+hideload).pipe((map( (obj: any) => {

            return obj;//.map((e: any) => new Evento(e));
        })));
    }

}

