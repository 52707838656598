import {Deserializable} from './deserializable.model';

export class Genero implements Deserializable {

  id = 0;
  nombre = '';

  constructor(obj = null) {

      if (obj) {

        this.deserialize(obj)
      }
  }

  deserialize(obj: any): this {

    Object.assign(this, obj);

    return this;
  }
}
