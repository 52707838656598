import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AuthFirebaseService} from '../providers/auth-firebase.service';
import {Usuario} from '../models/usuario.model';
import {UsuarioApiService} from '../providers/usuario-api.service';
import { StorageService } from '../providers/storage.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.page.html',
  styleUrls: ['./google-auth.page.scss'],
})
export class GoogleAuthPage implements OnInit {

  type = 'register';

  tyc = false;

  usuario: Usuario = new Usuario();

  constructor(private activatedRoute: ActivatedRoute, private authFirebaseService: AuthFirebaseService, private alertController: AlertController,
              private router: Router, private usuarioService: UsuarioApiService, private storage: StorageService) { }

  ionViewWillEnter() {

    this.activatedRoute.paramMap.subscribe((p: ParamMap) => {

      this.type = p.get('type');
    });

    this.usuario = this.authFirebaseService.getFirebaseUser();
  }

  isValid() {

    return this.tyc;
  }

  continuar() {

    if (this.type === 'register') {

      this.signUp(true);
    } else {

      this.login(true);
    }
  }

  async login(retry = false) {

    const result: any =  await this.authFirebaseService.googleAuth(retry);
    
    this.usuario = this.authFirebaseService.getFirebaseUser(true);
    // console.log('result login');
    // console.log(result);
    if (result) {
      if(result.intereses == null){
        this.router.navigate(['/intereses']);
      } else {
        this.router.navigate(['/home']);
      }
      // this.router.navigate([ this.usuario.estaVerificada ? '/intereses': '/cuenta-telefono' ]);
    } else {
      const alertMensaje = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Inicia Sesión UdeG',
        message: 'Existe un problema con tu inicio de sesión',
        buttons:['Ok']});
      await alertMensaje.present();
    }

  }

  signUp(retry = false) {

    this.authFirebaseService.googleSignUp(retry).then( async (result) => {
      // console.log('result signUp');
      // console.log(result);
      this.usuario = this.authFirebaseService.getFirebaseUser(true);

      if (result) {
        if(result.intereses == null){
          this.router.navigate(['/intereses']);
        } else {
          this.router.navigate(['/home']);
        }
        // this.router.navigate([ this.usuario.estaVerificada ? '/intereses': '/cuenta-telefono' ]);
      }else {
        const alertMensaje = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Inicia Sesión UdeG',
          message: 'Existe un problema con tu inicio de sesión',
          buttons:['Ok']});
        await alertMensaje.present();
      }

    }, error => { console.log(error); });
  }

  ngOnInit(): void {

    this.authFirebaseService.userSubject().subscribe((usuario: Usuario) => {
      this.usuario = usuario;
    });
  }

  irA(path) {
    if(path == '') {
      this.router.navigate(['/registro']);
    } else {
      this.storage.irLogin();
    }
  }

}
