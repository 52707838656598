import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Evento} from '../../models/evento.model';
import {PlatformService} from '../../providers/platform.service';
import {StorageService} from '../../providers/storage.service';
import {EventoApiService} from '../../providers/evento-api.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import { ConnexionApiService } from 'src/app/providers/connexion-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evento-thumbnail',
  templateUrl: './evento-thumbnail.component.html',
  styleUrls: ['./evento-thumbnail.component.scss'],
})
export class EventoThumbnailComponent implements OnInit {

  @Input()
  evento: Evento;

  @Input()
  promocional: boolean = false;

  @Input()
  vistaAlterna: boolean;

  @Input()
  isCompactView = false;

  @Input()
  gradient = false;

  @Input()
  mostrarComprar = false;

  @Output() reload = new EventEmitter<any>();

  get liked() {

    return this.evento ? this.storage._usuario.likeEventos.indexOf(this.evento.id) >= 0 : false;
  }

  constructor(public platform: PlatformService, private storage: StorageService,
              private router: Router,
              private eventoService: EventoApiService, private iab: InAppBrowser) { }

  ngOnInit() {}

  public async toggleLike(event:MouseEvent) {
    event.stopPropagation();
    let usuario = await this.storage.getUsuario();
    if(usuario.username == 'invitado'){
      return;
    }
    if (this.evento) {
      let idUser = await this.storage.getUsuarioId();
      if(this.evento.liked) {
        await this.eventoService.toggleDislike(this.evento.id, idUser).toPromise();
        this.reload.emit(null);
        this.eventoService.emitirEvento({data: 'alguna data'});
        this.evento.liked = false;
      } else {
        await this.eventoService.toggleLike(this.evento.id, idUser).toPromise();
        this.reload.emit(null);
        this.eventoService.emitirEvento({data: 'alguna data'});
        this.evento.liked = true;
      }

      // this.eventoService.obtenerEventoFavoritos(idUser).subscribe((likes:any) => {
      //   let dt = likes.intereses.filter( fl => fl.evento_id == this.evento.id );
      //   this.evento.liked = (dt.length > 0) ? true : false;
      // });
      // const likeList = await this.eventoService.toggleLike(this.evento.id, idUser).toPromise();      
    }
  }

  public comprar() {

    const iabInstance = this.iab.create(this.evento.urlCompra, '_system');
    iabInstance.close();
  }

  imageUrl(foto: string): string {

    return `${ConnexionApiService.host.replace('api','app.')}/uploads/evento/${foto}`;
  }

  ira(url){
    this.router.navigateByUrl(url);
  }

}
