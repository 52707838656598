import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Usuario} from '../models/usuario.model';
import {AuthFirebaseService} from '../providers/auth-firebase.service';
import {UsuarioApiService} from '../providers/usuario-api.service';
import { StorageService } from '../providers/storage.service';

@Component({
  selector: 'app-facebook-auth',
  templateUrl: './facebook-auth.page.html',
  styleUrls: ['./facebook-auth.page.scss'],
})
export class FacebookAuthPage implements OnInit {

  type = 'register';

  tyc = false;

  usuario: Usuario = new Usuario();

  constructor(private activatedRoute: ActivatedRoute, private authFirebaseService: AuthFirebaseService,
              private router: Router, private usuarioService: UsuarioApiService, private storage: StorageService) { }

  ionViewWillEnter() {

    this.activatedRoute.paramMap.subscribe((p: ParamMap) => {

      this.type = p.get('type');
    });

    this.usuario = this.authFirebaseService.getFirebaseUser();
  }

  isValid() {

    return this.tyc;
  }

  async continuar() {

    if (this.type === 'register') {

      this.signUp(true);
    } else {

      this.login(true);
    }
  }

  async login(retry = false) {

    if (!retry) {
      await this.authFirebaseService.logout();
    }

    const result: any =  await this.authFirebaseService.facebookAuth(retry);

    this.usuario = this.authFirebaseService.getFirebaseUser();

    if (result) {

      await this.usuarioService.checkVerificacion(result);
    }

  }

  async signUp(retry = false) {

    if (!retry) {
      await this.authFirebaseService.logout();
    }

    this.authFirebaseService.facebookSignUp(retry).then( (result) => {

      this.usuario = this.authFirebaseService.getFirebaseUser();

      if (result) {

        this.router.navigate([ this.usuario.estaVerificada ? '/intereses': '/cuenta-telefono' ]);
      }

    });
  }

  ngOnInit(): void {

    this.authFirebaseService.userSubject().subscribe((usuario: Usuario) => {
      this.usuario = usuario;
    });
  }

  irA(path) {
    if(path == '') {
      this.router.navigate(['/registro']);
    } else {
      this.storage.irLogin();
    }
  }
}
