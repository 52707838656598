import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LoginRecuperarContraPageRoutingModule } from './login-recuperar-contra-routing.module';

import { LoginRecuperarContraPage } from './login-recuperar-contra.page';
import {SharedModule} from '../shared/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    LoginRecuperarContraPageRoutingModule,
    SharedModule,
    NgbModalModule
  ],
  declarations: [LoginRecuperarContraPage]
})
export class LoginRecuperarContraPageModule {}
