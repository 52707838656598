import { Component, OnInit } from '@angular/core';
import {Categoria} from '../models/categoria.model';
import {CategoriaApiService} from '../providers/categoria-api.service';
import {ConnexionApiService} from '../providers/connexion-api.service';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventoSeccionService } from '../providers/evento-seccion.service';

@Component({
  selector: 'app-categoria-lista',
  templateUrl: './categoria-lista.page.html',
  styleUrls: ['./categoria-lista.page.scss'],
})
export class CategoriaListaPage implements OnInit {
//Filtros
ordenamiento = [
  { nombre: 'Alfabetico A-Z', valor: { orderBy: 'alfabeto', orderType: 'asc' } },
  { nombre: 'Alfabetico Z-A', valor: { orderBy: 'alfabeto', orderType: 'desc' } }
];
mostrarOrdenar = false;
webVersion = false;
enVista = false;
orderKey = null;

  categorias: Categoria[] = [];
  categoriasAux: Categoria[] = [];

  constructor(private platform: PlatformService, private location: Location, private router: Router,
              private categoriaService: CategoriaApiService, private sanitizer: DomSanitizer,
              private seccionService: EventoSeccionService,) { }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[0];
  }

  async back () {

    await this.location.back();
  }

  ionViewWillEnter() {

    this.categoriaService.obtenerTodas().subscribe((data: Categoria[]) => {

      this.categorias = data;
      this.categoriasAux = data;
    });

  }

  imageUrl(relativePath: string): string {

    return relativePath ? `${ConnexionApiService.host.replace('api','app.')}/uploads/categoria/${relativePath}` : '/assets/images/cine.png';
  }

  getGradient(color) {

    const style = this.sanitizer.bypassSecurityTrustStyle(`background-image: radial-gradient(transparent, transparent 40%, rgb(243, 158, 241) 66%, rgb(243, 158, 241));`);
    return style;
  }

  // Seccion filtros
  ordenarEventos() {
    if (!this.webVersion) {
      this.mostrarOrdenar = false;
    }
      this.actualizarBusqueda();
  }
  async actualizarBusqueda() {
    if(this.categorias.length == 0) {
      this.categorias = this.categoriasAux;
    }
    let categoriasOrdenados:any = this.seccionService.ordenarPor(this.categorias, this.orderKey.valor.orderBy, this.orderKey.valor.orderType);
    // console.log(categoriasOrdenados);
    this.categorias = [];
    setTimeout(() => {
      this.categorias = categoriasOrdenados;
    }, 100);
   
  }

  async verCategoria(c) {
    await localStorage.setItem('catActiva', JSON.stringify(c));
    this.router.navigateByUrl('/categoria/'+c.id);
  }
}
