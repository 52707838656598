import {Component, NgZone, OnInit} from '@angular/core';
import {AuthFirebaseService} from '../providers/auth-firebase.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PlatformService} from '../providers/platform.service';
import {StorageService} from '../providers/storage.service';
import { HttpRequestApiService } from '../providers/http-request-api.service';
import { UsuarioApiService } from '../providers/usuario-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cuenta-telefono',
  templateUrl: './cuenta-telefono.page.html',
  styleUrls: ['./cuenta-telefono.page.scss'],
})
export class CuentaTelefonoPage implements OnInit {
  validacionTwilio = environment.validacionTwilio;
  numero: string;

  submitting = false;

  error = false;

  resend = false;

  webVersion = false;

  codigoArea = '52';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private platform: PlatformService,
              private authFirebase: AuthFirebaseService, private ngZone: NgZone, private storage: StorageService, 
              private usuarioService: UsuarioApiService, private http: HttpRequestApiService) { }

  ngOnInit() {

      this.webVersion = this.platform.isDesktop();
  }

  get validNumber() {

    if (!this.numero) {

      return false;
    }

    return this.numero.length >= 9;
  }

  async ionViewWillEnter() {
    
    this.activatedRoute.paramMap.subscribe((pm: ParamMap) => {

      this.resend = pm.get('resend') === '1';

      if (this.storage._usuario) {

        this.numero = this.storage._usuario.telefono;
      }

    });
    if(!this.validacionTwilio){
      this.numero = JSON.parse(await localStorage.getItem('updateCorreoUsuario')).correo || '';
      // console.log(this.numero);
    }
  }

  async onSubmit() {

    this.submitting = true;
    let idUser = await this.storage.getUsuarioId();
    if(this.validacionTwilio) {
      await this.usuarioService.actualizarPerfil(idUser, {
        telefono: this.numero,
      }).toPromise();
      this.http.put('api/resend/code/'+idUser, { telefono: this.numero }).subscribe( (sendCode:any) => {
        if(sendCode.success){
          this.ngZone.run(() => {
            this.submitting = false;
            this.router.navigate([ '/verificar-cuenta/'+btoa(this.numero) ]);
          });
        } else {
          this.ngZone.run(() => {
            this.submitting = false;
            this.error = true;
          });
        }
      }, error => {
        this.ngZone.run(() => {
          console.log(error);
          this.submitting = false;
          this.error = true;
        });
      });
    } else {
      await this.usuarioService.actualizarPerfil(idUser, {
        correo: this.numero,
      }).toPromise();
      this.http.put('api/resend/email/code/'+idUser, { correo: this.numero }).subscribe( (sendCode:any) => {
        if(sendCode.success){
          this.ngZone.run(() => {
            this.submitting = false;
            this.router.navigate([ '/verificar-cuenta/'+btoa(this.numero) ]);
          });
        } else {
          this.ngZone.run(() => {
            this.submitting = false;
            this.error = true;
          });
        }
      }, error => {
        this.ngZone.run(() => {
          console.log(error);
          this.submitting = false;
          this.error = true;
        });
      });
    }   
  }

  cambioNumero(event) {

    event.preventDefault();
    if ( [ "0","1","2","3","4","5","6","7","8","9", "Delete", "Backspace", "+"].indexOf(event.key.toString()) < 0) {

      return;
    }

    let numero: string;
    if (["Delete", "Backspace"].indexOf(event.key) >= 0) {

      if (this.numero.length === 0) {

        this.numero = '';
        numero = this.numero;
      } else {

        numero = this.numero.slice(0, this.numero.length - 1);
      }

    } else {

      numero = (this.numero ? this.numero : '') + event.key;
    }

    numero = numero.replace(/[ ,\+]/g, '');

    this.numero = numero;
  }

  onNumberFocus() {

    if (!this.numero) {

      this.numero = this.codigoArea;
    }
  }

}
