import { Component, OnInit } from '@angular/core';
import {Recinto} from '../models/recinto.model';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {RecintoApiService} from '../providers/recinto-api.service';
import { StorageService } from '../providers/storage.service';
import { EventoApiService } from '../providers/evento-api.service';
import { EventoSeccionService } from '../providers/evento-seccion.service';

@Component({
  selector: 'app-recinto-lista',
  templateUrl: './recinto-lista.page.html',
  styleUrls: ['./recinto-lista.page.scss'],
})
export class RecintoListaPage implements OnInit {
//Filtros
ordenamiento = [
  { nombre: 'Alfabetico A-Z', valor: { orderBy: 'alfabeto', orderType: 'asc' } },
  { nombre: 'Alfabetico Z-A', valor: { orderBy: 'alfabeto', orderType: 'desc' } }
];
mostrarOrdenar = false;
webVersion = false;
enVista = false;
orderKey = null;

  agrupacion: Recinto[]= [];

  promocionado: Recinto[] = [];
  promocionadoAux: Recinto[] = [];

  noClasificados: Recinto[] = [];

  clasificacion = false;

  totalClasificacion = 0;

  loaded = false;

  constructor(private platform: PlatformService, private location: Location, private storage: StorageService,
    private recintoService: RecintoApiService, private eventoService: EventoApiService,
    private seccionService: EventoSeccionService,) { }

  async back () {

    await this.location.back();
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[0];
  }

  async ionViewWillEnter() {
    let idUser = await this.storage.getUsuarioId();
    const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.recintoService.todos(favoritos).subscribe((recintos: Recinto[]) => {
      this.loaded = true;
      this.agrupar(recintos);
    });
  }

  agrupar(recintos: Recinto[]) {

    const clasificacion: string[] = [];
    const agrupacion: Recinto[]   = [];
    const patrocinados: Recinto[] = [];
    const noClasificados: Recinto[] = [];

    recintos.forEach((r: Recinto) => {

      if (r.promocion) {

        patrocinados.push(r);
        return;
      }

      if (!r.clasificacion) {

        noClasificados.push(r);
        return;
      }

      agrupacion.push(r);

      if (clasificacion.indexOf(r.clasificacion) < 0) {

        clasificacion.push(r.clasificacion);
      }
    });

    this.promocionado = patrocinados;
    this.promocionadoAux = patrocinados;
    this.totalClasificacion = clasificacion.length;
    this.noClasificados = noClasificados;
    this.agrupacion = agrupacion.sort((a: Recinto, b: Recinto) => {

      if (a.clasificacion === b.clasificacion) {

        return 0;
      }

      return clasificacion.indexOf(a.clasificacion) > clasificacion.indexOf(b.clasificacion) ? 1: -1;
    });
  }

  mostrarClasificacion(recinto, iniciar = false) {

    if (iniciar) {
      this.clasificacion = recinto.clasificacion;
      return true;
    }

    const test = this.clasificacion !== recinto.clasificacion;

    if (test) {
      this.clasificacion = recinto.clasificacion;
    }

    return test;
  }

  // Seccion filtros
  ordenarEventos() {
    if (!this.webVersion) {
      this.mostrarOrdenar = false;
    }
      this.actualizarBusqueda();
  }
  async actualizarBusqueda() {
    if(this.promocionado.length == 0) {
      this.promocionado = this.promocionadoAux;
    }
    let promocionadoOrdenados:any = this.seccionService.ordenarPor(this.promocionado, this.orderKey.valor.orderBy, this.orderKey.valor.orderType);
    // console.log(promocionadoOrdenados);
    this.promocionado = [];
    setTimeout(() => {
      this.promocionado = promocionadoOrdenados;
    }, 100);
   
  }

}
