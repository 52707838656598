import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {auth, UserInfo} from 'firebase/app';
import {UsuarioApiService} from './usuario-api.service';
import {AlertController} from '@ionic/angular';
import {Usuario} from '../models/usuario.model';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {ConfigApiEnum} from '../models/config.api.enum';
import {PlatformService} from './platform.service';
import {StorageService} from './storage.service';
import {Facebook} from '@ionic-native/facebook/ngx';
import { ASAuthorizationAppleIDRequest, SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import {ConnexionApiService} from './connexion-api.service';
import {Subject} from 'rxjs';
import * as firebase from 'firebase'
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';

@Injectable({
    providedIn: 'root'
})
export class AuthFirebaseService {

    // Mantiene traza del request SMS
    private confirmationResult;

    private recaptchaVerifier: auth.RecaptchaVerifier;

    private capthaRender = false;

    private _userSubject = new Subject<Usuario>();

    constructor(private firestore: AngularFirestore, private fireAuth: AngularFireAuth, private storage: StorageService,
                private userService: UsuarioApiService, private platform: PlatformService, private googlePlus: GooglePlus,
                private facebook: Facebook, private conexion: ConnexionApiService, private router: Router,
                private SignInWithApple: SignInWithApple, private firebaseAuthentication: FirebaseAuthentication,
                private usuarioService: UsuarioApiService, public alertController: AlertController) { }

    getFirebaseUser(force = false) {

        if (!this.fireAuth.auth.currentUser) {

            return new Usuario();
        }
        const dataObject = {
            nombre: this.fireAuth.auth.currentUser.displayName,
            correo: this.fireAuth.auth.currentUser.email,
            telefono: this.fireAuth.auth.currentUser.phoneNumber,
            foto: this.fireAuth.auth.currentUser.photoURL,
            providerId: this.fireAuth.auth.currentUser.providerId,
            providerUId: this.fireAuth.auth.currentUser.uid,
            estaVerificada: force ? this.fireAuth.auth.currentUser.emailVerified : false
        };

        return new Usuario(dataObject);
    }

    async getGoogleProviderOrCredential(): Promise<any> {

        if (this.platform.is('desktop')) {

            const provider = new auth.GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account',
                display: 'popup'
            });

            return provider;
        } else {

            const tokens: any = await this.googlePlus.login({
                'webClientId': ConfigApiEnum.WEB_CID,
                'offline': false
            });

            return auth.GoogleAuthProvider.credential(tokens.idToken, tokens.accessToken);
        }
    }

    async getFacebookProviderOrCredential(): Promise<any> {

        if (this.platform.is('desktop')) {

            const provider = new auth.FacebookAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account',
                display: 'popup'
            });

            return provider;
        } else {

            const tokens: any = await this.facebook.login(['email']);

            return auth.FacebookAuthProvider.credential(tokens.authResponse.accessToken);
        }
    }

    async googleAuth(retry = false) {

        if (retry && this.fireAuth.auth.currentUser) {

            return this.checkUserForLogin(this.fireAuth.auth.currentUser.email);
        }

        return this.requestGoogleFacebookLogin(true);
    }

    async facebookAuth(retry = false) {

        if (retry && this.fireAuth.auth.currentUser) {

            return this.checkUserForLogin(this.fireAuth.auth.currentUser.email);
        }

        return this.requestGoogleFacebookLogin(true, 'facebook');
    }

    googleSignUp(retry = false) {

        if (retry && this.fireAuth.auth.currentUser) {

            return this.createUser(true);
        }

        return this.authSignUp();
    }

    facebookSignUp(retry = false) {

        if (retry && this.fireAuth.auth.currentUser) {

            return this.createUser(true);
        }

        return this.authSignUp('facebook');
    }

    // requestSMSCode(telefono: string, resend = false) {
    //     if (!resend || !this.capthaRender) {
    //         this.capthaRender = true;
    //         this.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
    //             'size': 'invisible'
    //         });
    //     }
    //     // return this.fireAuth.auth.signInWithPhoneNumber(telefono, this.recaptchaVerifier).then((data) => {
    //     //     console.log(data);
    //     //     this.confirmationResult = data;
    //     // },error => {
    //     //     console.log(error);
    //     // }).catch( e => {
    //     //     console.log(e);
    //     // });
    //     return this.firebaseAuthentication.verifyPhoneNumber(telefono,30)
    //     .then((res: any) => {
    //         this.confirmationResult = res;
    //     })
    //     .catch((error: any) => console.error(error));
    // }

    // async verifySMSCode(code: string) {
    //     if (!this.confirmationResult && this.confirmationResult!= undefined) {
    //         return false;
    //     }
    //     if (this.fireAuth.auth.currentUser) {
    //         const credential = await auth.PhoneAuthProvider.credential(this.confirmationResult, code);
    //         await this.fireAuth.auth.currentUser.updatePhoneNumber(credential);
    //         return true;
    //     }
    //     return await this.confirmationResult.confirm(code).catch((err) => {
    //         console.log(err);
    //     });
    // }

    async authSignUp(provider = 'google') {

        const user: any = await this.requestGoogleFacebookLogin(false, provider);

        if (user === false) {

            return this.createUser(true);
        }

        return false;
    }

    async requestGoogleFacebookLogin(forLogin = true, provider = 'google') {

        let argument;

        if (provider === 'google') {

            argument = await this.getGoogleProviderOrCredential();
        } else if (provider === 'facebook') {

            argument = await this.getFacebookProviderOrCredential();
        }

        if (!argument)
            throw new Error("Provider not found!");

        let err : any = false;
        if (this.platform.is('desktop')) {

            await this.fireAuth.auth.signInWithPopup(argument).catch( response => err = response);
        } else {

            await this.fireAuth.auth.signInWithCredential(argument).catch( response => err = response);
        }

        if (err !== false) {

            if (err.code === 'auth/account-exists-with-different-credential') {

                this.showErrorToast('Disculpe, Usted se encuentra registrado por otro método.');
            } else {

                //this.showErrorToast('Error desconocido. Contacte al soporte técnico.');
            }

            throw new Error("Error found!");
        }

        this._userSubject.next(this.getFirebaseUser());

        if (forLogin) {

            return this.checkUserForLogin(this.fireAuth.auth.currentUser.email);
        }

        return this.checkUserForRegister(this.fireAuth.auth.currentUser.email);
    }

    async createUser(force = false) {
        let datos:any = await localStorage.getItem('userUdeGDatos');
        if(datos == null){
            datos = {codigo: null, nip: null};
        } else {
            datos = JSON.parse(datos);
        }
        let dataUsuario = {
            nombre: this.fireAuth.auth.currentUser.displayName == null ? this.fireAuth.auth.currentUser.email : this.fireAuth.auth.currentUser.displayName,
            email: this.fireAuth.auth.currentUser.email,
            correo: this.fireAuth.auth.currentUser.email,
            username: this.fireAuth.auth.currentUser.email,
            password: this.fireAuth.auth.currentUser.uid,
            provider_id: 'social',
            provider_uid: this.fireAuth.auth.currentUser.uid,
            fotoUrl: this.fireAuth.auth.currentUser.photoURL,
            codigo: datos.codigo,
            nip: datos.nip,
            cuenta_verificada: 0
        };
// console.log('dataUsuario fireAuth');
// console.log(dataUsuario);

        if(force) {
            dataUsuario.cuenta_verificada = 1;
        }
        let usuario = await this.usuarioService.createUser(dataUsuario).toPromise();

        return this.loginOnApi();
    }

    async createUserFromCredentials(correo, password) {

        await this.fireAuth.auth.createUserWithEmailAndPassword(correo, password);

        return this.getFirebaseUser();
    }

    async checkUserForLogin(email) {

        if (!email)
            return false;

        let data : any = await this.usuarioService.checkEmail(email).toPromise();

        if (!data.user) {

            await this.createSimpleAlert('Debe registrar primero su usuario para distrutar de la aplicación.');
            return false;
        }

        return this.loginOnApi();
    }

    async loginOnApi() {

        // Autentica el usuario en la api obteniendo el token.
        const data : any = await this.usuarioService.checkLogin({
            username: this.fireAuth.auth.currentUser.email,
            password: this.fireAuth.auth.currentUser.uid
        }).toPromise();

        this.storage.setToken(data.token);
        this.storage.setRefreshToken(data.token);
        await this.storage.setUsuarioId(data.user.id);
        await this.storage.setSegmento(data.user.segmento);
        const usuario = await this.usuarioService.perfil(data.user.id).toPromise();

        this.storage.setUsuario(usuario);
        this.storage._isAuth = true;

        return usuario;
    }

    async checkUserForRegister(email) {

        if (!email)
            return false;

        const data : any = await this.usuarioService.checkEmail(email).toPromise();

        if (data.user) {

            await this.createSimpleAlert('Ya ésta cuenta está registrada. Ya puede entrar a la aplicación.');
            return false;
        }

        return false;
    }

    async createSimpleAlert(message: string) {

        const alert = await this.alertController.create({
            header: 'Notifcación',
            message: message,
            buttons: ['Ok']
        });

        return alert.present();
    }

    async showErrorToast(message) {

        await this.conexion.errorEncontrado(message);
    }

    userSubject() {

        return this._userSubject.asObservable();
    }

    async logout() {

        if (!this.platform.isWebBuild()) {

            await  this.googlePlus.logout();
            await this.facebook.logout();
        }

        const user = this.fireAuth.auth.currentUser;

        if (user) {
            const providerData = this.fireAuth.auth.currentUser.providerData;
            providerData.forEach((info: UserInfo) => {
                this.fireAuth.auth.currentUser.unlink(info.providerId);
            });
        }

        await this.fireAuth.auth.signOut();
    }

    openAppleSignIn(tipo = 'login') {
        this.SignInWithApple.signin({
            requestedScopes: [
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
              ]
        }).then(async (res:any) => {
            console.log(res);
          if (res && res.identityToken) {
            this.createFirebaseuser(res, tipo);
          } else {
            this.presentAlert();
          }
        }).catch(response => {
          this.presentAlert();
        });
    }

    createFirebaseuser(appleResponse, tipo) {
        // Create a custom OAuth provider    
        const provider = new firebase.auth.OAuthProvider('apple.com');
        // Create sign in credentials with our token
        const credential = provider.credential({
          idToken: appleResponse.identityToken
        });
        // Call the sign in with our created credentials
        this.fireAuth.auth.signInWithCredential(credential).then( async resultado => {
            await this.createUser(true);
            const user: any =  await this.getFirebaseUser();
            this.router.navigate(['/home']);
            // // if(tipo == 'registro'){
            // //     this.router.navigate([ user.estaVerificada ? '/intereses': '/cuenta-telefono' ]);
            // // }
            // // else {
            // //     await this.usuarioService.checkVerificacion(user);
            // // }
        }).catch( error => {
            console.log(error);
        });
    }

    async presentAlert() {
        const alert = await this.alertController.create({
          header: 'Login Failed',
          message: 'Please try again later',
          buttons: ['OK']
        });
        await alert.present();
    }

}
