import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {IonicModule} from '@ionic/angular';
import {FooterComponent} from './footer/footer.component';
import {EventoThumbnailComponent} from './evento-thumbnail/evento-thumbnail.component';
import {EventoListaComponent} from './evento-lista/evento-lista.component';
import {EventoSeccionComponent} from './evento-seccion/evento-seccion.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {DaterangePickerComponent} from './daterange-picker/daterange-picker.component';
import {NoArrowDirective} from './directives/no-arrow.directive';
import {HeadFilterComponent} from './head-filter/head-filter.component';
import {BannerComponent} from './banner/banner.component';
import {MultimediaComponent} from './multimedia/multimedia.component';
import {UdegInicioComponent} from './udeg-inicio/udeg-inicio.component';
import {UdegAlertComponent} from './udeg-alert/udeg-alert.component';
import {UdegFormularioComponent} from './udeg-formulario/udeg-formulario.component';
import {ModalBaseComponent} from './modal-base/modal-base.component';
import {SelectComponent} from './select/select.component';

@NgModule({
    declarations: [
        HeaderComponent, FooterComponent, EventoThumbnailComponent, EventoListaComponent,
        EventoSeccionComponent, DaterangePickerComponent, NoArrowDirective, HeadFilterComponent,
        BannerComponent, MultimediaComponent, UdegInicioComponent, UdegAlertComponent, UdegFormularioComponent,
        ModalBaseComponent, SelectComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgbDropdownModule
    ],
    entryComponents: [
        DaterangePickerComponent, MultimediaComponent, UdegInicioComponent, UdegAlertComponent, UdegFormularioComponent,
        SelectComponent
    ],
    exports: [
        HeaderComponent, FooterComponent, EventoThumbnailComponent, EventoListaComponent, EventoSeccionComponent,
        DaterangePickerComponent, NoArrowDirective, HeadFilterComponent, BannerComponent, MultimediaComponent,
        UdegInicioComponent, UdegAlertComponent, UdegFormularioComponent, ModalBaseComponent, SelectComponent
    ]
})
export class SharedModule { }
