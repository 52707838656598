import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequestApiService} from './http-request-api.service';
import {map, tap} from 'rxjs/operators';
import {Recinto} from '../models/recinto.model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RecintoApiService {

    constructor(private http: HttpRequestApiService, private storage: StorageService) { }

    todos(intereses): Observable<Recinto[]> {

      return this.http.get('api/v1/recinto').pipe((map( (obj: any[]) => {

          return obj.map( (r: any) => new Recinto(r, intereses));
      })));
    }

    obtenerRecintoInfo(id, intereses): Observable<Recinto> {

        return this.http.get('api/v1/recinto/' + id ).pipe((map( (obj: any) => {

            // return new Recinto(obj, intereses);
            return obj;
        })));
    }

}

