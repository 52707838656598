import {Component, OnInit, ViewChild} from '@angular/core';
import {Evento} from '../models/evento.model';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {GeolocationService} from '../providers/geolocation.service';
import {EventoApiService} from '../providers/evento-api.service';
import {Recinto} from '../models/recinto.model';
import {StorageService} from '../providers/storage.service';
import {EventoSeccionService} from '../providers/evento-seccion.service';
import {LoadingService} from '../providers/loading-service';
import { UsuarioApiService } from '../providers/usuario-api.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UdegUserService } from '../providers/udeg.user.service';
import { IonContent, MenuController } from '@ionic/angular';
import { HeadFilterComponent } from '../shared/head-filter/head-filter.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('filter_component', { static: false }) filter_component: HeadFilterComponent;
  
  secciones: any;
  seccionesResp: any;

  eventos: Evento[] = [];
  eventosOriginales: Evento[] = [];

  recintos: Recinto[] = [];
  private eventoSubscripcion: Subscription;
  constructor(public platform: PlatformService, private eventoService: EventoApiService,
              private loadingService: LoadingService, private location: Location, private storage: StorageService,
              private seccionService: EventoSeccionService, private  geolocation: GeolocationService,
              private DGUser: UdegUserService, private menu: MenuController,
              private usuarioService: UsuarioApiService, private router: Router) { }

  async back () {
    await this.location.back();
  }
  
  ionViewDidEnter(){
    this.scrollToTop();
  }

  scrollToTop() {
    if (this.content) {
      this.content.scrollToTop(400).then(() => {
        console.log('scrollToTop');
      }).catch(err => {
        console.error('Error scrolling to top:', err);
      });
    } else {
      console.error('IonContent not found');
    }
  }
  async ionViewWillEnter() {
    this.menu.enable(true);
    this.scrollToTop();
    let usuario = await this.storage.getUsuario();
    localStorage.setItem('Segmento', usuario.segmento);
    const token = await localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login'],{ replaceUrl: true });
    }
    const data = await localStorage.getItem('updateTelefonoUsuario');
    if (data) {
      let idUser = await this.storage.getUsuarioId();
      const usuario = await this.usuarioService.actualizarPerfil(idUser, JSON.parse(data)).toPromise();
      this.storage.setUsuario(usuario);
    }
    await this.getGeneros();
    await this.updateInfo();
    this.eventoSubscripcion = this.eventoService.eventoObservable$.subscribe( async (evento) => {
      await this.updateInfo();
    });
  }

  ionViewWillLeave() {
    try {
      this.eventoSubscripcion.unsubscribe();
    } catch (error) { }
  }

  async updateInfo() {

    let geoData: any = null;
    try {
      geoData = await this.geolocation.getCurrentPosition().catch(async () => {
        // console.log('Geolocalización no habilitada o error en GPS.');
        localStorage.setItem('GPSNoActivo','No');
        localStorage.removeItem('PositionGPS');
      });
      if(geoData == undefined) {
        geoData = null;
      }
    } catch (error) {
      geoData = null;
    }
    let idUser = await this.storage.getUsuarioId();
    let likes:any = await this.eventoService.obtenerEventoFavoritos(idUser,0,0).toPromise();
    if(geoData != null) {
      // console.log(geoData);
      localStorage.setItem('PositionGPS', JSON.stringify({latitude: geoData.coords.latitude, longitude: geoData.coords.longitude}));
      localStorage.removeItem('GPSNoActivo');
    }
    const data: any =  await this.eventoService.obtenerEventosParaTi( geoData != null ? geoData.coords : {latitude:null, longitude: null}, idUser, likes,0,0).toPromise();
    this.recintos = data.recintos;
    let eventos = data.eventos;
    // let likes:any  = await this.eventoService.obtenerEventoFavoritos(idUser,0,0).toPromise();
    if(likes) {
      for (let index = 0; index < eventos.length; index++) {
        let dt = likes.intereses.filter( fl => fl.evento_id == eventos[index].id);
        eventos[index].liked = (dt.length > 0) ? true : false;
      }
      eventos = eventos.sort((a: Evento, b: Evento) =>  {
  
        const fa = a.fechaProxima();
        const fb = b.fechaProxima();
  
        return fa < fb ? - 1 : 1;
      });
      eventos = this.ordenarEventosPorFechaInicio(eventos);
      this.eventos = eventos;
      this.eventosOriginales = eventos;
      // console.log(eventos);
      
      let recintos_cercanos = [];
      data.recintos.forEach(element => {
        try {
          if( parseFloat(this.getKilometros(geoData.coords.latitude, geoData.coords.longitude, parseFloat(element.lat), parseFloat(element.lng))) <= 15){
            recintos_cercanos.push(element.id);
          }
        } catch (error) {
          recintos_cercanos.push(element.id)
        }
      });
      if(recintos_cercanos.length > 0){
        this.storage._recintos_cerca = recintos_cercanos;
      } else {
        this.storage._recintos_cerca = [];
      }
      this.storage._eventos = data;
      this.storage._recintos = data.recintos;
      this.storage._categorias = data.categorias;
      this.secciones = this.seccionService.seccionarEventos(data);
      this.seccionesResp = this.secciones;
      // console.log('datos de selccion evento');
      // console.log(this.secciones);
    
      await this.loadingService.hide();
    } 
   
  }

  async ngOnInit() {
    let usuario = await this.storage.getUsuario();
    // let conPermisos = await this.usuarioService.validatePermission(usuario);
    // if(conPermisos) {
    //   this.tags();
    // }
  }

  ordenarEventosPorFechaInicio(eventos) {
    eventos.sort(function(a, b) {
      var fechaInicioA:any = new Date(a.fechaInicio);
      var fechaInicioB:any = new Date(b.fechaInicio);
      return fechaInicioA - fechaInicioB;
    });
    return eventos;
  }

  async onFilter(data) {
    let idUser = await this.storage.getUsuarioId();
    const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarTodoEventos(data, favoritos).subscribe((eventos: Evento[]) => {

      this.eventos = eventos;
    });
  }

  getKilometros(lat1,lon1,lat2,lon2) {
    let rad = function(x) {return x*Math.PI/180;}
    var R = 6378.137; //Radio de la tierra en km
    var dLat = rad( lat2 - lat1 );
    var dLong = rad( lon2 - lon1 );
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d.toFixed(3); //Retorna tres decimales
  }

  async getGeneros(){
    let datos:any = await this.eventoService.obtenerGeneros(0,0).toPromise();
    // console.log("datos",datos);
    if(datos.message) {
      await localStorage.setItem('categoriasEventos', JSON.stringify(datos));
    }
  }

  async reloadExit(event) {
    // console.log(event);
    await this.updateInfo();
  }

  async tags() {
    let usuario = await this.storage.getUsuario();
    let generoSuscripcion = await this.usuarioService.generarSuscripcionOneSignal(usuario);
    // if(generoSuscripcion) {
    //   if(usuario.codigo_ude_g){
    //     this.DGUser.estatusUdeG(usuario.codigo_ude_g);
    //   } else {
    //     this.DGUser.estatusUdeG();
    //   }
    // }
  }

  limpiarFiltro() {
    this.secciones = this.seccionesResp;
    this.eventos =  this.eventosOriginales;
    this.botonBuscar = true;
    this.filter_component.textSearch = '';
    this.filter_component.filtro = { recinto: [], fecha: null, precio: {min:null, max: null}, categoria: [], genero: [] };
  }

  botonBuscar = true;

  async onBuscarEventos(data) {
    this.botonBuscar = false;
    // console.log(data); 
    let secciones = this.seccionesResp;
    secciones = this.filtrarEventos(data, secciones);
    this.secciones = secciones;
    let eventos = this.filtrarEventos(data, this.eventosOriginales, true);
    console.log(eventos);
    this.eventos = eventos;
  }

  filtrarEventos(filtro: any, eventos: any, isEvents = false) {
    const cumpleTexto = (evento: any, texto: string) => {
      return texto === "" || evento.nombre.toLowerCase().includes(texto.toLowerCase());
    };
    
    const cumpleCategoria = (evento: any, categorias: number[]) => {
      return categorias.length === 0 || categorias.includes(evento.categoria);
    };
    
    const cumpleGenero = (evento: any, generos: number[]) => {
      return generos.length === 0 || generos.includes(evento.genero_id);
    };
    
    const cumpleRecinto = (evento: any, recintos: number[]) => {
      return recintos.length === 0 || recintos.includes(evento.recinto);
    };
    
    const cumpleFecha = (evento: any, fecha: any) => {
      if (!fecha.from && !fecha.to) {
        return true; // No hay restricciones de fecha
      }
      const fechaInicio = new Date(evento.fechaInicio);
      const fechaFin = new Date(evento.fechaFin);
      const from = fecha.from ? new Date(fecha.from) : null;
      const to = fecha.to ? new Date(fecha.to) : null;
    
      return (!from || fechaInicio >= from || fechaFin >= from) && 
             (!to || fechaInicio <= to || fechaFin <= to);
    };
    
    const cumplePrecio = (evento: any, precio: any) => {
      if (!precio.min && !precio.max) {
        return true; // No hay restricciones de precio
      }
      const precioBajo = parseFloat(evento.precio_bajo);
      const precioAlto = parseFloat(evento.precio_alto);
      const min = precio.min !== null ? parseFloat(precio.min) : null;
      const max = precio.max !== null ? parseFloat(precio.max) : null;
    
      if(min === null && max === null) {
        return true;
      } else if(min === null) {
        return precioBajo <= max;
      } else if(max === null) {
        return precioAlto >= min;
      } else {
        return precioAlto >= min && precioBajo <= max;
      }
    };    

    if(isEvents) {
      console.log('solo Enventos');
      return eventos.filter(evento => {
        return cumpleTexto(evento, filtro.texto) &&
               cumpleCategoria(evento, filtro.categoria) &&
               cumpleGenero(evento, filtro.genero) &&
               cumpleRecinto(evento, filtro.recinto) &&
               cumpleFecha(evento, filtro.fecha) &&
               cumplePrecio(evento, filtro.precio);
      });
    }
    else {
      const filtrarPorNodo = (eventos: any[], filtro: any) => {
        return eventos.filter(evento => {
          return cumpleTexto(evento, filtro.texto) &&
                cumpleCategoria(evento, filtro.categoria) &&
                cumpleGenero(evento, filtro.genero) &&
                cumpleRecinto(evento, filtro.recinto) &&
                cumpleFecha(evento, filtro.fecha) &&
                cumplePrecio(evento, filtro.precio);
        });
      };
      console.log('solo nodos');
      return {
        cerca: filtrarPorNodo(eventos.cerca, filtro),
        gratuitos: filtrarPorNodo(eventos.gratuitos, filtro),
        misEventos: filtrarPorNodo(eventos.misEventos, filtro)
      };
    }
  }
}
