import {Injectable} from '@angular/core';
import {Usuario} from '../models/usuario.model';
import SimpleCrypto from 'simple-crypto-js';
// import {Storage} from '@ionic/storage';
import {Evento} from '../models/evento.model';
import {Categoria} from '../models/categoria.model';
import {Recinto} from '../models/recinto.model';
import {Events, NavController} from '@ionic/angular';
import {Banner} from '../models/banner.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

    _s = 'edu.udg.app.butaca';

    _usuario: Usuario;

    _cr: SimpleCrypto;

    _token;

    _idUser;

    _segmento;

    _refreshToken;

    // Almacena el estado de la sesión
    _isAuth = false;

    _webSplashLoading = true;

    _banners: Banner[] = [];

    _eventos: Evento[] = []; // mantiene traza de los eventos se actualiza en home

    _categorias: Categoria[] = []; // traza de las categorías

    _recintos: Recinto[] = []; // traza de los recintos.

    _recintos_cerca = []; // traza de los recintos cercanos

    _metadata: any; // usar para comunicacion entre vistas (pasar objetos)

    _dataRegisterUser: any; // usar para comunicacion entre vistas (pasar objetos)

    constructor(private events: Events, private navCtrl: NavController, private router: Router) {

        this._cr = new SimpleCrypto(this._s);
    }

    async setUsuarioId(id){
        this._idUser = id.toString();
        await localStorage.setItem('usuarioId', id.toString());
    }

    async getUsuarioId(){
        const idUser = localStorage.getItem('usuarioId');
        this._idUser = idUser;
        return this._idUser;
    }

    async setSegmento(segmento){
        this._segmento = segmento;
        await localStorage.setItem('segmento', segmento);
    }

    async getSegmento(){
        const segmento = localStorage.getItem('segmento');
        this._segmento = segmento;
        return this._segmento;
    }

    async setRefreshToken(rtoken: string) {

        this._refreshToken = rtoken;
        await localStorage.setItem('rtoken', rtoken ?
            this._cr.encrypt(rtoken) : null);
    }

    async getRefreshToken() {

        const rtoken = await localStorage.getItem('rtoken');
        this._refreshToken = rtoken ? 
            this._cr.decrypt(rtoken) : null;
        return this._refreshToken;
    }

    async setUsuario(usuario: Usuario) {

        this._usuario = usuario;
        this.events.publish('perfil:updated', usuario);
        await localStorage.setItem('usuario', usuario ?
            this._cr.encrypt(JSON.stringify(usuario)) : null);
    }

    async getUsuario() {

        let sUsuario:any = await localStorage.getItem('usuario');
        sUsuario = sUsuario ? this._cr.decrypt(sUsuario) : null;
        this._usuario = sUsuario ? new Usuario(sUsuario) : null;
        return this._usuario;
    }

    async setToken(token: string) {

        this._token = token;
        await localStorage.setItem('token', token ?
            this._cr.encrypt(token) : null);
    }

    async getToken() {

        const sToken = await localStorage.getItem('token');

        this._token = sToken ? 
            this._cr.decrypt(sToken) : null;
        return this._token;
    }

    async setNotificacion(notificacion: boolean) {

        await localStorage.setItem('notificacion', notificacion ? 'true' : 'false');
    }

    async getNotificacion() {

        const notificacion = await localStorage.getItem('notificacion');
        return notificacion ? (notificacion == 'true' ? true: false) : false;
    }

    async signoff() {

        this._isAuth = false;
        this.setUsuario(null);
        this.setToken(null);
        this.setRefreshToken(null);
    }

    async logout() {

        this._isAuth = false;
        this.setToken(null);
        this.setRefreshToken(null);
    }

    newNotification(notify: boolean) {

        this.events.publish('notify:updated', notify ? 'true' : 'false');
    }

    async setMetadata(metadaData) {

        this._metadata = metadaData;
        await localStorage.setItem('metadata', JSON.stringify(metadaData));
    }

    async getMetadata() {

        this._metadata = await localStorage.getItem('metadata');
        return JSON.parse(this._metadata);
    }

    async setNormalLogin(params) {
        await localStorage.setItem('normalLogin', params);
    }

    async getNormalLogin() {
        const data = await localStorage.getItem('normalLogin') || null;
        return data;
    }

    async removeNormalLogin(){
        await localStorage.remove('normalLogin');
    }

    async setDataRegisterUser(dataRegisterUser){
        // this._dataRegisterUser = dataRegisterUser;
        await localStorage.setItem('dataRegisterUser', JSON.stringify(dataRegisterUser));
    }

    async getDataRegisterUser() {

        this._dataRegisterUser = await localStorage.getItem('dataRegisterUser');
        return JSON.parse(this._dataRegisterUser);
    }

    async removeDataRegisterUser(){
        await localStorage.remove('dataRegisterUser');
    }

    async removeAllData(){
        await localStorage.clear();
    }

    irLogin() {
        this.navCtrl.navigateRoot('/login');
        this.router.navigate(['/login'],{ replaceUrl: true });
    }
}
