import {Injectable} from '@angular/core';
import {LoadingService} from './loading-service';
import {StorageService} from './storage.service';
import {ConnexionApiService} from './connexion-api.service';
import {HTTP} from '@ionic-native/http/ngx';
import {from} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PlatformService} from './platform.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestApiService {

  constructor(private loadingService: LoadingService, private storage: StorageService,
              private connexion: ConnexionApiService, private nativeHttp: HTTP, private http: HttpClient) { }

  get(path, params = {}, query = '') {

    return this.request(path, 'GET', params, query);
  }

  post(path, params = {}, query = '') {

    return this.request(path, 'POST', params, query);
  }

  put(path, params = {}, query = '') {

    return this.request(path, 'PUT', params, query);
  }

  delete(path, params = {}, query = '') {

    return this.request(path, 'DELETE', params, query);
  }

  request(path, method = 'GET', params = {}, query = '') {

    const url = this.connexion.getUrl(path, params, query);
    if (path.indexOf('spinner=1') != -1) {
      this.loadingService.show();
    }

    return this.callDefault(url, method, params);
  }

  callDefault(url, method, params) {
    let header = new HttpHeaders({
      'Referrer-Policy': 'no-referrer'
    });
    switch (method) {
      case 'GET':
        if(this.storage._token != '' || this.storage._token != undefined || this.storage._token != null) {
          header.set('Authorization', 'Bearer ' + this.storage._token);
        }
        return this.http.get(url, { headers: header });
      case 'POST':
        if(this.storage._token != '' || this.storage._token != undefined || this.storage._token != null) {
          header.set('Authorization', 'Bearer ' + this.storage._token);
        }
        return this.http.post(url, params, { headers: header });
      case 'PUT':
        if(this.storage._token != '' || this.storage._token != undefined || this.storage._token != null) {
          header.set('Authorization', 'Bearer ' + this.storage._token);
        }
        return this.http.put(url, params, { headers: header });
      case 'DELETE':
        if(this.storage._token != '' || this.storage._token != undefined || this.storage._token != null) {
          header.set('Authorization', 'Bearer ' + this.storage._token);
        }
        return this.http.delete(url, { headers: header });
    }
  }

  getStates(apiUrl) {
    let base = 'https://api.countrystatecity.in/v1/countries/MX/';
    const headers = new HttpHeaders().set('X-CSCAPI-KEY', environment.apiKeyContryStateCity);
    return this.http.get(base + apiUrl, { headers });
  }
}
