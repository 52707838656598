import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Categoria} from '../models/categoria.model';
import {Evento} from '../models/evento.model';
import {PlatformService} from '../providers/platform.service';
import {Location} from '@angular/common';
import {EventoSeccionService} from '../providers/evento-seccion.service';
import {EventoApiService} from '../providers/evento-api.service';
import {StorageService} from '../providers/storage.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.page.html',
  styleUrls: ['./categoria.page.scss'],
})
export class CategoriaPage implements OnInit {
//Filtros
ordenamiento = [
  { nombre: 'Mayor a menor promoción', valor: { orderBy: 'promocion', orderType: 'desc' } },
  { nombre: 'Fecha lejana', valor: { orderBy: 'fecha', orderType: 'desc' } },
  { nombre: 'Fecha cercana', valor: { orderBy: 'fecha', orderType: 'asc' } },
  { nombre: 'De menor a mayor precio', valor: { orderBy: 'precio', orderType: 'desc' } },
  { nombre: 'De mayor a menor precio', valor: { orderBy: 'precio', orderType: 'asc' } }
];
mostrarOrdenar = false;
webVersion = false;
enVista = false;
orderKey = null;

  categoria: Categoria = new Categoria();

  secciones: any;

  eventos: Evento[] = [];
  eventosAux: Evento[] = [];

  constructor(private activatedRoute: ActivatedRoute, public platform: PlatformService,
              private location: Location, private seccionService: EventoSeccionService,
              private eventoService: EventoApiService, private storage: StorageService) { }

  ngOnInit() {
    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[2];
  }

  async back () {

    await this.location.back();
  }

  ionViewWillEnter() {

    this.activatedRoute.paramMap.subscribe((param: ParamMap) => {
      const id = param.get('id');
      // let cat = JSON.parse(localStorage.getItem('categoriasEventos'));
      let cat = JSON.parse(localStorage.getItem('catActiva'));
      this.categoria = cat;
      // this.categoria = cat.message.find((c: Categoria) => c.id.toString() === id);
      // console.log(this.storage._categorias)
      this.obtenerEventos({});
    });
  }

  onBuscar(data) {

    this.obtenerEventos({ filtros: data}, true, true);
  }

  filtrarTodos(data) {

    this.obtenerEventos(data, false, true);
  }

  async obtenerEventos(data, updateSeccion = true, updateLista = true) {

    if (!this.categoria || !this.categoria.id) {

      return;
    }

    if (!data.filtros) {

      data.filtros = {}
    }

    data.filtros.categoria = [ this.categoria.id ];
    let idUser = await this.storage.getUsuarioId();
    const favoritos = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarTodoEventos(data, favoritos).subscribe((eventos: Evento[]) => {

      if (updateSeccion) {
        this.secciones = this.seccionService.seccionarEventos(eventos);
        // console.log(this.secciones);
      }

      if (updateLista) {
        this.eventos = eventos;
        this.eventosAux = eventos;
      }
    });
  }

  // Seccion filtros
  ordenarEventos() {
    if (!this.webVersion) {
      this.mostrarOrdenar = false;
    }
      this.actualizarBusqueda();
  }
  async actualizarBusqueda() {
    if(this.eventos.length == 0) {
      this.eventos = this.eventosAux;
    }
    let eventosOrdenados:any = this.seccionService.ordenarPor(this.eventos, this.orderKey.valor.orderBy, this.orderKey.valor.orderType);
    // console.log(eventosOrdenados);
    this.eventos = [];
    setTimeout(() => {
      this.eventos = eventosOrdenados;
    }, 100);
   
  }

}
