import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoadingService} from './loading-service';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';
import {ConnexionApiService} from './connexion-api.service';
import {UsuarioApiService} from './usuario-api.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private storage: StorageService, private loadingService: LoadingService,
                private conexion: ConnexionApiService, private router: Router, private usuarioService: UsuarioApiService) {}

      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
          tap((event: HttpEvent<any>) => {
            if (req.urlWithParams.indexOf('hideload=') == -1 || req.urlWithParams.indexOf('hideload=1') != -1) {
              this.loadingService.hide();
            }
          }),
          catchError(response => {

                this.loadingService.hide();
                if (response instanceof HttpErrorResponse) {

                    if (response.error && response.error.message && response.error.message.indexOf('Invalid credentials') >= 0) {

                        this.conexion.errorEncontrado('Usuario/contraseña invalida.');
                    } else if (response && response.status === 401 && response.error && response.error.message !== 'JWT Token not found') {

                        if (this.storage._refreshToken) {

                            this.usuarioService.refreshToken(this.storage._refreshToken);
                        } else {

                            this.usuarioService.logoutAndunsubscribeCloudMessaging().then(async () => {

                                await this.conexion.sesionFinalizada();
                            });
                        }
                    } else {

                        // this.conexion.errorEncontrado(response.error && response.error.message ?
                        //     response.error.message : 'Error desconocido. Por favor comuníquese con el soporte técnico!');
                    }
                }

                return throwError(response.error);
          }),
        );
      }
}
