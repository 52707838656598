import {Component, Input, OnInit} from '@angular/core';
import {PlatformService} from '../../providers/platform.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input()
  onlyWeb: boolean = false;

  webVersion = true;

  constructor(private platform: PlatformService, private iab: InAppBrowser,) { }

  ngOnInit() {
    this.webVersion = this.platform.isDesktop();
  }

  abrirAviso() {
    this.iab.create('https://transparencia.udg.mx/aviso-confidencialidad ', '_system');
  }

}
