import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {StorageService} from './storage.service';
import {PlatformService} from './platform.service';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public router: Router, private storage: StorageService, private platform: PlatformService,
                private splashScreen: SplashScreen, private navCtrl: NavController) {}

    canActivate(): boolean {


        if (!this.storage._isAuth) {

            this.navCtrl.navigateRoot([ '/login' ]).then(() => {

                this.splashScreen.hide();
                this.storage._webSplashLoading = false;
            });
            return false;
        }

        this.splashScreen.hide();
        this.storage._webSplashLoading = false;
        return true;
    }
}