import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-udeg-alert',
  templateUrl: './udeg-alert.component.html',
  styleUrls: ['./udeg-alert.component.scss'],
})
export class UdegAlertComponent implements OnInit {

  @Input()
  asModal = true;

  @Input()
  type: "success" | "error" = "success";

  @Input()
  labelBtn: string = "";

  @Input()
  message: string = "";

  @Output()
  onClick = new EventEmitter();

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async dismiss() {

    if (this.asModal) {
      await this.modalController.dismiss({
        'role': this.type
      });
    } else {

      this.onClick.emit();
    }
  }

}
