import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CuentaTelefonoPageRoutingModule } from './cuenta-telefono-routing.module';

import { CuentaTelefonoPage } from './cuenta-telefono.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CuentaTelefonoPageRoutingModule,
        SharedModule
    ],
  declarations: [CuentaTelefonoPage]
})
export class CuentaTelefonoPageModule {}
