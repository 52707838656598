import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthFirebaseService } from '../providers/auth-firebase.service';
import {PlatformService} from '../providers/platform.service';
import {UdegUserService} from '../providers/udeg.user.service';
import { StorageService } from '../providers/storage.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.page.html',
  styleUrls: ['./registro.page.scss'],
})
export class RegistroPage implements OnInit {

  webVersion = false;

  isUdegUser = false;

  constructor(private  platform: PlatformService, private udegUserService: UdegUserService, private storage: StorageService,
    private authFirebaseService: AuthFirebaseService, public platformCheck: Platform) {
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
    this.udegUserService.onUdegDataChange.subscribe((data: any) => {

      this.isUdegUser = !!(data.codigo);
    });
  }

  ionViewWillEnter() {

    this.isUdegUser = !!(this.udegUserService.udegData && this.udegUserService.udegData.codigo);
  }

  loginApple(){
    this.authFirebaseService.openAppleSignIn();
  }

  irLogin() {
    this.storage.irLogin();
  }

}
