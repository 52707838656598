import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';


import { IonicModule } from '@ionic/angular';

import { NotificacionPageRoutingModule } from './notificacion-routing.module';

import { NotificacionPage } from './notificacion.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NotificacionPageRoutingModule,
        SharedModule,
        NgbModalModule
    ],
  declarations: [NotificacionPage]
})
export class NotificacionPageModule {}
