import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Usuario} from '../models/usuario.model';
import {PlatformService} from '../providers/platform.service';
import {Evento} from '../models/evento.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UsuarioApiService} from '../providers/usuario-api.service';
import {timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {StorageService} from '../providers/storage.service';
import {ConnexionApiService} from '../providers/connexion-api.service';
import {EventoApiService} from '../providers/evento-api.service';
import { ActionSheetController, AlertController, NavController } from '@ionic/angular';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {File, FileEntry} from '@ionic-native/file/ngx';
import {UdegUserService} from '../providers/udeg.user.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.page.html',
  styleUrls: ['./perfil.page.scss'],
})
export class PerfilPage implements OnInit {

  usuario: Usuario;

  webVersion = false;

  eventos = [];

  // Para edición
  campoEdicion = '';

  edicionForm: FormGroup;

  submitted = false;

  confirmField: string;

  notificacion: boolean;

  imgBlob: any;

  udegPage = 0;

  isUdegUser = false;

  isAndroid = true;

  constructor(private location: Location, private platform: PlatformService, private modalService: NgbModal,
              private storage: StorageService, private eventoService: EventoApiService, private file: File,
              private formBuilder: FormBuilder, private usuarioService: UsuarioApiService, private router: Router,
              private actionSheetController: ActionSheetController, private camera: Camera, private zone: NgZone,
              private iab: InAppBrowser, private navController :NavController, private androidPermissions: AndroidPermissions,
              private udegUserService: UdegUserService, private alertController: AlertController, private cdRef: ChangeDetectorRef) { }

  async back () {

    await this.location.back();
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

  async ionViewWillEnter() {
    this.usuario = this.storage._usuario;
    // console.log('usuarios login');
    // console.log(this.usuario); provider_id
    this.notificacion = this.usuario.noPush;
    this.isUdegUser = !this.usuario.codigo_ude_g ? false : true;
    this.udegPage = 0;
    let idUser = await this.storage.getUsuarioId();
    this.eventos = [];
    this.eventoService.obtenerEventoFavoritos(idUser).subscribe((favoritos: any) => {
      favoritos.intereses.forEach(favorito => {
        let event = favorito.eventos.map( (e: any) => new Evento(e, favoritos.intereses));
        this.eventos.push(event[0]);
      });
      this.eventos = this.eventos.filter(elemento => elemento !== undefined);
      console.log(this.eventos);
    });
    this.iniciarForm();

    this.storage.getNotificacion().then((value) => this.notificacion = value);
    if(this.platform.is('android')){
      this.isAndroid = true;
    } else {
      this.isAndroid = false;
    }
  }

  iniciarForm() {

    if (!this.usuario) {

      return;
    }

    this.edicionForm = this.formBuilder.group({
      nombre:   [ this.usuario.nombre  , [
          Validators.required, Validators.minLength(3), this.hasSameValue('confirmField').bind(this)
      ] ],
      telefono: [ this.usuario.telefono, [
          Validators.required, Validators.minLength(6), Validators.pattern(/^[+0-9]{6,}$/),
          this.hasSameValue('confirmField').bind(this)
      ] ],
      correo: new FormControl(
          this.usuario.correo,
          {
            validators: [ Validators.required, Validators.email, this.hasSameValue('confirmField').bind(this) ],
            asyncValidators: [ this.emailIsTaken(this.usuarioService) ]
          }
      ),
      password: [ '',
        [
            Validators.required, Validators.minLength(5),
            this.hasSameValue('confirmField').bind(this)
        ]]
    });
  }

  async seleccionarFotoPerfil(content) {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => console.log('Has permission?',result.hasPermission),
      err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    );
    
    this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA]);

    const actionSheet = await this.actionSheetController.create({
      header: "Seleccione una opción",
      buttons: [{
        text: 'Imagen de la biblioteca',
        handler: () => {
          this.takePicture(content, this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
        {
          text: 'Usar cámara',
          handler: () => {
            this.takePicture(content, this.camera.PictureSourceType.CAMERA);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  async seleccionarFotoPerfilWeb(inputEl) {

    if (inputEl && inputEl.click) {

      inputEl.click();
    }
  }

  async fotoPerfilWebCambio(el, content) {

    if (el.files && el.files.length) {

      this.readFile(content, el.files[0]);
    }
  }

  takePicture(content, sourceType: number) {

    let options: CameraOptions = {
      quality: 50,
      destinationType: this.webVersion ? this.camera.DestinationType.DATA_URL : this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType:sourceType,
    };

    this.camera.getPicture(options).then((uri) => {

      if (this.webVersion) {

        const contentType = 'image/jpeg';

        this.imgBlob = this.b64toBlob(uri, contentType);

        this.campoEdicion = 'foto';
        this.openAdvertenciaModal(content);

      } else {

        this.file.resolveLocalFilesystemUrl(uri).then((entry: FileEntry) => {

          entry.file(file => { console.log(file); this.readFile(content, file); });
        });
      }

    }, (error) => {
      console.log('Error al subir foto de perfil: No se pudo obtener la foto de perfil.' + error)
    });

  }

  readFile(content, file) {

    const reader = new FileReader();

    reader.onloadend = () => {

      const imgBlob = new Blob([ reader.result ], { type: file.type });
      this.zone.run(() => {
        this.campoEdicion = 'foto';
        this.imgBlob = imgBlob;
        this.openAdvertenciaModal(content);
      });
    };

    reader.readAsArrayBuffer(file);
  }

  async editarCampo() {

    this.modalService.dismissAll();

    let service;

    if (this.campoEdicion !== 'foto') {

      const data = {};
      data[this.campoEdicion] = this.edicionForm.value[this.campoEdicion];
      let idUser = await this.storage.getUsuarioId();
      service = this.usuarioService.actualizarPerfil(idUser, data);
    } else {
      return;
    }

    if (service) {

      service.subscribe((usuario: Usuario) => {
        this.storage.setUsuario(usuario);
        if(usuario.cuenta_verificada == 0) {
          this.navController.navigateRoot('/cuenta-telefono');
        } else {
          this.router.navigate([ '/home' ]);
        }
      })
    }
  }

  async cerrarSesion() {

    // await this.usuarioService.logoutAndunsubscribeCloudMessaging();
    this.udegUserService.resetData();
    // location.reload();
  }

  eliminarCuenta() {

    this.modalService.dismissAll();

    this.usuarioService.eliminarCuenta().subscribe(async () => {

      await this.usuarioService.signoffAndunsubscribeCloudMessaging();
      await this.storage.irLogin();
    });
  }

  openFormModal(content, field) {

    this.campoEdicion = field;
    this.confirmField = "";
    this.modalService.open(content, { centered: true, windowClass: 'dark-modal' });
  }

  async openAdvertenciaModal(content) {

    if (this.campoEdicion !== 'foto') {

      this.submitted = true;

      if (this.edicionForm.controls[this.campoEdicion].invalid) {

        return;
      }
    } else {

      // Para las fotos no se muestra el modal. Se guarda en el servidor y listo.
      if (this.imgBlob) {
        let idUser = await this.storage.getUsuarioId();
        this.usuarioService.uploadPhoto(idUser, this.imgBlob).subscribe((usuario: Usuario) => {
          this.usuarioService.perfil(idUser, 1, 1).subscribe( async (usuario: Usuario) => {
            await this.usuarioService.checkVerificacion(usuario);
            this.usuario = usuario;
            this.storage.setUsuario(usuario);
         });
        });
      }

      return;
    }

    this.modalService.open(content, { centered: true, windowClass: 'dark-modal' });
  }

  openEliminarCuentaModal(content) {

    this.modalService.open(content, { centered: true, windowClass: 'dark-modal' });
  }

  closeFormModal(modal) {

    this.submitted = false;
    this.edicionForm.reset({
      nombre: this.usuario.nombre,
      correo: this.usuario.correo,
      telefono: this.usuario.telefono,
      password: ''
    });
    this.confirmField = '';
    modal.dismiss()
  }

  // Considerar crear clase
  hasSameValue(confirmField: string) {

    return (c: FormControl) => {

      return this[confirmField] && this[confirmField] === c.value ? null : {
        hasSameValue: { valid: true }
      };
    }
  }

  emailIsTaken(userService: UsuarioApiService) {

    return (c: FormControl) => {

      return timer(500).pipe(switchMap(() => {

        return userService.checkEmail(c.value)
      }), map( (data: any) => {

        return !data.user ? null : { 'emailIsTaken': true }
      }));
    }
  }

  fotoPerfil() {

    if (!this.usuario || ( !this.usuario.foto && !this.usuario.fotoUrl) ) {

      return;
    }

    return this.usuario.foto ? `${ConnexionApiService.host}${this.usuario.foto}` : this.usuario.fotoUrl;
  }

  async changeNotificacion() {
    let idUser = await this.storage.getUsuarioId();
    await this.usuarioService.actualizarPerfil(idUser, { noPush: !this.notificacion }).toPromise();
    await this.storage.setNotificacion(this.notificacion);

    if (this.notificacion) {

      await this.usuarioService.subscribeCloudMessaging(this.storage._usuario);
    } else {

      await this.usuarioService.unsubscribeCloudMessaging();
    }

  }

  b64toBlob(b64Data, contentType='', sliceSize=512) {

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return  new Blob(byteArrays, {type: contentType});
  }

  nextUdegPage() {

    this.udegPage += 1;
  }

  prevUdegPage() {

    this.udegPage -= 0;
  }

  mostrarUdegForm() {

    this.udegPage = 1;
  }

  enviarUdegForm(data) {

    this.usuarioService.statusUdegUser(data.codigo).subscribe((data) => {

      this.udegPage = 0;
      this.storage.setUsuario(new Usuario(data.usuario));
      this.isUdegUser = true;
    }, err => {

      this.isUdegUser = false;
      this.udegPage = 2;
    });

  }

  goToCupones() {
    this.router.navigate([ '/cupones' ]);
  }


  async solicitadDatos() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Inicia Sesión UdeG',
      inputs: [
        {
          name: 'codigo',
          type: 'text',
          placeholder: 'Código UdeG',
        },
        // {
        //   name: 'nip',
        //   type: 'text',
        //   placeholder: 'NIP',
        // }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Enviar',
          handler: (data) => {
            console.log('Confirm Ok', data);
            this.usuarioService.statusUdegUser(data.codigo).subscribe(async (dataResp) => {
              if(data.respuesta == false) {
                this.isUdegUser = false;
                this.udegPage = 2;
                const alertMensaje = await this.alertController.create({
                  cssClass: 'my-custom-class',
                  header: 'Inicia Sesión UdeG',
                  message: 'Tus datos no coinciden' + ', Intenta de Nuevo',
                  buttons:['Ok']});
                  await alertMensaje.present();
              } else {
                localStorage.setItem('userUdeGDatos', JSON.stringify({ codigo:data.codigo, nip:data.nip }));
                let usuarioId = await localStorage.getItem('usuarioId');
                await this.usuarioService.actualizarPerfil(usuarioId, {
                  codigo_ude_g: data.codigo,
                  nip: data.nip,
                }).toPromise();
                const alertMensaje = await this.alertController.create({
                  cssClass: 'my-custom-class',
                  header: 'Inicia Sesión UdeG',
                  message:'Datos verificados',
                  buttons:['Ok']});
                  await alertMensaje.present();
                this.udegPage = 1;
                this.isUdegUser = true;
              }
            }, async (err) => {
              console.log(err);
              const alertMensaje = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Inicia Sesión UdeG',
                message: err.message || 'Tus datos no coinciden' + ', Intenta de Nuevo',
                buttons:['Ok']});
                await alertMensaje.present();
              this.udegPage = 2;
              this.isUdegUser = false;
            }, () => {
              this.cdRef.detectChanges();
            });
          },
        },
      ],
    });

    await alert.present();
  }

  abrirAviso() {
    this.iab.create('https://transparencia.udg.mx/aviso-confidencialidad ', '_system');
  }

}
