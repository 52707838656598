import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FacebookAuthPageRoutingModule } from './facebook-auth-routing.module';

import { FacebookAuthPage } from './facebook-auth.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FacebookAuthPageRoutingModule,
        SharedModule
    ],
  declarations: [FacebookAuthPage]
})
export class FacebookAuthPageModule {}
