import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {PlatformService} from '../providers/platform.service';
import {Evento} from '../models/evento.model';
import {Router} from '@angular/router';
import {EventoSeccionService} from '../providers/evento-seccion.service';
import {StorageService} from '../providers/storage.service';
import {HeadFilterComponent} from '../shared/head-filter/head-filter.component';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.page.html',
  styleUrls: ['./busqueda.page.scss'],
})
export class BusquedaPage implements OnInit {

  webVersion = false;

  secciones: any;

  eventos: Evento[] = [];

  @ViewChild('filter_component', { static: true})
  filterComponent: HeadFilterComponent;

  constructor(public platform: PlatformService, private location: Location, private router: Router,
              private storage: StorageService, private seccionService: EventoSeccionService) { }

  async back () {

    await this.location.back();
  }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

  ionViewWillEnter() {

    this.eventos = this.storage._eventos;
    this.secciones = this.seccionService.seccionarEventos(this.eventos);
    this.filterComponent.reset();
  }

  async onBuscarEventos(data) {

    console.group('BusquedaPage@onBuscarEventos');
    console.log(data);
    console.groupEnd();
    await this.storage.setMetadata(data);
    await this.router.navigate([ 'busqueda-resultado' ]);
  }

}
