import {Component, Input, OnInit} from '@angular/core';
import {IonSlides} from '@ionic/angular';

@Component({
  selector: 'app-evento-seccion',
  templateUrl: './evento-seccion.component.html',
  styleUrls: ['./evento-seccion.component.scss'],
})
export class EventoSeccionComponent implements OnInit {

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  @Input()
  titulo: string;

  @Input()
  highlight = false;

  itemPorSlide = 0;

  @Input()
  collection: any[] = [];

  constructor() { }

  ngOnInit() {

    this.checkBreakPoint();
  }

  ngAfterViewInit() {

    this.checkBreakPoint();
  }

  ionViewWillEnter() {

    this.checkBreakPoint();
  }

  onResize() {

    this.checkBreakPoint();
  }

  checkBreakPoint () {

    let n = 2;
    if (window.innerWidth > 992) {

      n = 4;
    } else if (window.innerWidth > 576) {

      n = 3;
    }

    if (!this.itemPorSlide || this.itemPorSlide !== n) {

      this.itemPorSlide = n;
    }
  }

  slide() {

    const n = Math.ceil(this.collection.length / this.itemPorSlide);
    const lista = [];

    for (let i=0; i < n; i++) {

      lista.push(this.collection.slice(i * this.itemPorSlide, (i+1) * this.itemPorSlide));
    }

    return lista;
  }

  async prev(slides: IonSlides) {

    await slides.slidePrev();
  }

  async next(slides: IonSlides) {

    await slides.slideNext();
  }

}
