import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {Usuario} from '../models/usuario.model';
import {StorageService} from './storage.service';
import {HttpRequestApiService} from './http-request-api.service';
import {Router} from '@angular/router';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {ConfigDataEnum} from '../models/config.data.enum';
import {AlertController, Platform, ToastController} from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthFirebaseService } from './auth-firebase.service';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { CategoriaApiService } from './categoria-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioApiService {
    validacionTwilio = environment.validacionTwilio;
  private WebPushSDK: any = {
        loaded: false,
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
  };

  // Usado para enviar el código y nip al crear usuario
  udegData: { codigo: string, nip: string } = { codigo: null, nip: null };

  constructor(private http: HttpRequestApiService, private storage: StorageService, private router: Router,
            private platform: Platform, private fireAuth: AngularFireAuth, private pltform : PlatformService,
            private splashScreen: SplashScreen, private toast: ToastController, private categoriaService:CategoriaApiService,
            private firebaseMessaging: FirebaseMessaging
        ) { }

    async login(credentials: any, recordar, spinner = 1, hideload = 1) {
        const checkLogin = await this.checkLogin(credentials, spinner, hideload);
        await this.generarToken(checkLogin, recordar);
    }

    checkLogin(credentials: any, spinner = 1, hideload = 1) {

      return this.http.post('api/login_check'+"?spinner="+spinner+"&hideload="+hideload, credentials);
    }

    refreshToken(rtoken: string) {

        this.generarToken(this.http.post('api/token/refresh', { refresh_token: rtoken }), true);
    }
    perfil(id, spinner = 1, hideload = 1): Observable<Usuario> {

        return this.http.get('api/v1/usuario/perfil/'+id+"?spinner="+spinner+"&hideload="+hideload).pipe(map( (obj: any) => {

            const usuario = new Usuario(obj.user);

            if (!usuario.noPush) {
                this.subscribeCloudMessaging(usuario);
            }

            return usuario;
        }));
    }

    checkEmail(email: string): Observable<any> {

      return this.http.post('api/auth/email/exist', { 'correo' : email },'spinner=0');
    }

    checkUsername(username: string): Observable<any> {

        return this.http.post('api/auth/username/exist', { username },'spinner=0');
    }

    // checkUdegUser(codigo: string, nip: string, auth = false): Observable<any> {

    //     if (auth) {

    //         return this.http.post('api/auth/user/udeg/checkExternal', { codigo, nip });
    //     } else {

    //         return this.http.post('api/auth/user/udeg/check', { codigo, nip });
    //     }
    // }

    statusUdegUser(codigo: string): Observable<any> {
        let generarTokenAlumno = this.generarTokenAlumno(codigo);
        return this.http.post('api/auth/user/udeg/checkStatus', { codigo: generarTokenAlumno });
    }

    createUser(usuario: any): Observable<Usuario> {

        let url = 'api/auth/user/create';

        console.log(this.udegData);
        if (this.udegData.codigo && this.udegData.nip) {

            url += `?codigo=${this.udegData.codigo}&nip=${this.udegData.nip}`;
        } else {
            url += `?codigo=${usuario.codigo}&nip=${usuario.nip}`;
        }

        return this.http.post(url, usuario).pipe(
            map( (data: any) => new Usuario(data.usuario) )
        );
    }

    verificarCaptha(token: string): Observable<any> {

        // TODO: Verificar Captcha.
        return timer(500).pipe(map(() => {
            return { success: true };
        }));
    }

    actualizarPerfil(id, data: any, foto = false): Observable<Usuario> {
        if(!foto) {
            return this.http.put('api/auth/user/update/'+id, data).pipe(
                map((data: any) => new Usuario(data.message) )
            );
        } else {
            return this.http.post('api/auth/user/update/foto/'+id, data).pipe(
                map((data: any) => new Usuario(data.message) )
            );
        }
    }

    uploadPhoto(idUser, imgBlob: any) {

        let formData = new FormData();
        let fecha = new Date().getTime();
        formData.append('imagen', imgBlob, 'tempfile_' + fecha + '.jpeg');

        return this.actualizarPerfil(idUser, formData, true);
    }

    eliminarCuenta(): Observable<any> {

        return this.http.get('api/v1/usuario/signoff');
    }

    solicitarRecuperarPassword(username) {

      return this.http.post('api/password/reset', { username: username });
    }

    generarToken(observable: Observable<any>, recordar: boolean): any {

      observable.subscribe( async (data: any) => {

          // almacena el token.
        this.storage.setToken(data.token);

          if (recordar) {

                this.storage.setRefreshToken(data.token);
          } else {

               this.storage.setRefreshToken(null);
          }

          await this.recuperarPerfil(data.user.id);

      }, async (err) => {

          console.error("Error al obtener token o perfil!");
          console.log(err);
        //   let error = JSON.parse(err);
          this.mostrarNuevaNotificacion("Error al obtener token o perfil!", err.message);

        this.storage.setToken(null);
        this.storage.setRefreshToken(null);
          this.storage.irLogin();

          // Si está activo el splashscreen lo esconde
          this.splashScreen.hide();
      })
    }

    // Obtiene el perfil de usuario
    recuperarPerfil(id, spinner = 1, hideload = 1) {
        const user = this.fireAuth.auth.currentUser;
        this.perfil(id, spinner, hideload).subscribe( async (usuario) => {
            await this.storage.setUsuarioId(id);
            this.storage._isAuth = true;
            //usuario.estaVerificada = user.emailVerified;
            this.storage.setUsuario(usuario);
            await this.checkVerificacion(usuario);

            if(usuario.estaVerificada){
                let formData = new FormData();
                formData.append('estaVerificada', usuario.estaVerificada.toString());
                this.actualizarPerfil(id, formData);
            }
            
            // Si está activo el splashscreen lo esconde
            this.splashScreen.hide();
            this.storage._webSplashLoading = false;
        });
    }

    async checkVerificacion(usuario) {
        if (usuario.cuenta_verificada == 1) {
            if(usuario.telefono == null && this.validacionTwilio) {
                await localStorage.setItem('updateCorreoUsuario', JSON.stringify({ correo: usuario.correo, esVerificada: false }));
                await this.router.navigate([ '/cuenta-telefono' ]);
            } else {
                this.verfication(usuario);
            }
        } else {
            await localStorage.setItem('updateCorreoUsuario', JSON.stringify({ correo: usuario.correo, esVerificada: false }));
            await this.router.navigate([ '/cuenta-telefono' ]);
        } 
    }

    async verfication(usuario){
        this.storage._isAuth = true;
        if (usuario.intereses && usuario.intereses.length) {
            await this.router.navigate([ '/home' ]);
        } else {
            await this.router.navigate([ '/intereses' ]);
        }
    }

    registerFCMToken(token: string, idUser) {
        console.log('FCM Token: '+ token);
        return this.http.post(`api/fcm/token/${encodeURI(token)}`, {"user_id": idUser}, '').toPromise();
    }

    // TODO: Create a new provider for CloudMessaging functionality
    async subscribeCloudMessaging(usuario: Usuario) {

        // // this.oneSignal.startInit(ConfigDataEnum.ONE_SIGNAL_APP_ID, ConfigDataEnum.FCM_SENDER_ID);
        // // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

        // // this.oneSignal.handleNotificationReceived().subscribe((data) => {
        // //     this.storage.newNotification(true);
        // //     this.mostrarNuevaNotificacion(data.payload.title, data.payload.body);
        // // }, (err) => console.log(err));

        // // this.oneSignal.handleNotificationOpened().subscribe((data) => {
        // //     this.router.navigate([ '/notificacion' ]);
        // // }, (err) => console.log(err));
        // // // this.oneSignal.promptForPushNotificationsWithUserResponse();
        // // // console.log(permisos);§ƒ§©
        // // this.oneSignal.endInit();

        // // // this.oneSignal.setSubscription(true);

        // // await this.storage.setNotificacion(true);
        // // const data = await this.oneSignal.getIds();
        // // let idUser = await this.storage.getUsuarioId();
        // // await this.registerFCMToken(data.userId, idUser);

        // // if (usuario.id && usuario.correo) {
        // //     this.oneSignal.setExternalUserId(usuario.id.toString());
        // //     await this.oneSignal.setEmail(usuario.correo);
        // // }

          
       
        // this.oneSignal.endInit();
        // this.oneSignal.setSubscription(true);

        // // OneSignal.setLogLevel(6,6);

        // // OneSignal.setAppId(ConfigDataEnum.ONE_SIGNAL_APP_ID);

        // // OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        // //     console.log("User accepted notifications: " + accepted);
        // // });

        // // OneSignal.setNotificationOpenedHandler((myClickListener) => {
        // //     this.router.navigate([ '/notificacion' ]);
        // // });

        // // OneSignal.setNotificationWillShowInForegroundHandler((e) => {
        // //     this.storage.newNotification(true);
        // //     let notification = e.getNotification();
        // //     this.mostrarNuevaNotificacion(notification.title, notification.body);
        // // });

        // // OneSignal.addSubscriptionObserver(event => {
        // //     console.log(event);
        // // });
    
          // Prompts the user for notification permissions.
          // Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        //   OneSignal.perm(true).then((accepted: boolean) => {
        //     console.log("User accepted notifications: " + accepted);
        //   });
        // OneSignal.Notifications.addEventListener('click' ,async (e) => {
        //     this.router.navigate([ '/notificacion' ]);
        // });
        // OneSignal.Notifications.addEventListener('foregroundWillDisplay', async (e) => {
        //     this.storage.newNotification(true);
        //     let notification = e.getNotification();
        //     this.mostrarNuevaNotificacion(notification.title, notification.body);
        // });
        
        // // OneSignal.disablePush(false);
        // await this.storage.setNotificacion(true);
        // // OneSignal.getDeviceState(async (identity:any) => {
        //     let OneSignalId = await OneSignal.User.pushSubscription.getIdAsync();
        //     // let OneSignalId = await OneSignal.User.getOnesignalId();
        //     let idUser = await this.storage.getUsuarioId();
        //     // console.log(idUser);
        //     await this.registerFCMToken(OneSignalId, idUser);
        // // });
       
        // if (usuario.id && usuario.correo) {
        // //     OneSignal.User.addAlias("alias", usuario.id.toString());
        //     OneSignal.User.addEmail(usuario.correo);
        // }
        return true;
    }

    async mostrarNuevaNotificacion(title, message) {

      await this.mostrarToast(`${title ? title : 'Butaca UDG'}: ${message}`, 'primary');
    }

    async agregarTag(tags) {
        // // tags.forEach(element => {
        // //     this.oneSignal.deleteTag(element.categoria);
        // //     // // OneSignal.User.removeTag(element.categoria);
        // //     // OneSignal.deleteTag(element.categoria);
        // // });
        // // tags.forEach(element => {
        // //     this.oneSignal.sendTag(element.categoria, element.valor);
        // //     // // OneSignal.User.addTag(element.categoria, element.valor);
        // //     // OneSignal.sendTag(element.categoria, element.valor);
        // //     console.log(element.categoria + '-' + element.valor);
        // // });
    }

    async eliminarTags() {
        // // ['Comunidad', 'Todos', 'Publico'].forEach(element => {
        // //     this.oneSignal.deleteTag(element);
        // //     // // OneSignal.User.removeTag(element);
        // //     // OneSignal.deleteTag(element);
        // // });
    }

    async mostrarToast(message, color) {

        const toast = await this.toast.create({
            message: message,
            duration: 5000,
            color,
            position: 'top'
        });

        await toast.present();
    }

    async logoutAndunsubscribeCloudMessaging() {

        await this.storage.logout();
        await this.unsubscribeCloudMessaging();
    }

    async signoffAndunsubscribeCloudMessaging() {

        await this.storage.signoff();
        await this.unsubscribeCloudMessaging();
    }

    async unsubscribeCloudMessaging() {

        // await this.storage.setNotificacion(false);
        // await this.oneSignal.logoutEmail();
        // // OneSignal.setSubscription(false);
        // this.oneSignal.setSubscription(false);

        await this.storage.setNotificacion(false);
        // await OneSignal.logout();
        // OneSignal.disablePush(true);
    }

    // TODO: Implementacion for browser [No EN USO. Para Probar!]
    subscribeCloudMessagingForBrowser(usuario: Usuario) {

      return new Promise((resolve) => {

          // El plugin de Cordova se inyecta en `window['OneSignal']`. Lo eliminamos.
          delete window['OneSignal'];

          this.loadWebOneSignalPushSDK().then(() => {

              // El SDK de WebPush ya ha sido cargado y ahora debemos inicializarlo
              // normalmente.
              let OneSignalBrowser = window['OneSignal'];

              if (!OneSignalBrowser)
                  throw new Error("OneSignal not found");

              this.browserNotificationSetup(OneSignalBrowser, usuario);

               resolve(true);
            }, (errors: any) => {
                console.log(errors);
                resolve(false);
            });
        });
    }

    browserNotificationSetup(OneSignalBrowser, usuario: Usuario) {

        OneSignalBrowser.push(() => {

            OneSignalBrowser.init({appId: ConfigDataEnum.ONE_SIGNAL_APP_ID});

            OneSignalBrowser.setSubscription(true);

            OneSignalBrowser.getUserId((userId) => {

                this.registerFCMToken(userId, usuario.id);
            });

            this.storage.setNotificacion(true);

            if (usuario.id && usuario.correo) {
                OneSignalBrowser.setExternalUserId(usuario.id.toString());
                OneSignalBrowser.setEmail(usuario.correo);
            }
        });
    }

    loadWebOneSignalPushSDK() {

        return new Promise((resolve, reject) => {
            if (this.WebPushSDK.loaded) {
                resolve(true);
            } else {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.WebPushSDK.src;

                script.onload = () => {
                    this.WebPushSDK.loaded = true;
                    resolve(true);
                };

                script.onerror = () => {
                    reject(false);
                };

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    generarSuscripcionOneSignal(usuario) {
        return new Promise(async (resolve) => {
            if(this.pltform.isDesktop()) {
                resolve(await this.subscribeCloudMessagingForBrowser(usuario));
            } else {
                resolve(await this.subscribeCloudMessaging(usuario));
            }
        });
    }

    validatePermission(usuario) {
        try {
            return new Promise(async (resolve) => {
                this.firebaseMessaging.requestPermission().then((permission) => {
                    // console.log("Permission granted: ", permission);
                    resolve(true);
                }).catch(async (error) => {
                    // console.log("Permission denied: ", error);
                    resolve(false);
                });
    
                console.log(await this.firebaseMessaging.getToken());
                // this.firebaseMessaging.subscribe('Comunidad-Alumno');
                await this.desuscripcionTotal();
                this.estatusUdeG(usuario);
                this.firebaseMessaging.onBackgroundMessage().subscribe(resp => {
                    // console.log('FCM onBackgroundMessage');
                    // console.log(resp);
                    // this.storage.newNotification(true);
                    // this.firebaseMessaging.getInstanceId().then(resp => {
                    //     console.log(resp);
                    // });
                    this.router.navigate([ '/notificacion' ]);
                });
                this.firebaseMessaging.onMessage().subscribe(resp => {
                    // console.log('FCM onMessage');
                    // console.log(resp);
                    let notification = resp.gcm;
                    this.mostrarNuevaNotificacion(notification.title, notification.body);
                    this.router.navigate([ '/notificacion' ]);
                });
            // this.fcm.onNotification().subscribe(data => {
            //     if (data.wasTapped) {
            //       console.log("Received in background", data);
            //     } else {
            //       console.log("Received in foreground", data);
            //     }
            });
        } catch (error) {}
    }

    async estatusUdeG(codigo = null) {
        this.desuscripcionGeneral();
        let idUser = this.storage._idUser;
        if(codigo.codigo_ude_g != null) {
            this.statusUdegUser(codigo.codigo_ude_g).subscribe(async (data) => {
                // console.log('WS tipoUsuario');
                // console.log(data);
                if(data.tipoUsuario){
                    this.firebaseMessaging.subscribe('Todos');
                    this.firebaseMessaging.subscribe('Comunidad-Todos');
                    this.firebaseMessaging.subscribe('Comunidad-' + data.tipoUsuario);
                    localStorage.setItem('Segmento', 'Comunidad-' + data.tipoUsuario);
                    this.categoriaService.asignarSegmento('Comunidad-' + data.tipoUsuario, idUser).subscribe(data => {
                        // console.log('Asignacion Segmento');
                        // console.log(data);
                    });
                } else {
                    this.subscribeGeneral(idUser);
                }
            }, async (err) => {
                console.log(err);
                this.subscribeGeneral(idUser);
            });
        } else {
            this.subscribeGeneral(idUser);
        }
    }

    subscribeGeneral(idUser) {
        this.firebaseMessaging.subscribe('Todos');
        this.firebaseMessaging.subscribe('General');
        this.firebaseMessaging.subscribe('Publico');
        this.categoriaService.asignarSegmento('General', idUser).subscribe(data => {
            // console.log('Asignacion Segmento');
            // console.log(data);
        });
    }

    desuscripcionGeneral(){
        this.firebaseMessaging.unsubscribe('Todos');
        this.firebaseMessaging.unsubscribe('General');
        this.firebaseMessaging.unsubscribe('Publico');
        this.firebaseMessaging.unsubscribe('Comunidad');
        this.firebaseMessaging.unsubscribe('Comunidad-Todos');
        this.firebaseMessaging.unsubscribe('Comunidad-Alumno');
        this.firebaseMessaging.unsubscribe('Comunidad-Academico');
        this.firebaseMessaging.unsubscribe('Comunidad-Administrativo');
        this.firebaseMessaging.unsubscribe('Comunidad-Egresado');
    }

    desuscripcionTotal(){
        this.firebaseMessaging.unsubscribe('Todos');
        this.firebaseMessaging.unsubscribe('General');
        this.firebaseMessaging.unsubscribe('Publico');
        this.firebaseMessaging.unsubscribe('Comunidad');
        this.firebaseMessaging.unsubscribe('Comunidad-Todos');
        this.firebaseMessaging.unsubscribe('Comunidad-Alumno');
        this.firebaseMessaging.unsubscribe('Comunidad-Egresado');
        this.firebaseMessaging.unsubscribe('Comunidad-Academico');
        this.firebaseMessaging.unsubscribe('Comunidad-Administrativo');
        localStorage.removeItem('Segmento');
    }

    doubleEncodeBase64(input) {
        let encoded = btoa(input); // Primera codificación
        encoded = btoa(encoded);   // Segunda codificación
        return encoded;
    }
    
      generateUniqueId(alumnoId) { // Generacion de uniqueId
        let timestamp = Math.floor(Date.now() / 1000); // Marca de tiempo en segundos
        let uniqid = this.doubleEncodeBase64(alumnoId + '-' + timestamp);
        return uniqid;
      }
    
      generarTokenAlumno(idAlumno) {
        let uniqid = this.generateUniqueId(idAlumno);
        // return "show?encryptedId=" + uniqid;
        return uniqid;
      }
}

