import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequestApiService} from './http-request-api.service';
import {Sitio} from '../models/sitio.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfoApiService {

    constructor(private http: HttpRequestApiService) { }

    infoBySlug(slug): Observable<any> {

      return this.http.get('api/v1/info/' + slug);
    }

    sitiosInteres(): Observable<Sitio[]> {

        return this.http.get('api/v1/info/sitios/intereses').pipe(
            map( (obj: any[]) => obj.map((o: any) => new Sitio(o)))
        );
    }

}

