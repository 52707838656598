import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InteresesPageRoutingModule } from './intereses-routing.module';

import { InteresesPage } from './intereses.page';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        InteresesPageRoutingModule,
        SharedModule
    ],
  declarations: [InteresesPage]
})
export class InteresesPageModule {}
