import {Deserializable} from './deserializable.model';
import {ConnexionApiService} from '../providers/connexion-api.service';

export class Notify implements Deserializable {

    id: string | number;
    titulo = '';
    mensaje = '';
    nuevo = false;
    imagenUrl = '';
    fecha = '2019-02-03';
    url = '';

    constructor(obj = null) {

        if (obj) {
            this.deserialize(obj)
        }
    }

    deserialize(obj: any): this {
        Object.assign(this, obj);
        if(obj.imagen != null) {
            this.imagenUrl = `${ConnexionApiService.host.replace('api','app.')}/uploads/notificacion/${obj.imagen}`;
        } else {
            this.imagenUrl = '';
        }
        // console.log(obj);
        return this;
    }
}
