import {Deserializable} from './deserializable.model';

export class Filtro implements Deserializable {

    texto = '';
    categoria: any[] = [];
    genero: any[] = [];
    recinto: any[] = [];
    fecha  = { from: null, to: null };
    precio = { min: null, max: null };

  constructor(obj = null) {

      if (obj) {

        this.deserialize(obj)
      }
  }

  deserialize(obj: any): this {

    Object.assign(this, obj);

    return this;
  }
}
