import {Deserializable} from './deserializable.model';
import {Helper} from './helper';
import {ConnexionApiService} from '../providers/connexion-api.service';
import {Genero} from './genero.model';
import { EventoApiService } from '../providers/evento-api.service';

export class Evento implements Deserializable {

  id: number;
  nombre: string = '';
  precios: number[] = [ ];
  descripcion = '';
  redes = { facebook: '', instagram: '', web: '', twitter: '', youtube: '', snapchat: '' };  // urls
  imagenes: string[];        // urls
  seccion: string;
  recinto: any;
  textoPromocion: string;
  fechaProxima$: any;
  fechaInicio: string;
  fechaFin: string;
  video: string;
  horario: any;
  liked: boolean;
  generos: Genero[] = [];
  urlCompra: string;
  esGratuito: boolean;
  recomendado: boolean;
  foto: string;
  precio_bajo: string;
  precio_alto: string;
  latitud: string;
  longitud: string;

  constructor(obj = null, intereses = []) {

      if (obj) {

        this.deserialize({evento: obj, intereses: intereses})
      }
  }

  featuredImg() {

      if (!this.imagenes || this.imagenes.length == 0 ) {

          return null;
      }

      return this.imagenes[0];
  }

  precioMinimo() {

    if (this.precio_bajo) {
        return this.formatearNumero(this.precio_bajo);
    }
    return null;
  }

  precioMaximo() {

    //   if (!this.precio_alto || !this.precios.length ) {

    //       return;
    //   }

    //   let precio =  (this.precios && this.precios.length > 0) ? Math.max(...this.precios): null;
    //   return precio !== this.precioMinimo() ? precio: null;
    if (this.precio_alto) {
        return this.formatearNumero(this.precio_alto);
    }
    return this.precioMinimo();
  }

  fechaProxima(format = false) {
    let userTimezoneOffset = new Date().getTimezoneOffset() * 60000;

    const fb = new Date(new Date(this.fechaInicio).getTime() + userTimezoneOffset);
    const fe = new Date(new Date(this.fechaFin).getTime() + userTimezoneOffset);
    let fechaActual = new Date(new Date().getTime() + userTimezoneOffset);

    let fecha = fechaActual > fb ? (fechaActual < fe ? fechaActual : fe) : fb;

    if (!format) {
        return fecha;
    }

    let sFecha = Helper.FormatFecha(fecha); // Retorna un objeto con 'fechaCorta' y posiblemente 'hora'

    try {
        this.horario = JSON.parse(this.horario);
    } catch (error) {
        // El horario ya está en el formato correcto o manejar el error
    }

    if (this.horario && this.horario.length) {
        let dayOfWeek = fecha.getDay();
        dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        const horarioDelDia = this.horario.find(h => h.dias.includes(dayOfWeek.toString()));

        if (horarioDelDia) {
            sFecha.hora = horarioDelDia.horas; // Actualiza 'hora' en 'sFecha' si se encuentra un horario correspondiente
        }
    }

    // Construir el resultado final considerando solo la modificación de 'fechaCorta'
    let resultadoFinal = { fechaCorta: sFecha.fechaCorta, hora: sFecha.hora };

    if (this.fechaInicio !== this.fechaFin) {
        const fechaInicioFormat = Helper.FormatFecha(fb).fechaCorta;
        const fechaFinFormat = Helper.FormatFecha(fe).fechaCorta;

        const diaInicio = fechaInicioFormat.split(" ")[0];
        let fechaCortaModificada = diaInicio; // Iniciar rango de fechas con el día de inicio

        const mesInicio = fechaInicioFormat.substring(fechaInicioFormat.indexOf("de") + 3);
        const mesFin = fechaFinFormat.substring(fechaFinFormat.indexOf("de") + 3);

        if (mesInicio === mesFin) {
            // Mismo mes para ambas fechas
            const diaFin = fechaFinFormat.split(" ")[0];
            fechaCortaModificada += `, ${diaFin} de ${mesFin}`;
        } else {
            // Meses diferentes
            fechaCortaModificada += ` de ${mesInicio}, ${fechaFinFormat}`;
        }

        resultadoFinal.fechaCorta = fechaCortaModificada; // Actualizar solo 'fechaCorta' en el resultado final
    }

    return resultadoFinal; // Retorna el objeto con 'fechaCorta' modificada y 'hora'
}

  horarios() {
    try {
        this.horario = JSON.parse(this.horario);
    } catch (error) {
        // El horario ya está en el formato correcto o manejar el error
    }
    if (this.horario && this.horario.length) {
        if(this.horario.length > 1) {
            return 'Consultar horarios';
        } else {
            let fp:any = this.fechaProxima(true);
            return fp.hora;
        }
    }
  }


  formatFecha(sFecha: string) {

      let userTimezoneOffset = (new Date()).getTimezoneOffset() * 60000;
      const fecha  = new Date(new Date(sFecha).getTime() + userTimezoneOffset);

      return Helper.FormatFecha(fecha).fechaCorta;
  }

  deserialize(obj: any): this {

    Object.assign(this, obj.evento);

    if (this.imagenes && this.imagenes.length) {

        this.imagenes = this.imagenes.map( (img: string) => {

            return ConnexionApiService.host + '/' + img;
        });
    }

    if (this.generos && this.generos.length) {

        this.generos.map( (g: any) => new Genero(g));
    }

    if (this.urlCompra) {

        this.urlCompra = Helper.CheckAndFixUrl(this.urlCompra);
    }

    this.fechaInicio = obj.evento.fecha_inicio;
    this.fechaFin = obj.evento.fecha_fin;
    this.redes = { 
        facebook: obj.evento.facebook, instagram: obj.evento.instagram, 
        web: obj.evento.web, twitter: obj.evento.twitter, 
        youtube: obj.evento.youtube, snapchat: obj.evento.snapchat
    };
    this.esGratuito = obj.evento.es_gratuito;
    this.textoPromocion = obj.evento.texto_promocional;
    this.urlCompra = obj.evento.url_compra;
    if(obj.intereses.length > 0){
        let existe = obj.intereses.filter(d => d.evento_id == obj.evento.id);
        this.liked = existe.length > 0 ? true : false;
    } else {
        this.liked = false;
    }
    this.latitud = obj.evento.lat;
    this.longitud = obj.evento.lng;
    return this;
  }

    formatearNumero(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}
