import { Component, OnInit } from '@angular/core';
import {PlatformService} from '../providers/platform.service';
import {StorageService} from '../providers/storage.service';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.page.html',
  styleUrls: ['./promo.page.scss'],
})
export class PromoPage implements OnInit {

  webVersion = true;

  constructor(private platform: PlatformService) { }

  ngOnInit() {

    this.webVersion = this.platform.isDesktop();
  }

}
