import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PlatformService} from '../../providers/platform.service';
import {Genero} from '../../models/genero.model';
import {StorageService} from '../../providers/storage.service';
import {Categoria} from '../../models/categoria.model';
import {Recinto} from '../../models/recinto.model';
import {DaterangePickerComponent} from '../daterange-picker/daterange-picker.component';
import {PopoverController} from '@ionic/angular';
import { Filtro } from '../../models/filtro.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {SelectComponent} from '../select/select.component';

@Component({
  selector: 'app-head-filter',
  templateUrl: './head-filter.component.html',
  styleUrls: ['./head-filter.component.scss'],
})
export class HeadFilterComponent implements OnInit, OnChanges {
  @Input()
  textSearch: string = '';

  collapseStatus = { categoria: false, genero: false, recinto: false, fecha: false, precio: false };

  @Input()
  filtro = { recinto: [], fecha: null, precio: {min:null, max: null}, categoria: [], genero: [] };

  webVersion = false;

  generos: Genero[] = [];

  categorias: Categoria[] = [];

  recintos: Recinto[] = [];

  precios = [
    {min: null, max:    0 }, {min:    0, max:  100 }, {min:  100, max:  250 }, {min:  250, max:  500 },
    {min:  500, max: 1000 }, {min: 1000, max: 1500 }, {min: 1500, max: 5000 }, {min: 5000, max: null }
  ];

  _filterConfig = { texto: true, recinto: true, fecha: true, precio: true, categoria: true, genero: true };

  @Input()
  filterConfig: any;

  @Output()
  buscar = new EventEmitter();

  cols = [];

  @Input()
  btnBuscar = true;

  generosRequest = [];

  constructor(private platform: PlatformService, private storage: StorageService,
              private popoverController: PopoverController, private zone: NgZone) { }

  async ngOnInit() {

    this.webVersion = this.platform.isDesktop();

    this.categorias = this.storage._categorias;
   
    let reorderRecinto = this.customSort(this.storage._recintos, [2,1,6,13]);
    // console.log(reorderRecinto);
    this.recintos = reorderRecinto;
    this._filterConfig = Object.assign(this._filterConfig, this.filterConfig);
    this.getCols();
    let datosCategorias = await localStorage.getItem('categoriasEventos');
    if(datosCategorias != null){
      this.generosRequest = JSON.parse(datosCategorias).message;
    } else {
      this.generosRequest = [];
    } 
    setTimeout(() => {
      this.cambioCategoria();
    }, 1000);
    
  }

  colapsarPrecio() {
    this.collapseStatus.precio = !this.collapseStatus.precio;
  }

  cambioCategoria() {

    let generos = [];
    let categorias = [];

    this.filtro.categoria.forEach( value => {
      categorias.push(value.id);
    });
    generos = this.generosRequest.filter(datos => categorias.includes(datos.categoria_id));
    console.log(generos);
    // this.filtro.categoria.forEach((value) => generos = [ ...generos, ...value.generos ]);
    // generos.sort((a: Genero, b: Genero) => ( a.nombre > b.nombre ) ? 1 : ( ( a.nombre < b.nombre ) ? -1 : 0) );

    this.generos = generos;

    console.log(generos, this.filtro);

    if (!this.btnBuscar) {

      this.buscarEventos();
    }
  }

  onDateRangeSelected(range) {
    console.log('range')
    console.log(range)
    this.filtro.fecha = range;
    if (!this.btnBuscar) {

      this.buscarEventos();
    }
  }

  async presentPopover(ev: any) {

    const popover = await this.popoverController.create({
      component: DaterangePickerComponent,
      event: ev,
      translucent: true,
      cssClass: 'daterange-popover scrollable',
      componentProps: {
        inPopover: true,
        showUnselect: true,
        fromDate: this.filtro.fecha ? this.fechaNgb(this.filtro.fecha.from) : null,
        toDate: this.filtro.fecha ? this.fechaNgb(this.filtro.fecha.to) : null,
      }
    });

    popover.onDidDismiss().then((info) => {
      console.log(info);
        if (!info.data) {

          return;
        }
        console.log('data', info.data);
        if(info.data && info.data.from) {
          let from = info.data.from.getUTCFullYear()+'-'+(info.data.from.getUTCMonth()+1)+'-'+info.data.from.getUTCDate();
          let to = info.data.to.getUTCFullYear()+'-'+(info.data.to.getUTCMonth()+1)+'-'+info.data.to.getUTCDate();
          console.log(from, to)
          this.filtro.fecha = {"from": from,"to": to};
        } else {
          this.filtro.fecha = null;
        }
        
        if (!this.btnBuscar) {

          this.buscarEventos();
        }
    });

    return await popover.present();
  }

  buscarEventos() {
    console.log("obtenerData", this.obtenerData());
    this.buscar.emit(this.obtenerData());
  }

  obtenerData() {

    const filtro = new Filtro();

    if (this.textSearch) {
      filtro.texto = this.textSearch;
    }
    try {
      filtro.categoria = this.filtro.categoria.map((c: Categoria) => c.id);
    } catch (error) { }
    try {
      filtro.recinto = this.filtro.recinto.map((r: Recinto) => r.id);
    } catch (error) { }
    try {
      filtro.genero = this.filtro.genero.map((g: Genero) => g);
    } catch (error) { }
    try {
      if (this.filtro.fecha) {

        filtro.fecha.from = this.formatFecha(this.filtro.fecha.from);
        filtro.fecha.to = this.formatFecha(this.filtro.fecha.to);
      }
    } catch (error) { }
      
    try {
      if (this.filtro.precio) {
        filtro.precio = this.filtro.precio;
      }
    } catch (error) { }
    return filtro;
  }

  formatFecha(fecha: Date) {
    console.log(fecha)
    if (!fecha) {
      return fecha;
    }
    if (fecha instanceof Date && !isNaN(fecha.getTime())) {
      
      return `${fecha.getUTCFullYear()}-${fecha.getUTCMonth() < 10 ? ('0' + fecha.getUTCMonth()) : fecha.getUTCMonth()}-${fecha.getUTCDate() < 10 ? ('0' + fecha.getUTCDate()) : fecha.getUTCDate()}`  
    }
    else {
      return fecha;
    }
  }

  fechaNgb(fecha: Date) {

    if (!fecha)
      return null;
    fecha = new Date(fecha)
    return new NgbDate(fecha.getUTCFullYear(), fecha.getUTCMonth() + 1, fecha.getUTCDate());
  }

  getCols() {

    let count = 0;
    Object.keys(this._filterConfig).forEach(k => {
      return count += (this._filterConfig[k] && k !== 'texto' ) ? 1 : 0
    });

    if (count === 3) {

      this.cols =  [12, 6, 4];
    }

  }

  onChangeValue() {

    if (!this.btnBuscar) {

      this.buscarEventos();
    }
  }

  reset() {

    this.zone.run(() => {

      this.textSearch = null;
      this.filtro = { recinto: [], fecha: null, precio: {min: null, max:null}, categoria: [], genero: [] };
    });
    
  }

  changeSeleccion(event, field) {

    const elements: any[] = this.filtro[field];

    const index = elements.findIndex((e: any) => e.id == event.detail.value.id);

    if (event.detail.checked) {

      if (index < 0) {

        elements.push(event.detail.value);
      }

    } else {

      if (index >= 0) {

        elements.splice(index, 1);
      }
    }

    this.filtro[field] = elements;

    if (field === 'categoria') {

      this.cambioCategoria();
    } else {

      if (!this.btnBuscar) {

        this.buscarEventos();
      }
    }
  }

  isChecked(field, value) {
    if(field == 'genero') {
      //return this.filtro[field].findIndex((e: any) => e == value.id) >= 0;
    }
    return this.filtro[field].findIndex((e: any) => e.id == value.id) >= 0;
  }

  obtenerSelectName(field: string, defaultStr = '') {

    const elements: any[] = this.filtro[field];

    if (!elements.length) {

      return defaultStr ? defaultStr : field.toUpperCase();
    }

    const text = elements.map((c: any) => c.nombre ).join(', ');

    return text.slice(0, 80) + (text.length > 120 ? '...' : '');

  }

  async showSelect(ev, opciones, field, emptyOptions = '') {

    const selectEvent = new EventEmitter();

    const popover = await this.popoverController.create({
      component: SelectComponent,
      event: ev,
      cssClass: 'select-popover scrollable',
      translucent: true,
      componentProps: {
        opciones,
        selected: this.filtro[field],
        onSelect: selectEvent,
        emptyOptions
      }
    });

    selectEvent.subscribe((data) => {

      this.changeSeleccion(data, field);
    });

    return await popover.present();
  }

  customSort(array, idsAtStart) {
    const idMap = new Map();
    idsAtStart.forEach((id, index) => {
        idMap.set(id, index);
    });
    return array.sort((a, b) => {
        const indexA = idMap.has(a.id) ? idMap.get(a.id) : idsAtStart.length;
        const indexB = idMap.has(b.id) ? idMap.get(b.id) : idsAtStart.length;
        if (indexA !== indexB) {
            return indexA - indexB;
        }
        return a.id - b.id;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.filtro.currentValue != changes.filtro.previousValue && changes.filtro.previousValue != undefined) {
        let flt = changes.filtro.currentValue;
        flt.categoria = flt.categoria.map(id => {
          const categoriaEncontrada = this.categorias.filter(d => d.id === id)[0];
          return categoriaEncontrada ? categoriaEncontrada : id;
        });
        flt.texto = flt.texto == "" ? (this.textSearch == undefined ? '' : this.textSearch) : flt.texto;
        this.filtro = flt;
        this.rangos.lower = flt.precio.min == null ? 0 : flt.precio.min;
        this.rangos.upper = flt.precio.max == null ? 5000 : flt.precio.max;
      }
    } catch (error) { }
  }
  rangos = {
    lower: 0,
    upper: 5000
  };
  modoGratis =false;
  stepRange = 100;
  valorMin = '0';
  valorMax = '5000';
  showRangos(eventi) {
    this.modoGratis = false;
    console.log(eventi.detail.value);
    if(this.rangos.lower == 0 && this.rangos.upper == 0) {
      this.modoGratis = true;
      this.filtro.precio.min = null; 
      this.filtro.precio.max = 0;
    } else {
      if(this.rangos.lower == 5000 && this.rangos.upper == 5000) {
        this.modoGratis = false;
        this.filtro.precio.min = 5000; 
        this.filtro.precio.max = null;
      } else {
        this.modoGratis = false;
        this.filtro.precio.min = this.rangos.lower; 
        this.filtro.precio.max = this.rangos.upper;
      }
    }

  }

  ionChangeGratis(event) {
    if(event.detail.checked) {
      this.filtro.precio.min = null; 
      this.filtro.precio.max = 0;
      // this.rangos.lower = 0;
      // this.rangos.upper = 0;
    }
  }
}
